import React from 'react';
import i18next from 'i18next';
import SadIcon from 'img/sad.svg';
import ErrorPage from './error-page';

const NoMatchError = () => (
  <ErrorPage
    icon={SadIcon}
    textNormal={i18next.t('error_page.cant_find_page_text')}
  />
);

export default NoMatchError;
