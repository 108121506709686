import React from 'react';
import { productTypes } from 'shared/constants';
import FetchData from 'components/fetch-data';
import LicenseHistory from 'components/pages/license-history/license-history';

export default function LicenseHistoryWithFetch(props) {
  const {
    match,
    licensesDataGetSignal,
    eraLicensesDataGetSignal,
    licensesDataIm,
    eraLicensesDataIm,
  } = props;

  const licensesDataTypes = [
    {
      productType: productTypes.RAIDIX,
      fetchActionNames: ['licensesDataGetSignal'],
      currentLicensesDataIm: licensesDataIm,
    },
    {
      productType: productTypes.ERA,
      fetchActionNames: ['eraLicensesDataGetSignal'],
      currentLicensesDataIm: eraLicensesDataIm,
    }
  ];

  const licensesDataType = licensesDataTypes.find(
    ({ productType }) => productType.toLowerCase() === match.params.productType.toLowerCase(),
  );

  if (!licensesDataType) {
    return null;
  }

  const { productType, fetchActionNames, currentLicensesDataIm } = licensesDataType;

  return (
    <FetchData
      eraLicensesDataGetSignal={eraLicensesDataGetSignal}
      licensesDataGetSignal={licensesDataGetSignal}
      fetchActionNames={fetchActionNames}
    >
      <LicenseHistory
        {...props}
        productType={productType}
        currentLicensesDataIm={currentLicensesDataIm}
      />
    </FetchData>
  );
}
