import React, { PureComponent } from 'react';
import ItemsPerPage from './items-per-page';
import PaginationControls from './pagination-controls';

export default class Pagination extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeItemsPerPage = this.onChangeItemsPerPage.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);

    this.state = {
      pagesCount: this.calcPagesCount(props.itemsPerPage, props.itemsCount),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (('itemsPerPage' in nextProps) || ('itemsCount' in nextProps)) {
      this.setState({
        pagesCount: this.calcPagesCount(nextProps.itemsPerPage, nextProps.itemsCount),
      });
    }
  }

  onNextClick() {
    const { changePage, currentPage, tableId } = this.props;

    if (!this.isDisabledNext()) {
      changePage(tableId, currentPage + 1);
    }
  }

  onPreviousClick() {
    const { changePage, currentPage, tableId } = this.props;

    if (!this.isDisabledPrevious()) {
      changePage(tableId, currentPage - 1);
    }
  }

  onChangeItemsPerPage(event) {
    const { changeItemsPerPage, tableId } = this.props;

    changeItemsPerPage(tableId, Number(event.value));
  }

  calcPagesCount(itemsPerPage, itemsCount) {
    return itemsCount < itemsPerPage ?
      1 :
      Math.ceil(itemsCount / itemsPerPage);
  }

  isDisabledPrevious() {
    return this.props.currentPage <= 1;
  }

  isDisabledNext() {
    return this.props.currentPage >= this.state.pagesCount;
  }

  render() {
    const { currentPage, itemsCount, itemsPerPage } = this.props;
    const { pagesCount } = this.state;

    return (
      <div className="table__pagination">
        <ItemsPerPage
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          onChange={this.onChangeItemsPerPage}
        />
        <PaginationControls
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          pagesCount={pagesCount}
          isDisabledPrevious={this.isDisabledPrevious()}
          isDisabledNext={this.isDisabledNext()}
          onPreviousClick={this.onPreviousClick}
          onNextClick={this.onNextClick}
        />
      </div>
    );
  }
}
