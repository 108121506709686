import React from 'react';
import i18next from 'i18next';
import { driveTypeTranslations } from 'shared/constants';
import TableCell from './cell';

export default function LicenseDriveTypeCell({ value, className }) {
  const translation = i18next.t(driveTypeTranslations[value]);

  return (
    <TableCell
      className={className}
      value={translation}
    />
  );
}
