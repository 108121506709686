import React, { PureComponent } from 'react';
import Checkbox from 'components/controls/checkbox';
import i18next from 'i18next';
import { BASIC_NUMBER_OF_DRIVES, BASIC_FEATURES_CODES } from 'constants';
import { orderTypes } from 'shared/constants';
import { List } from 'immutable';
import { getRelatedFeatures } from './helpers';

export default class Functionality extends PureComponent {
  constructor(props) {
    super(props);

    let features = List();
    this.basicFeaturesIds = [];

    props.functionalitiesDataIm.get('data').forEach((feature) => {
      const id = feature.get('id');
      const select = Array.isArray(props.defaultValue) ?
        props.defaultValue.includes(id) : false;

      if (BASIC_FEATURES_CODES.includes(feature.get('code'))) {
        this.basicFeaturesIds.push(id);
      }

      // Выводим только активные функции, или функции которых уже с ними нет,
      // но они были проданы в старых заказах
      if (feature.get('active') === 1 || (feature.get('active') === 0 && select)) {
        features = features.push({ id, select });
      }
    });

    this.state = {
      features,
      selectCount: Array.isArray(props.defaultValue) ? props.defaultValue.length : 0,
    };

    this.onSelectAllFeatures = this.onSelectAllFeatures.bind(this);
    this.onSelectBasicFeatures = this.onSelectBasicFeatures.bind(this);
    this.onRemoveAllFeatures = this.onRemoveAllFeatures.bind(this);
    this.onChangeFeature = this.onChangeFeature.bind(this);
  }

  componentDidUpdate() {
    this.value = this.props.value;
  }

  /**
   * Вызывается при изменении любой из функций
   * @param event
   */
  onChangeFeature(event) {
    const { functionalitiesDataIm } = this.props;
    // Колличество выбранных функций
    let selectCount = 0;
    const value = Number(event.target.value);
    const featureIds = [];

    const selectFeatures = this.state.features.map((feature) => {
      // Находим изменённую функцию и инвертируем её значение
      const select = feature.id === value ? !feature.select : feature.select;

      if (select) {
        selectCount += 1;
        featureIds.push(feature.id);
      }

      return {
        ...feature,
        select,
      };
    });

    // Add related features to the list
    const relatedFeatures = getRelatedFeatures(functionalitiesDataIm, featureIds);
    const features = selectFeatures.map((feature) => {
      const select = relatedFeatures.includes(feature.id);

      if (select && !feature.select) {
        selectCount += 1;
        featureIds.push(feature.id);
      }

      return {
        ...feature,
        select,
      };
    });

    // Сохраняем изменения
    this.setState({ features, selectCount });

    if (this.props.onChange) {
      this.props.onChange(featureIds);
    }
  }

  onRemoveAllFeatures(event) {
    event.preventDefault();

    this.setFeatures({ value: false });
  }

  onSelectAllFeatures(event) {
    event.preventDefault();

    this.setFeatures({ value: true });
  }

  onSelectBasicFeatures(event) {
    event.preventDefault();

    this.setFeatures({
      value: true,
      reset: true,
      featureIds: this.basicFeaturesIds,
    });

    this.props.setDrives(BASIC_NUMBER_OF_DRIVES);
  }

  /**
   * Возврощает ид выбранных функций
   * @returns {*}
   */
  get value() {
    return this.state.features
      // Получаем выбраные функции
      .filter(feature => feature.select === true)
      // получаем ид функций
      .map(feature => feature.id);
  }

  set value(newFeatures) {
    if (Array.isArray(newFeatures)) {
      let selectCount = 0;
      const features = this.props.functionalitiesDataIm.get('data').map((feature) => {
        const id = feature.get('id');
        const select = newFeatures.length > 0 ? newFeatures.includes(id) : false;

        if (select) {
          selectCount += 1;
        }

        return { id, select, active: feature.get('active') };
      }).filter(feature => feature.active === 1);

      this.state = {
        features,
        selectCount,
      };
    }
  }

  /**
   * Sets a new features value
   * @param {object} params
   * @param {boolean} params.value New value
   * @param {array} [params.featureIds] List of updated features. If not specified,
   *                                    all features will be updated.
   * @param {boolean} [params.reset=false] Sets the initial value of the functions to false
   */
  setFeatures({ value, featureIds, reset = false }) {
    const { disabled, relatedFeatures, onChange } = this.props;
    // Number of selected features
    let selectCount = 0;
    const changeFeatureIds = [];
    const features = this.state.features.map((feature) => {
      // Do not touch inactive features
      if (disabled.includes(feature.id) && relatedFeatures.includes(feature.id)) {
        return feature;
      }

      let select = reset ? false : feature.select;

      if (
        // If items are not transferred, update the value of all features
        (featureIds === undefined) ||
        // Update only passed values
        (Array.isArray(featureIds) && featureIds.length > 0 && featureIds.includes(feature.id))
      ) {
        select = value;
      }

      if (select) {
        selectCount += 1;
        changeFeatureIds.push(feature.id);
      }

      return { ...feature, select };
    });

    this.setState({
      selectCount,
      features,
    });

    if (onChange) {
      onChange(changeFeatureIds);
    }
  }

  /**
   * Узнаёт выбранна ли функция featureId
   * @param featureId
   * @returns {*}
   */
  featureValue(featureId) {
    if (this.props.value !== undefined) {
      return this.props.value.includes(featureId);
    }

    const features = this.state.features.find(feature => feature.id === featureId);

    return features.select;
  }

  /**
   * Возврощает true если выбраны все функции
   * @returns {boolean}
   */
  isSelectAllFeatures() {
    return this.state.features.size === this.state.selectCount;
  }

  isSelectBasicFeatures() {
    const { value, drives } = this.props;

    if (value.length !== this.basicFeaturesIds.length) {
      return false;
    }

    const isIncludesFeatures = value.every(selectedFeature =>
      this.basicFeaturesIds.includes(selectedFeature));

    if (!isIncludesFeatures) {
      return false;
    }

    return drives === BASIC_NUMBER_OF_DRIVES;
  }

  selectAllFeatures() {
    const { disabled = [], orderType, presetsDisabled } = this.props;

    if (
      // Some features are always disabled for now,
      // so disabled.length will always be greater by some amount
      disabled.length < this.state.features.size &&
      (orderType === orderTypes.COMMERCIAL || orderType === orderTypes.TRIAL) &&
      !presetsDisabled
    ) {
      return (
        this.isSelectAllFeatures() ? (
          <a
            href="#remove-all-features"
            className="link link_dashed link_separated"
            onClick={this.onRemoveAllFeatures}
          >
            {i18next.t('remove_all')}
          </a>
        ) : (
          <a
            href="#select-all-features"
            className="link link_dashed link_separated"
            onClick={this.onSelectAllFeatures}
          >
            {i18next.t('select_all')}
          </a>
        )
      );
    }

    return null;
  }

  selectBasicFeatures() {
    const { disabled = [], orderType, presetsDisabled } = this.props;
    // Some features are always disabled for now,
    // so disabled.length will always be greater by some amount
    if (disabled.length < this.state.features.size && orderType === orderTypes.COMMERCIAL && !presetsDisabled) {
      return (
        this.isSelectBasicFeatures() ? (
          <span className="link link_dashed link_separated link_disabled">
            {i18next.t('select_basic')}
          </span>
        ) : (
          <a
            href="#select-basic-features"
            className="link link_dashed link_separated"
            onClick={this.onSelectBasicFeatures}
          >
            {i18next.t('select_basic')}
          </a>
        )
      );
    }

    return null;
  }

  render() {
    const {
      onlyForDraid,
      licenseKey,
      relatedFeatures,
      functionalitiesDataIm,
      disabled: disabledList = [],
    } = this.props;

    return (
      <div className="functionality">
        <div className="functionality__header">
          <div className="functionality__title">
            {i18next.t('features')}
          </div>
          {this.selectAllFeatures()}
          {this.selectBasicFeatures()}
        </div>
        <div className="functionality__list">
          {
            functionalitiesDataIm.get('data')
              .filter((f) => onlyForDraid ? f.get('forDraid') === 1 : true)
              .map((item) => {
                const id = item.get('id');
                // Так как на странице может быть не один блок с функциями,
                // то для корректной работы переключения к id переключателя
                // для уникальности, добавлен id лицензии(licenseKey)
                const htmlId = `functionality__item-id_${licenseKey}_${id}`;
                const name = item.get('name');
                let disabled = disabledList.includes(id) || relatedFeatures.includes(id);
                const checked = this.featureValue(id);

                // Проверяем доступна ли функция для заказа
                if (item.get('active') !== 1) {
                  // Деактивируем функцию если она не доступна,
                  // но была ранее добавлена в заказа
                  disabled = true;

                  // скрываем функцию если она ранее не была выбрана
                  if (!checked) {
                    return null;
                  }
                }

                // LIC-242: Сделать checkbox для graid неактивным при создании trial (disabled)
                if (id === 18) {
                  disabled = true;
                }

                return (
                  <div className="functionality__item" key={id}>
                    <label htmlFor={htmlId} >
                      <Checkbox
                        id={htmlId}
                        value={id}
                        onChange={this.onChangeFeature}
                        checked={checked}
                        disabled={disabled}
                      />
                      {name}
                    </label>
                  </div>
                );
              })
          }
        </div>
      </div>
    );
  }
}
