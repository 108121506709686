import { fetchSignal } from 'ducks/fetch';
import { actions as licensesDataActions } from 'ducks/data/licenses';
import { actions as eraLicensesDataActions } from 'ducks/data/era-licenses';
import { actions as draidLicensesDataActions } from 'ducks/data/draid-licenses';
import { productTypes } from 'shared/constants';

export const URL = '/api/generator';

const generatorDataGenerateSignal = ({ licenseId, data }) =>
  dispatch => Promise.coroutine(function* generateLicense() {
    const body = { ...data, licenseId };
    const answer = yield dispatch(fetchSignal(URL, { method: 'POST', body }));

    if (answer.isSuccess) {
      const map = {
        [productTypes.RAIDIX]: licensesDataActions.licensesDataNoFetchUpdateSignal,
        [productTypes.ERA]: eraLicensesDataActions.eraLicensesDataNoFetchUpdateSignal,
        [productTypes.DRAID]: draidLicensesDataActions.draidLicensesDataNoFetchUpdateSignal,
      }

      const action = map[data.productType](answer.data);

      dispatch(action);
    }

    return answer;
  })();

export const actions = {
  generatorDataGenerateSignal,
};
