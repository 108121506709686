import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'shared/constants';
import TableCell from './cell';

export default function DateCell({ className, value, valueRaw, children }) {
  const result = valueRaw ? moment(value, 'X').format(DATE_FORMAT) : '\u2014';

  return (
    <TableCell className={className} value={result}>
      {children}
    </TableCell>
  );
}
