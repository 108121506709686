import React, { Component } from 'react';
import classnames from 'classnames';
import ReactSelect from 'react-select';
import i18next from 'i18next';
import ExpandMoreIcon from 'img/expand-more.svg';

function arrowRenderer({ isOpen }) {
  return (
    <span className={classnames('Select-arrow-button', { 'Select-arrow-button_open': isOpen })}>
      <ExpandMoreIcon
        width="24"
        height="24"
      />
    </span>
  );
}

export default class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: props.defaultValue,
      error: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    // TODO: Решить проблему с обновлением value без использования
    // setState() в методе componentDidUpdate
    if (prevProps.value !== this.props.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedItem: this.props.value,
      });
    }
  }

  onChange(data) {
    const { onChange, multi } = this.props;
    const selectedItem = multi ? data.map(option => option.value) : data.value;

    if (data) {
      this.setState({
        error: false,
        selectedItem,
      });
    }

    if (typeof onChange === 'function') {
      onChange(data);
    }
  }

  get value() {
    const { emptyValue } = this.props;
    const { selectedItem } = this.state;

    if (selectedItem === undefined) {
      return emptyValue;
    }

    return selectedItem;
  }

  get error() {
    return this.state.error;
  }

  set error(newValue) {
    this.setState({
      error: newValue,
    });
  }

  set value(value) {
    this.setState({
      selectedItem: value,
      error: false,
    });
  }

  promptTextCreator(label) {
    return i18next.t('create_option', { label });
  }

  render() {
    const {
      value,
      multi,
      disabled,
      tabIndex,
      className,
      placeholder,
      errorClassName,
      creatable = false,
    } = this.props;

    const selectedValue = value !== undefined ? value : this.state.selectedItem;
    // Если компонент неактивный, делаем его недоступным с клавиатуры
    const inputTabIndex = disabled === true ? -1 : tabIndex;
    let inputPlaceholder = placeholder !== undefined ? placeholder : `${i18next.t('select')}...`;

    if (disabled) {
      inputPlaceholder = '';
    }

    const props = {
      ...this.props,
      // Перевод
      noResultsText: i18next.t('no_results_found'),
      loadingPlaceholder: `${i18next.t('loading')}...`,
      searchPromptText: i18next.t('type_to_search'),
      clearAllText: i18next.t('clear_all'),
      clearValueText: i18next.t('clear_value'),
      backspaceToRemoveMessage: i18next.t('press_backspace_to_remove'),
      promptTextCreator: this.promptTextCreator,

      placeholder: inputPlaceholder,
      value: selectedValue,
      tabIndex: inputTabIndex,
      arrowRenderer,
      autosize: multi,
      inputProps: {
        autoComplete: 'off',
      },
      onChange: this.onChange,
      className: classnames(className, {
        select_error: this.state.error,
      }),
    };

    return (
      <div className="select-component">
        { creatable ? (<ReactSelect.Creatable {...props} />) : (<ReactSelect {...props} />)}
        {
          this.state.error ? (
            <div
              className={classnames('select-component__error', errorClassName)}
              title={this.state.error}
            >
              {this.state.error}
            </div>
          ) : null
        }
      </div>
    );
  }
}
