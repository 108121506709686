import React, { PureComponent } from 'react';
import i18next from 'i18next';
import classnames from 'classnames';

export default class CurrencyButton extends PureComponent {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    this.props.onClick({ currency: this.props.id });
  }

  render() {
    const { active, disabled, children, id } = this.props;
    const className = classnames(
      'button',
      'button_currency',
      { 'button_currency-active button_non-interactive': active },
    );

    return (
      <button
        className={className}
        onClick={this.onChange}
        data-tip={i18next.t(`currencies.${id.toLowerCase()}`)}
        data-for="currency"
        tabIndex={(active || disabled) ? -1 : null}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
}
