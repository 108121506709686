/* global document */
import React, { PureComponent } from 'react';
import i18next from 'i18next';

export default class LicenseTextForm extends PureComponent {
  constructor(props) {
    super(props);
    this.licenseTextarea = this.props.licenseTextarea ?
      this.props.licenseTextarea : React.createRef();
    this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
  }

  onCopyToClipboard() {
    this.licenseTextarea.current.select();
    document.execCommand('copy');
  }

  render() {
    return (
      <form className="form">
        <div className="form__row">
          <div className="form__field form__field_width_auto">
            <textarea
              className="license-text form__field-text form__field-text_padding"
              defaultValue={this.props.licenseText}
              ref={this.licenseTextarea}
              readOnly
            />
          </div>
        </div>
        <div className="form__row form__row_footer">
          <div className="form__field form__field_width_auto">
            <input
              type="button"
              className="button"
              value={i18next.t('copy_license_text_to_clipboard')}
              onClick={this.onCopyToClipboard}
            />
          </div>
        </div>
      </form>
    );
  }
}
