import React from 'react';

export default function TableCell({ className, value, children }) {
  const component = children || value;

  return (
    <div className={className} title={typeof value === 'string' ? value : null}>
      {component}
    </div>
  );
}
