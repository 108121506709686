import React, { Fragment, PureComponent } from 'react';
import i18next from 'i18next';
import ModalHeader from 'components/modal/modal-header';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LicenseTextForm from './license-text-form';

export default class LicenseTextModal extends PureComponent {
  constructor(props) {
    super(props);
    this.firstTextarea = React.createRef();
  }

  componentDidMount() {
    this.firstTextarea.current.focus();
  }

  render() {
    const { licenses = [] } = this.props.modalComponentIm.getIn(['options']);
    const availableLicenses = licenses.filter(({ content }) => content && content.length > 0);

    return (
      <Fragment>
        <ModalHeader text={i18next.t('license_key')} />
        <Tabs>
          <TabList>
            {availableLicenses.map(({ tabName }) => (
              <Tab key={tabName}>{i18next.t(tabName)}</Tab>
            ))}
          </TabList>

          {availableLicenses.map(({ tabName, content }, key) => (
            <TabPanel key={tabName}>
              <LicenseTextForm
                licenseText={content}
                licenseTextarea={!key ? this.firstTextarea : null}
              />
            </TabPanel>
          ))}
        </Tabs>
      </Fragment>
    );
  }
}
