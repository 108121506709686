import { Map, List } from 'immutable';
import { applicationName } from 'config';
import { actions as toastsComponentActions } from 'ducks/components/toasts';

/*
* Constants
* */

export const MODULE_NAME = 'modal-component';

// Action names
const SHOW = `${applicationName}/${MODULE_NAME}/SHOW`;
const CLOSE = `${applicationName}/${MODULE_NAME}/CLOSE`;
const CLOSE_ALL = `${applicationName}/${MODULE_NAME}/CLOSE_ALL`;
const DISABLE = `${applicationName}/${MODULE_NAME}/DISABLE`;
const ENABLE = `${applicationName}/${MODULE_NAME}/ENABLE`;
const UPDATE_DATA = `${applicationName}/${MODULE_NAME}/UPDATE_DATA`;

/*
* Reducer
* */

const initialState = Map({
  // Id в данные момент активного окна
  activeId: false,
  // Заблокировано ли активное окно
  isDisabled: false,
  // Входные данные окна(его первичная настройка)
  options: false,
  queue: List(),
  // Вспомогательные данные
  data: Map(),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW:
      return state.withMutations((mutable) => {
        mutable.set('activeId', action.payload.id)
          .set('options', action.payload.options)
          .set('queue', state.get('queue').push(
            Map({
              activeId: action.payload.id,
              options: action.payload.options,
            })
          ));
      });

    case ENABLE:
      return state.set('isDisabled', false);

    case DISABLE:
      return state.set('isDisabled', true);

    case CLOSE: {
      if (state.get('queue').size > 1) {
        return state.withMutations((mutable) => {
          const queue = state.get('queue').pop();
          const activeDialog = queue.last();

          mutable
            .set('activeId', activeDialog.get('activeId'))
            .set('queue', queue)
            .set('options', activeDialog.get('options'));
        });
      }

      return initialState;
    }

    case CLOSE_ALL:
      return initialState;

    case UPDATE_DATA: {
      return state.mergeDeep({ data: action.payload });
    }

    default:
      return state;
  }
}

/*
* Actions
* */

const modalComponentShowDelta = (id, options) => ({ type: SHOW, payload: { id, options } });
const modalComponentDisableDelta = () => ({ type: DISABLE });
const modalComponentEnableDelta = () => ({ type: ENABLE });
const modalComponentHideSignal = () => ({ type: CLOSE });
const modalComponentHideAllSignal = () => ({ type: CLOSE_ALL });

// Оборачивает submitSignal логикой дизейбла кнопок и отображения тоста при успехе
const modalComponentSubmitWrapperSignal = ({ submitSignal, doneText = false }) =>
  dispatch => Promise.coroutine(function* getWrapper() {
    dispatch(modalComponentDisableDelta());

    const answer = yield submitSignal();

    if (answer.isSuccess) {
      if (doneText) {
        dispatch(
          toastsComponentActions.toastsComponentAddDelta({ type: 'info', content: doneText })
        );
      }

      dispatch(modalComponentHideSignal());
    }

    dispatch(modalComponentEnableDelta());

    return answer;
  })();

const modalComponentUpdateDataSignal = data => (dispatch) => {
  dispatch({ type: UPDATE_DATA, payload: data });
};

export const actions = {
  modalComponentShowDelta,
  modalComponentDisableDelta,
  modalComponentEnableDelta,
  modalComponentHideSignal,
  modalComponentHideAllSignal,
  modalComponentSubmitWrapperSignal,
  modalComponentUpdateDataSignal,
};
