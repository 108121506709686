import { Map, fromJS } from 'immutable';
import { applicationName } from 'config';
import { fetchSignal } from 'ducks/fetch';

/*
* Constants
* */

export const MODULE_NAME = 'drad-licenses-data';
export const URL = '/api/draid-licenses';

// Action names
const FETCH_DONE = `${applicationName}/${MODULE_NAME}/FETCH_DONE`;
const UPDATE_DONE = `${applicationName}/${MODULE_NAME}/UPDATE_DONE`;
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;

/*
* Reducer
* */

const initialState = Map({ data: false });

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DONE:
      return state.set('data', fromJS(action.payload));

    case UPDATE_DONE: {
      return state.update('data', data => data.map((dataItem) => {
        if (dataItem.get('id') === action.payload.id) {
          return dataItem.merge(action.payload);
        }

        return dataItem;
      }));
    }

    case RESET:
      return initialState;

    default:
      return state;
  }
}

/*
* Actions
* */

const draidLicensesDataResetDelta = () => ({ type: RESET });

const draidLicensesDataGetSignal = () => dispatch => Promise.coroutine(function* getLicense() {
  const answer = yield dispatch(fetchSignal(URL));

  if (answer.isSuccess) {
    dispatch({ type: FETCH_DONE, payload: answer.data });
  }

  return answer;
})();

// Используется после генерации лицензии
const draidLicensesDataNoFetchUpdateSignal = data => ({ type: UPDATE_DONE, payload: data });

export const actions = {
  draidLicensesDataResetDelta,
  draidLicensesDataGetSignal,
  draidLicensesDataNoFetchUpdateSignal,
};
