/* global document */

import React, { PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import classnames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';

export default class Tooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.toggleHide = this.toggleHide.bind(this);
    this.onClick = this.onClick.bind(this);

    this.state = { isHidden: true };
  }

  componentDidMount() {
    document.addEventListener('click', this.onClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick, false);
  }

  onClick(event) {
    try {
      // Закрываем тултип если произошел клик за пределами тултипа, но внутри root.
      // (Это защитит от закрытия при клике по дате календаря)
      if (
        !findDOMNode(this).contains(event.target) &&
        document.getElementById('root').contains(event.target)
      ) {
        if (!this.state.isHidden) {
          this.toggleHide();
        }
      }
    } catch (error) {
      // do nothing, ie fix
    }
  }

  get isHidden() {
    return this.state.isHidden;
  }

  toggleHide() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    const {
      className = '',
      classNameButtonShow = '',
      classNameButtonHide = '',
      children,
      content,
      onClickContent = null,
    } = this.props;

    let button = children;

    if (classNameButtonShow || classNameButtonHide) {
      button = React.cloneElement(children, {
        className: classnames(children.props.className, {
          [classNameButtonShow]: !this.state.isHidden,
          [classNameButtonHide]: this.state.isHidden,
        }),
      });
    }

    return (
      <div className="tooltip">
        <div onClick={this.toggleHide}>
          { button }
        </div>
        <CSSTransitionGroup
          component="div"
          transitionName={{
            enter: 'tooltip__content_enter',
            enterActive: 'tooltip__content_enter_active',
            leave: 'tooltip__content_leave',
            leaveActive: 'tooltip__content_leave_active',
            appear: 'tooltip__content_appear',
            appearActive: 'tooltip__content_appear_active',
          }}
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
        >
          {
            !this.state.isHidden ? (
              <div className={`tooltip__content ${className}`} onClick={onClickContent}>
                {content}
              </div>
            ) : null
          }
        </CSSTransitionGroup>
      </div>
    );
  }
}
