import { connect } from 'react-redux';
import Licenses from 'components/pages/licenses';
import dataFetcherEnhance from 'components/data-fetcher-enhance';
import { actions as licensesDataActions } from 'ducks/data/licenses';
import { actions as licensesInfoDataActions } from 'ducks/data/licenses-info';
import { actions as eraLicensesDataActions } from 'ducks/data/era-licenses';
import { actions as draidLicensesDataActions } from 'ducks/data/draid-licenses';
import { actions as functionalitiesDataActions } from 'ducks/data/functionalities';
import { actions as usersDataActions } from 'ducks/data/users';
import { actions as licenseSubtypesDataActions } from 'ducks/data/license-subtype';
import { actions as productVersionsDataActions } from 'ducks/data/product-versions';

const mapDispatchToProps = Object.assign(
  {},
  licensesDataActions,
  eraLicensesDataActions,
  draidLicensesDataActions,
  functionalitiesDataActions,
  usersDataActions,
  licenseSubtypesDataActions,
  productVersionsDataActions,
  licensesInfoDataActions,
);

function mapStateToProps(state) {
  return {
    authDataIm: state.data.authDataIm,
    licensesDataIm: state.data.licensesDataIm,
    licensesInfoDataIm: state.data.licensesInfoDataIm,
    eraLicensesDataIm: state.data.eraLicensesDataIm,
    draidLicensesDataIm: state.data.draidLicensesDataIm,
    functionalitiesDataIm: state.data.functionalitiesDataIm,
    usersDataIm: state.data.usersDataIm,
    licenseSubtypesDataIm: state.data.licenseSubtypesDataIm,
    productVersionsDataIm: state.data.productVersionsDataIm,
    fetchActionNames: [
      'usersDataGetSignal',
      'licensesInfoDataGetSignal',
    ],
  };
}

const LicensesWithFetch = dataFetcherEnhance(Licenses);

export default connect(mapStateToProps, mapDispatchToProps)(LicensesWithFetch);
