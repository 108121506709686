import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import classnames from 'classnames';
import _map from 'lodash/map';
import _some from 'lodash/some';
import { roles } from 'constants';
import { VALID_STAFF_EMAIL_REX } from 'shared/constants';
import { isEmailValidation } from 'shared/functions';
import Select from 'components/controls/select';
import Input from 'components/controls/input';
import Checkbox from 'components/controls/checkbox';

export default class UserForm extends PureComponent {
  constructor(props) {
    super(props);

    const { userIm, isEdit } = props;

    const role = isEdit && userIm ? userIm.get('role') : roles.PARTNER;

    this.state = {
      managers: this.getManagers(),
      roles: this.getRoles(),
      role,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.licenseSubtypesRef = [];
  }

  onSubmit(e) {
    e.preventDefault();

    const { isEdit, userIm } = this.props;
    const userRole = this.props.authDataIm.getIn(['data', 'role']);
    let doneText = isEdit ? 'staff_edited' : 'staff_added';
    let options = {
      name: this.nameRef.value,
      login: this.loginRef.value,
      email: this.emailRef.value,
      role: this.roleRef.value,
      description: this.descriptionRef.value,
    };

    if (!isEdit || this.passwordRef.value.length) {
      options = {
        ...options,
        password: this.passwordRef.value,
      };
    }

    if (options.role === roles.PARTNER) {
      const licenseSubtypesRes = [];
      this.licenseSubtypesRef.forEach((data) => {
        licenseSubtypesRes.push({
          dailyLimit: data.value,
          licenseSubtypeId: data.props.licenseSubtypeId
        });
      });
      doneText = isEdit ? 'partner_edited' : 'partner_added';
      options = {
        ...options,
        defaultManager: this.defaultManagerRef.value,
        trialDailyLimit: this.trialDailyLimitRef.value,
        commercialDailyLimit: this.commercialDailyLimitRef.value,
        eraTrialDailyLimit: this.eraTrialDailyLimitRef.value,
        eraCommercialDailyLimit: this.eraCommercialDailyLimitRef.value,
        creatingRaidixOrder: this.creatingRaidixOrderRef.checked
          ? this.creatingRaidixOrderRef.props.value : 0,
        creatingEraOrder: this.creatingEraOrderRef.checked
          ? this.creatingEraOrderRef.props.value : 0,
        licenseSubtypes: licenseSubtypesRes,
      };
    }

    const validateOptions = {
      ...options,
      // Не отправляем подтверждения пароля на сервер
      confirmPassword: this.confirmPasswordRef.value,
    };

    if (
      // Редактировать пользователей может только администратор
      (isEdit && userRole === roles.ADMIN && this.validate(validateOptions)) ||
      // Остальные сотрудники могут создавать пользователей
      (!isEdit && this.validate(validateOptions))
    ) {
      const fields = { data: options };

      if (userIm && userIm.get('id')) {
        fields.id = userIm.get('id');
      }

      this.props.formComponentSubmitWrapperSignal({
        submitSignal: () => this.props.submitSignal(fields),
        successSignal: () => this.props.formComponentAddSuccessSignal(),
        doneText: i18next.t(doneText, { name: options.name }),
      });
    }
  }

  onChangeRole(event) {
    this.setState({
      role: event.value,
    });
  }

  getRoles() {
    let filterRoles = [];
    const userRole = this.props.authDataIm.getIn(['data', 'role']);

    // Администратор может создавать любых пользователей
    if (userRole === roles.ADMIN) {
      filterRoles = roles;
      // Сотрудники могут создавать только партнёров
    } else if (userRole === roles.MANAGER ||
      userRole === roles.ENGINEER ||
      userRole === roles.ACCOUNTANT
    ) {
      filterRoles = [roles.PARTNER];
    }

    return _map(filterRoles, value => ({ value, label: i18next.t(value) }));
  }

  getManagers() {
    const { userIm, isEdit, usersDataIm } = this.props;
    let defaultManager;

    if (isEdit && userIm) {
      defaultManager = userIm.get('defaultManager');
    }

    return usersDataIm.get('data')
      .filter(
        user =>
          // Выбираем из активных менеджеров
          (user.get('role') === roles.MANAGER && user.get('active') === 1) ||
          // или выбранного менеджера если даже он неактивный
          user.get('id') === defaultManager
      )
      .map(user => ({ value: user.get('id'), label: user.get('name') }))
      // Сортируем список менеджеров по алфавиту
      .sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      })
      .toArray();
  }

  getDailyLimit(userLicenseSubtypes, userId, licenseId) {
    const userLicenseSubtype = userLicenseSubtypes.find(
      data => (data.get('user') === userId) && (data.get('license_subtype') === licenseId)
    );
    return (userLicenseSubtype ? userLicenseSubtype.get('daily_limit') : 0); // 0 is not specified
  }

  getInitValue() {
    const { userIm, isEdit, licenseSubtypesDataIm, userLicenseSubtypesDataIm } = this.props;
    const { role } = this.state;
    const value = { role };

    if (isEdit && userIm) {
      value.name = userIm.get('name');
      value.login = userIm.get('login');
      value.email = userIm.get('email');
      value.defaultManager = userIm.get('defaultManager');
      value.trialDailyLimit = userIm.get('trialDailyLimit');
      value.commercialDailyLimit = userIm.get('commercialDailyLimit');
      value.eraTrialDailyLimit = userIm.get('eraTrialDailyLimit');
      value.eraCommercialDailyLimit = userIm.get('eraCommercialDailyLimit');
      value.creatingRaidixOrder = userIm.get('creatingRaidixOrder');
      value.creatingEraOrder = userIm.get('creatingEraOrder');
      value.description = userIm.get('description');

      const userId = userIm.get('id');
      const userLicenseSubtypes = userLicenseSubtypesDataIm.get('data');

      value.licenseSubtypes = licenseSubtypesDataIm.get('data').map(item => ({
        value: item.get('code'),
        name: item.get('name'),
        dailyLimit: this.getDailyLimit(userLicenseSubtypes, userId, item.get('id')),
        licenseSubtypeId: item.get('id'),
      })).toArray();
    }

    return value;
  }

  validate(options) {
    const { isEdit } = this.props;
    const {
      name,
      login,
      password,
      confirmPassword,
      email,
      role,
      defaultManager,
      trialDailyLimit,
      commercialDailyLimit,
      eraTrialDailyLimit,
      eraCommercialDailyLimit,
      licenseSubtypes,
    } = options;
    let isValid = true;

    // name
    if (!name.length) {
      this.nameRef.error = i18next.t('v.required');
      isValid = false;
    } else if (this.isChange(options, ['name']) && name.length < 3) {
      this.nameRef.error = i18next.t('v.must_be_longer_than', { count: 2 });
      isValid = false;
    }

    // login
    if (!login.length) {
      this.loginRef.error = i18next.t('v.required');
      isValid = false;
    } else if (this.isChange(options, ['login']) && login.length < 6) {
      this.loginRef.error = i18next.t('v.must_be_longer_than', { count: 5 });
      isValid = false;
    }

    // Проверяем пароль если мы создаём нового пользователя
    // или вводим новый пароль уже созданного пользователя
    if (!isEdit || (password && password.length)) {
      if (!password.length) {
        this.passwordRef.error = i18next.t('v.required');
        isValid = false;
      } else if (password.length < 6) {
        this.passwordRef.error = i18next.t('v.must_be_longer_than', { count: 5 });
        isValid = false;
      }

      if (!confirmPassword.length) {
        this.confirmPasswordRef.error = i18next.t('v.required');
        isValid = false;
      } else if (password !== confirmPassword) {
        this.confirmPasswordRef.error = i18next.t('v.not_match_password');
      }
    }

    // email
    if (
      this.isChange(options, ['email']) &&
      email.length > 0 &&
      (
        (role === roles.PARTNER && !isEmailValidation(email)) ||
        (role !== roles.PARTNER && !VALID_STAFF_EMAIL_REX.test(email))
      )
    ) {
      this.emailRef.error = i18next.t('v.invalid_email');
      isValid = false;
    } else {
      this.emailRef.error = '';
    }

    if (role === roles.PARTNER) {
      // manager
      if (defaultManager <= 0) {
        this.defaultManagerRef.error = i18next.t('v.required');
        isValid = false;
      }

      // trial day limit
      if (isNaN(trialDailyLimit) || trialDailyLimit < 0) {
        this.trialDailyLimitRef.error = i18next.t('v.invalid_format');
        isValid = false;
      }

      if (isNaN(eraTrialDailyLimit) || eraTrialDailyLimit < 0) {
        this.eraTrialDailyLimitRef.error = i18next.t('v.invalid_format');
        isValid = false;
      }

      // commercial day limit
      if (isNaN(commercialDailyLimit) || commercialDailyLimit < 0) {
        this.commercialDailyLimitRef.error = i18next.t('v.invalid_format');
        isValid = false;
      }

      if (isNaN(eraCommercialDailyLimit) || eraCommercialDailyLimit < 0) {
        this.eraCommercialDailyLimitRef.error = i18next.t('v.invalid_format');
        isValid = false;
      }

      // all subtypes day limits
      licenseSubtypes.forEach((data) => {
        if (isNaN(data.dailyLimit) || data.dailyLimit < 0) {
          isValid = false;
        }
      });
    }

    return isValid;
  }

  isChange(value, fields = [
    'name',
    'login',
    'email',
    'role',
    'defaultManager',
    'trialDailyLimit',
    'commercialDailyLimit',
    'description',
  ]) {
    const { isEdit, userIm } = this.props;

    // Если форма создания, то всегда говорим что данные были изменены
    if (!isEdit) {
      return true;
    }

    // Если нет сохранённых данных, то нет и изменений
    if (!userIm) {
      return false;
    }

    // Проверяем были ли изменены данные хотя бы в одном поле(fields)
    return _some(
      fields,
      // Если новые и сохранённые данные существуют и неравны друг другу
      field => value[field] && userIm.get(field) && value[field] !== userIm.get(field),
    );
  }

  render() {
    const {
      managers,
      roles: userRoles,
    } = this.state;

    const { isEdit, authDataIm } = this.props;
    const currentUserRole = authDataIm.getIn(['data', 'role']);

    const {
      name,
      login,
      email,
      role,
      defaultManager,
      trialDailyLimit,
      commercialDailyLimit,
      eraTrialDailyLimit,
      eraCommercialDailyLimit,
      creatingRaidixOrder,
      creatingEraOrder,
      licenseSubtypes,
      description,
    } = this.getInitValue();

    let isDisabled = false;

    if (this.props.formComponentIm !== undefined) {
      isDisabled = this.props.formComponentIm.get('isDisabled');
    }

    return (
      <form className="form">
        <div className="form__row form__row_2">
          <div className="form__field">
            <label htmlFor="add-user-name" className="form__label form__label_required">
              {i18next.t('staff_or_partner_name')}
            </label>
            <Input
              type="text"
              id="add-user-name"
              defaultValue={name}
              ref={(ref) => { this.nameRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="James Morris"
              autoFocus
            />
          </div>
          <div className="form__field">
            <label htmlFor="add-user-login" className="form__label form__label_required">
              {i18next.t('login')}
            </label>
            <Input
              type="text"
              id="add-user-login"
              defaultValue={login}
              ref={(ref) => { this.loginRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="james"
              autoComplete="off"
            />
          </div>

          <div className="form__field">
            <label
              htmlFor="add-user-password"
              className={classnames('form__label', { form__label_required: !isEdit })}
            >
              {i18next.t('password')}
            </label>
            <Input
              type="password"
              id="add-user-password"
              ref={(ref) => { this.passwordRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="Secret password"
              autoComplete="off"
            />
          </div>

          <div className="form__field">
            <label
              htmlFor="add-user-repeat-password"
              className={classnames('form__label', { form__label_required: !isEdit })}
            >
              {i18next.t('repeat_password')}
            </label>
            <Input
              type="password"
              id="add-user-repeat-password"
              ref={(ref) => { this.confirmPasswordRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="Secret password"
              autoComplete="off"
            />
          </div>

          <div className="form__field">
            <label htmlFor="add-user-email" className="form__label">{i18next.t('email')}</label>
            <Input
              type="text"
              id="add-user-email"
              defaultValue={email}
              ref={(ref) => { this.emailRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="morris.j@company.com"
            />
          </div>
          <div className="form__field">
            <label htmlFor="add-user-role" className="form__label">{i18next.t('role')}</label>
            <Select
              id="add-user-role"
              className="select_form"
              name="form-field-name"
              clearable={false}
              options={userRoles}
              ref={(ref) => { this.roleRef = ref; }}
              defaultValue={role}
              onChange={this.onChangeRole}
            />
          </div>
        </div>

        {
          role === roles.PARTNER ? (
            <Fragment>
              <div className="form__row form__row_2">
                <div className="form__field">
                  <label htmlFor="add-user-manager" className="form__label form__label_required">
                    {i18next.t('manager')}
                  </label>
                  <Select
                    id="add-user-manager"
                    className="select_form"
                    name="form-field-name"
                    clearable={false}
                    options={managers}
                    emptyValue={0}
                    defaultValue={defaultManager}
                    ref={(ref) => { this.defaultManagerRef = ref; }}
                  />
                </div>
              </div>
              <div className="form__row form__row_2">
                <div className="form-line">
                  <div className="form-line__title">{i18next.t('raidix_product')}</div>
                </div>
              </div>

              <div className="form__row">
                <div className="form__field form__field-checkbox">
                  <Checkbox
                    id="add-user-creating-raidix-order"
                    ref={(ref) => {
                      this.creatingRaidixOrderRef = ref;
                    }}
                    name="creatingRaidixOrder"
                    value={1}
                    label={i18next.t('creating_order')}
                    defaultChecked={creatingRaidixOrder}
                  />
                </div>
              </div>

              <div className="form__row form__row_2">
                <div className="form__field">
                  <label htmlFor="add-user-trial-daily-limit" className="form__label">
                    {i18next.t('trial_day_limit')}
                  </label>
                  <Input
                    type="number"
                    id="add-user-trial-daily-limit"
                    defaultValue={trialDailyLimit}
                    className="form__field-text form__field-text_padding"
                    placeholder="0"
                    ref={(ref) => { this.trialDailyLimitRef = ref; }}
                  />
                </div>
                <div className="form__field">
                  <label htmlFor="add-user-commercial-daily-limit" className="form__label">
                    {i18next.t('commercial_day_limit')}
                  </label>
                  <Input
                    type="number"
                    id="add-user-commercial-daily-limit"
                    defaultValue={commercialDailyLimit}
                    className="form__field-text form__field-text_padding"
                    placeholder="0"
                    ref={(ref) => { this.commercialDailyLimitRef = ref; }}
                  />
                </div>
              </div>
              <div className="form__row form__row_2">
                { licenseSubtypes &&
                  <div className="form-line" />
                }
                { licenseSubtypes &&
                  Object.values(licenseSubtypes).map((subtype, index) => (
                    <div key={subtype.name} className="form__field">
                      <label
                        htmlFor={`add-user-${subtype.name}-daily-limit`}
                        className="form__label"
                      >
                        {i18next.t('commercial_day_limit_subtype', { subtype: subtype.name })}
                      </label>
                      <Input
                        type="number"
                        id={`add-user-${subtype.name}-daily-limit`}
                        defaultValue={subtype.dailyLimit}
                        className="form__field-text form__field-text_padding"
                        placeholder="0"
                        licenseSubtypeId={`${subtype.licenseSubtypeId}`}
                        ref={(ref) => { this.licenseSubtypesRef[index] = ref; }}
                      />
                    </div>
                  ))
              }
              </div>

              <div className="form__row form__row_2">
                <div className="form-line">
                  <div className="form-line__title">{i18next.t('era_product')}</div>
                </div>
              </div>

              <div className="form__row">
                <div className="form__field form__field-checkbox">
                  <Checkbox
                    id="add-user-creating-era-order"
                    ref={(ref) => {
                      this.creatingEraOrderRef = ref;
                    }}
                    name="creatingEraOrder"
                    value={1}
                    label={i18next.t('creating_order')}
                    defaultChecked={creatingEraOrder}
                  />
                </div>
              </div>

              <div className="form__row form__row_2">
                <div className="form__field">
                  <label htmlFor="add-user-era-trial-daily-limit" className="form__label">
                    {i18next.t('trial_day_limit')}
                  </label>
                  <Input
                    type="number"
                    id="add-user-era-trial-daily-limit"
                    defaultValue={eraTrialDailyLimit}
                    className="form__field-text form__field-text_padding"
                    placeholder="0"
                    ref={(ref) => { this.eraTrialDailyLimitRef = ref; }}
                  />
                </div>
                <div className="form__field">
                  <label htmlFor="add-user-era-commercial-daily-limit" className="form__label">
                    {i18next.t('commercial_day_limit')}
                  </label>
                  <Input
                    type="number"
                    id="add-user-era-commercial-daily-limit"
                    defaultValue={eraCommercialDailyLimit}
                    className="form__field-text form__field-text_padding"
                    placeholder="0"
                    ref={(ref) => { this.eraCommercialDailyLimitRef = ref; }}
                  />
                </div>
              </div>
            </Fragment>
          ) : null
        }

        <div className="form__row form__row_2">
          <div className="form-line" />
        </div>
        <div className="form__row form__row_2">
          <div className="form__field form__field_wide">
            <label htmlFor="add-user-notes" className="form__label">{i18next.t('notes')}</label>
            <textarea
              className="form__field-text form__field-text_padding"
              id="add-user-notes"
              defaultValue={description}
              placeholder="Description"
              ref={(ref) => { this.descriptionRef = ref; }}
            />
          </div>
        </div>

        {
          isEdit !== true || currentUserRole === roles.ADMIN ? (
            <div className="form__row form__row_2 form__row_footer">
              <div className="form__field form__field_width_auto">
                <button
                  className="button button_form button_submit"
                  onClick={this.onSubmit}
                  disabled={isDisabled}
                >
                  {isEdit !== true ? i18next.t('create') : i18next.t('save')}
                </button>
              </div>
            </div>
          ) : null
        }
      </form>
    );
  }
}
