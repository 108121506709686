/* global document */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import i18next from 'i18next';
import classnames from 'classnames';
import CancelIcon from 'img/cancel.svg';

const modalRoot = document.getElementById('modal-root');

export default class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onKeyup = this.onKeyup.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyup, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyup, false);
  }

  onKeyup(event) {
    // Закрываем все окна при нажатии Esc.
    // События слушает только активное окно, что бы избежать лишних вычислений
    if (event.keyCode === 27 &&
      this.props.modalComponentIm.get('activeId') === this.props.modalId
    ) {
      this.modalClose();
    }
  }

  onClick(event) {
    // close when layout was clicked
    if (event.target === this.layoutRef || event.target === this.wrapperRef) {
      this.modalClose();
    }
  }

  onFakeInputFocus({ howToChoose = 'first' } = {}) {
    // Выбираем все контролы окна на которых можно сфокусироваться
    const fieldsNodelist = this.layoutRef.querySelectorAll('input, select, button, textarea');
    // Преобразуем набор в массив
    const fields = Array.prototype.slice.call(fieldsNodelist);
    // Оставляем только активные контролы
    let activeFields = fields.filter(field => field.disabled !== true);

    // Инвертируем массив если необходимо выбрать последний контрол в списке
    if (howToChoose === 'last') {
      activeFields = activeFields.reverse();
    }

    activeFields[0].focus();
  }

  modalClose() {
    const {
      modalComponentIm,
      modalComponentHideAllSignal,
      onClose
    } = this.props;

    const options = modalComponentIm.getIn(['options']);

    if (!modalComponentIm.get('isDisabled')) {
      if (options && options.resolve) {
        options.resolve(false);
      }

      if (onClose !== undefined) {
        onClose();
      } else {
        modalComponentHideAllSignal();
      }
    }
  }

  render() {
    const {
      children,
      modalComponentIm,
      modalComponentHideSignal,
      modalComponentSubmitWrapperSignal,
      modalComponentUpdateDataSignal,
      modalId,
      wide = false,
    } = this.props;
    let modalBlock = null;

    const closeButton = (
      <button
        className="modal__close-button"
        onClick={this.modalClose}
        title={i18next.t('close')}
      >
        <span className="modal__close-button-icon">
          <CancelIcon
            width="20"
            height="20"
          />
        </span>
        <span className="modal__close-button-text">esc</span>
      </button>
    );

    if (modalComponentIm.get('activeId') === modalId) {
      const modalComponent = React.cloneElement(children, {
        modalComponentIm,
        modalComponentHideSignal,
        modalComponentSubmitWrapperSignal,
        modalComponentUpdateDataSignal,
        modalClose: this.modalClose,
      });

      modalBlock = (
        <div className="modal">
          <input
            className="modal__fake-input"
            onFocus={() => this.onFakeInputFocus({ howToChoose: 'last' })}
          />
          <div
            className="modal__layout"
            ref={(ref) => { this.layoutRef = ref; }}
            onClick={this.onClick}
          >
            {closeButton}
            <div
              ref={(ref) => { this.wrapperRef = ref; }}
              className={classnames('modal__wrapper', { modal__wrapper_wide: wide })}
            >
              <div className="modal__content">
                {modalComponent}
              </div>
            </div>
          </div>
          <input
            className="modal__fake-input"
            onFocus={() => this.onFakeInputFocus()}
          />
        </div>
      );
    }

    return ReactDOM.createPortal((
      <CSSTransitionGroup
        component="div"
        transitionName="modal"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={200}
      >
        {modalBlock}
      </CSSTransitionGroup>
    ), modalRoot);
  }
}
