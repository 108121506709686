/* global window */
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Input from 'components/controls/input';
import SupportPeriod from 'components/controls/support-period';
import i18next from 'i18next';
import moment from 'moment';
import _isNaN from 'lodash/isNaN';
import {
  supportPeriods,
  orderTypes,
  orderStatus,
  VALID_ONLY_ERA_HARDWARE_KEY_REX,
  DATE_FORMAT,
  PREMIUM_SUPPORT,
  productTypeTranslations,
  productTypes,
  driveTypes,
  EXTENDED_RAID,
  ERA_PRODUCT_TYPE,
  UNLIMITED_DRIVES,
  NOT_UNLIMITED_DRIVES,
} from 'shared/constants';
import { getLicenseSubtype } from 'shared/functions';
import {
  licenseCreateTypeTranslations,
  licenseCreateTypes,
  roles,
  DOWNLOAD_LICENSE_URL,
} from 'constants';
import Checkbox from 'components/controls/checkbox';
import Select from 'components/controls/select';
import DownloadIcon from 'img/download.svg';
import IconButton from 'components/controls/icon-button';
import SupportInfo from 'components/form/order/support-info';
import EraFunctionality from 'components/form/order/license/era-functionality';

export default class License extends PureComponent {
  constructor(props) {
    super(props);

    const {
      licenseOrderIm,
      originalLicenseData,
      isEdit,
      orderType,
      type,
      disabled,
    } = props;
    const licenseSubtypeCode = ERA_PRODUCT_TYPE;
    let drives = 0;
    let unlimitedDrives = NOT_UNLIMITED_DRIVES;
    let supportPeriod = '';
    let supportPeriodDays = '';
    let premiumSupport = 0;

    // Initialize the state with the data of the modified license
    if (originalLicenseData) {
      // When changing the hardware key, the number of disks
      // is inherited from the original license
      if (orderType === orderTypes.HWKEY_CHANGE) {
        drives = originalLicenseData.get('mergeDrives');
      }

      premiumSupport = originalLicenseData.get('premiumSupport');
      // supportPeriod, supportPeriodDays - do not initialize
    }

    const licenseSubtype = getLicenseSubtype(licenseSubtypeCode);

    // Initializes the disk value to the minimum value
    if (type !== licenseCreateTypes.CHANGE) {
      drives = licenseSubtype.getMinDriveCount();
    }

    // Инициализируем стейт данными сохранённой лицензии
    if (isEdit && licenseOrderIm) {
      drives = licenseOrderIm.get('drives');
      unlimitedDrives = licenseOrderIm.get('unlimitedDrives');
      // In the database, the data is stored as days
      supportPeriod = supportPeriods.SPECIFIED_NUMBER_OF_DAYS;
      supportPeriodDays = licenseOrderIm.get('supportPeriodDays');
      premiumSupport = licenseOrderIm.get('premiumSupport');

      if (supportPeriodDays === 0) {
        supportPeriod = supportPeriods.WITHOUT_SUPPORT;
      }
    }

    let disabledDrives = !!unlimitedDrives;
    let disableUnlimitedDrives = false;

    if (disabled) {
      disabledDrives = true;
      disableUnlimitedDrives = true;
    }

    this.state = {
      drives,
      unlimitedDrives,
      supportPeriod,
      supportPeriodDays,
      premiumSupport,
      disabledDrives,
      disableUnlimitedDrives,
      licenseSubtypeCode,
      licenseSubtype,
    };

    this.onRemoveLicense = this.onRemoveLicense.bind(this);
    this.onChangeDrives = this.onChangeDrives.bind(this);
    this.onChangeDrivesToUnlimited = this.onChangeDrivesToUnlimited.bind(this);
    this.onGenerateLicense = this.onGenerateLicense.bind(this);
    this.onDownloadLicense = this.onDownloadLicense.bind(this);
    this.onShowLicenseText = this.onShowLicenseText.bind(this);
    this.onChangeSupportPeriod = this.onChangeSupportPeriod.bind(this);
    this.isChangeLicenseUnlimitedDrives = this.isChangeLicenseUnlimitedDrives.bind(this);
  }

  componentWillMount() {
    // Так как значение состояние disabled хранится в state
    // то выставлять его начальное значение нужно в componentWillMount
    this.isChangeLicenseUnlimitedDrives();
    this.isTrialLicenseDisableDrives();
  }

  componentDidUpdate(prevProps, prevState) {
    // Сбрасываем ошибку, при смене статуса, так как для
    // пробной лицензии это поле заполняется автоматически
    if (prevProps.orderType !== orderTypes.TRIAL &&
      this.props.orderType === orderTypes.TRIAL
    ) {
      this.drivesRef.error = '';
    }

    if (prevState.unlimitedDrives !== this.state.unlimitedDrives) {
      // Forbid editing the number of discs if an unlimited number is selected
      const disabledDrives = this.state.unlimitedDrives || this.props.orderType === orderTypes.TRIAL;

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ disabledDrives });
    }
  }

  onRemoveLicense(e) {
    e.preventDefault();

    this.props.onRemoveLicense(this.props.id);
  }

  onGenerateLicense() {
    const { licenseOrderIm, onGenerateLicense, orderId } = this.props;

    let isValid = this.validate();

    if (this.hardwareKey1Ref && this.hardwareKey1Ref.value.length < 1) {
      this.hardwareKey1Ref.error = i18next.t('v.required');
      isValid = false;
    }

    if (isValid) {
      onGenerateLicense(
        licenseOrderIm.get('id'),
        {
          productType: productTypes.ERA,
          hardwareKey1: this.hardwareKey1Ref.value,
          orderId,
        },
      );
    }
  }

  onDownloadLicense() {
    const { licenseOrderIm } = this.props;
    const path = `${DOWNLOAD_LICENSE_URL}/${licenseOrderIm.get('id')}`;

    window.location.href = `${path}?productType=${productTypes.ERA}`;
  }

  onShowLicenseText() {
    this.props.modalComponentShowDelta('licenseText', {
      licenses: [{
        tabName: productTypeTranslations.ERA,
        content: this.props.licenseOrderIm.get('licenseText'),
      }],
    });
  }

  onChangeDrives(event) {
    let drives = event.value !== undefined ? event.value : event.target.value;

    drives = Number(drives);

    if (!_isNaN(drives)) {
      this.setState({ drives });
    }
  }

  onChangeDrivesToUnlimited(event) {
    this.setState({
      unlimitedDrives: event.target.checked ? UNLIMITED_DRIVES : NOT_UNLIMITED_DRIVES,
    });
  }

  onChangeSupportPeriod({ period, days }) {
    this.setState({
      supportPeriod: period,
      supportPeriodDays: days,
    });
  }

  get value() {
    const {
      isEdit,
      licenseOrderIm,
      originalLicenseData,
      type,
      orderType,
    } = this.props;

    const { licenseSubtype, unlimitedDrives } = this.state;

    let values = {
      customer: this.customerRef.value,
      drives: this.drivesRef.value,
      unlimitedDrives,
      description: this.descriptionRef.value,
      subtypeCode: licenseSubtype.getSubtypeCode(),
      ...this.eraFunctionalityRef.value,
    };

    if (this.hardwareKey1Ref) {
      values.hardwareKey1 = this.hardwareKey1Ref.value;
    }

    if (orderType === orderTypes.COMMERCIAL && licenseSubtype.isEditableSupportPeriod()) {
      const isPremiumSupport = licenseSubtype.isPremiumSupport() && this.premiumSupportRef.checked;

      values = {
        ...values,
        supportPeriod: this.supportPeriodRef.value,
        supportDays: Number(this.supportPeriodRef.days),
        premiumSupport: isPremiumSupport ? this.premiumSupportRef.props.value : 0,
      };
    }

    if (orderType === orderTypes.TRIAL) {
      values = {
        ...values,
        supportPeriod: supportPeriods.SPECIFIED_NUMBER_OF_DAYS,
        supportDays: this.trialSupportDaysRef.value,
      };
    }

    if (type === licenseCreateTypes.CHANGE && originalLicenseData) {
      values = {
        ...values,
        originalLicenseId: originalLicenseData.get('id'),
      };
    }

    if (isEdit && licenseOrderIm) {
      values = {
        ...values,
        id: licenseOrderIm.get('id'),
      };
    }

    return values;
  }

  getInitValue() {
    const { licenseOrderIm, originalLicenseData, isEdit, type } = this.props;
    const {
      drives,
      unlimitedDrives,
      supportPeriod,
      supportPeriodDays,
      premiumSupport,
      licenseSubtype,
      licenseSubtypeCode,
    } = this.state;
    const value = {
      drives,
      unlimitedDrives,
      originalLicense: '',
      supportPeriod,
      supportPeriodDays,
      premiumSupport,
      mergeDrives: 0,
      licenseSubtype,
      licenseSubtypeCode,
    };
    let hardwareKey;

    if (originalLicenseData) {
      value.customer = originalLicenseData.get('customer');
      value.description = originalLicenseData.get('description');
      value.originalLicense = originalLicenseData.get('id');
      value.mergeDrives = originalLicenseData.get('mergeDrives');
      value.driveType = originalLicenseData.get('driveType');
      value.oldKernelVersion = originalLicenseData.get('oldKernelVersion');
      value.extendedRaid = originalLicenseData.get('extendedRaid');
      value.linuxDistribution = originalLicenseData.get('linuxDistribution');
      value.linuxKernel = originalLicenseData.get('linuxKernel');
      value.productVersion = originalLicenseData.get('productVersion');

      hardwareKey = originalLicenseData.get('hardwareKey');
    }

    if (isEdit && licenseOrderIm) {
      value.customer = licenseOrderIm.get('customer');
      value.description = licenseOrderIm.get('description');
      value.originalLicense = licenseOrderIm.get('originalLicenseId');
      value.generatedBy = licenseOrderIm.get('generatedBy');
      value.generatedByUserName = licenseOrderIm.get('generatedByUserName');
      value.generatedDate = licenseOrderIm.get('generatedDate');
      value.mergeDrives = licenseOrderIm.get('mergeDrives');
      value.driveType = licenseOrderIm.get('driveType');
      value.oldKernelVersion = licenseOrderIm.get('oldKernelVersion');
      value.extendedRaid = licenseOrderIm.get('extendedRaid');
      value.linuxDistribution = licenseOrderIm.get('linuxDistribution');
      value.linuxKernel = licenseOrderIm.get('linuxKernel');
      value.productVersion = licenseOrderIm.get('productVersion');

      if (
        // Условие для ситуаци, когда заказ на изменение и новые данные присутствуют
        (type === licenseCreateTypes.CHANGE && licenseOrderIm.get('hardwareKey')) ||
        // Условие для любых заказов на новые лицензии
        type === licenseCreateTypes.NEW) {
        hardwareKey = licenseOrderIm.get('hardwareKey');
      }
    }

    if (hardwareKey) {
      [value.hardwareKey1, value.hardwareKey2] = hardwareKey.split(' ');
    }

    return value;
  }

  validate() {
    const { orderType } = this.props;
    let isValid = true;

    if (orderType === orderTypes.COMMERCIAL) {
      const { licenseSubtype } = this.state;
      let supportPeriod;
      let supportDays;

      if (licenseSubtype.isEditableSupportPeriod()) {
        supportPeriod = this.supportPeriodRef.value;
        supportDays = Number(this.supportPeriodRef.days);
      } else {
        const { value, days } = licenseSubtype.getDefaultSupportPeriod();

        supportPeriod = value;
        supportDays = days;
      }

      // support period
      if (supportPeriod <= 0) {
        this.supportPeriodRef.error = i18next.t('v.required');
        isValid = false;
      } else if (supportPeriod === supportPeriods.SPECIFIED_NUMBER_OF_DAYS &&
        (isNaN(supportDays) || supportDays <= 0)
      ) {
        if (supportDays <= 0) {
          this.supportPeriodRef.error = i18next.t('v.required');
          isValid = false;
        } else if (isNaN(supportDays)) {
          this.supportPeriodRef.error = i18next.t('v.invalid_format');
          isValid = false;
        }
      }
    }

    if (orderType === orderTypes.COMMERCIAL || orderType === orderTypes.TRIAL) {
      // drives
      if (this.drivesRef.value < 0) {
        this.drivesRef.error = i18next.t('v.required');
        isValid = false;
      }
    }

    // hardware key
    if (
      orderType === orderTypes.HWKEY_CHANGE
      && this.hardwareKey1Ref
      && this.hardwareKey1Ref.value.length < 1
    ) {
      this.hardwareKey1Ref.error = i18next.t('v.required');
      isValid = false;
    }

    if (
      this.hardwareKey1Ref &&
      this.isHardwareKeyValidation(this.hardwareKey1Ref.value)
    ) {
      this.hardwareKey1Ref.error = i18next.t('v.invalid_hw_format');

      isValid = false;
    }

    if (!this.eraFunctionalityRef.validate()) {
      isValid = false;
    }

    return isValid;
  }

  isHardwareKeyValidation(hardwareKey) {
    return hardwareKey && hardwareKey.length > 0
      && !VALID_ONLY_ERA_HARDWARE_KEY_REX.test(hardwareKey);
  }

  isChangeLicenseUnlimitedDrives() {
    // if the original license has unlimited disks
    if (this.props.type === licenseCreateTypes.CHANGE && this.state.unlimitedDrives) {
      this.setState({ disableUnlimitedDrives: true });
    }
  }

  isTrialLicenseDisableDrives() {
    if (this.props.orderType === orderTypes.TRIAL) {
      this.setState({
        disabledDrives: true,
        disableUnlimitedDrives: true,
      });
    }
  }

  render() {
    const {
      id,
      isEdit,
      type,
      orderType,
      orderCurrentStatus,
      disabled = false,
      hideRemoveButton = false,
      formComponentIm,
      originalLicenseData,
      licenseOrderIm,
      productVersionsDataIm,
      currentUserRole,
    } = this.props;

    const {
      supportPeriod,
      supportPeriodDays,
      customer,
      drives,
      unlimitedDrives,
      driveType,
      oldKernelVersion,
      extendedRaid,
      mergeDrives,
      description,
      originalLicense,
      hardwareKey1,
      generatedBy,
      generatedDate,
      generatedByUserName,
      premiumSupport,
      licenseSubtype,
      linuxDistribution,
      linuxKernel,
      productVersion,
    } = this.getInitValue();

    let drivesValue = drives;
    let extendedRaidValue = extendedRaid;
    let driveTypeValue = driveType;
    let currentDrivesValue = 0;
    let supportExpirationDate;
    let currentProductVersion;

    if (isEdit && licenseOrderIm) {
      currentDrivesValue = licenseOrderIm.get('drives');
      currentProductVersion = licenseOrderIm.get('productVersion');
    }

    if (originalLicenseData) {
      supportExpirationDate = originalLicenseData.get('supportExpirationDate');
    }

    const drivesList = [];
    const maxDriveCount = licenseSubtype.getMaxDriveCount();

    // The administrator can create a license with 0 disks.
    // Other users can specify 0 only when changing an existing license
    if (
      currentUserRole === roles.ADMIN ||
      type === licenseCreateTypes.CHANGE ||
      drivesValue === 0
    ) {
      drivesList.push({ label: 0, value: 0 });
    }

    if (licenseSubtype.isListDrives()) {
      licenseSubtype.getListDrives().forEach((value) => {
        if (
          // Check available disk count
          value > ((maxDriveCount - mergeDrives) + currentDrivesValue) &&
          currentUserRole !== roles.ADMIN &&
          orderType !== orderTypes.HWKEY_CHANGE
        ) {
          return;
        }

        drivesList.push({ label: value, value });
      });
    }

    if (orderType === orderTypes.TRIAL) {
      drivesValue = licenseSubtype.isListDrives() ? maxDriveCount : 'UNLIMITED';
      driveTypeValue = driveTypes.NVME;
      extendedRaidValue = EXTENDED_RAID;
    }

    if (unlimitedDrives) {
      drivesValue = 'UNLIMITED';
    }

    const generatedDateFormat = moment(generatedDate, 'X').format(DATE_FORMAT);

    let isDisabledGenerateButton = false;
    let isDisabledHardwareKey = true;
    let isDisabledTrialSupportDays = false;
    // TODO: move 30 to constants
    let trialSupportDays = 30;

    if (currentUserRole === roles.PARTNER || disabled) {
      isDisabledTrialSupportDays = true;
    }

    if (isEdit) {
      trialSupportDays = supportPeriodDays;
    }

    // If the license is not yet generated
    if (
      generatedBy === undefined && (
        orderType === orderTypes.HWKEY_CHANGE ||
        type !== licenseCreateTypes.CHANGE
      )
    ) {
      isDisabledHardwareKey = false;
    }

    if (formComponentIm && formComponentIm.get('isDisabled') === true) {
      isDisabledHardwareKey = true;
      isDisabledGenerateButton = true;
    }

    const generateLicenseButton = isEdit && orderCurrentStatus === orderStatus.GENERATION_READY ? (
      <div className="controls-group controls-group_form">
        <div className="controls-group__control">
          <input
            type="button"
            className="button button_submit"
            value={i18next.t('generate_license_file')}
            onClick={this.onGenerateLicense}
            disabled={isDisabledGenerateButton}
          />
        </div>
      </div>
    ) : null;

    const supportPeriodField = (licenseSubtype.isEditableSupportPeriod() &&
      (orderType === orderTypes.COMMERCIAL || orderType === orderTypes.HWKEY_CHANGE)) ? (
        <div className="form__row form__row_2">
          <div className="form__field">
            <label
              htmlFor={`license-form-${id}-support-period`}
              className="form__label form__label_required"
            >
              {i18next.t('support_period')}
            </label>
            <SupportPeriod
              id={`license-form-${id}-support-period`}
              ref={(ref) => { this.supportPeriodRef = ref; }}
              defaultValue={supportPeriod}
              defaultDays={supportPeriodDays}
              disabled={disabled}
              onChange={this.onChangeSupportPeriod}
            />
          </div>
          <div className="form__field">
            <SupportInfo
              orderType={orderType}
              supportPeriod={supportPeriod}
              supportPeriodDays={supportPeriodDays}
              supportExpirationDateUnix={supportExpirationDate}
              generatedDateUnix={generatedDate}
              showOnlyNewSupport={generatedBy || type !== licenseCreateTypes.CHANGE}
            />
          </div>
        </div>
      ) : null;

    const validityInDaysField = (orderType !== orderTypes.COMMERCIAL &&
      orderType !== orderTypes.HWKEY_CHANGE) ? (
        <div className="form__row form__row_2">
          <div className="form__field">
            <label
              htmlFor={`${id}-days`}
              className={classnames('form__label', {
                form__label_required: !isDisabledTrialSupportDays,
              })}
            >
              {i18next.t('validity_in_days')}
            </label>
            <Input
              type="number"
              id={`${id}-days`}
              defaultValue={trialSupportDays}
              ref={(ref) => { this.trialSupportDaysRef = ref; }}
              onChange={this.onChangeDays}
              className="form__field-text form__field-text_padding"
              placeholder="30"
              disabled={isDisabledTrialSupportDays}
              maxLength="3"
            />
          </div>
        </div>
      ) : null;

    return (
      <div className="license-form__license">
        <div className="form__row form__row_2">
          <div className="license-form__header">
            <div className="license-form__title">
              {i18next.t(licenseCreateTypeTranslations[type])}
              {
                isEdit && licenseOrderIm ? (
                  <span className="license-form__subtitle"> #{licenseOrderIm.get('id')}</span>
                ) : null
              }
            </div>
            {
              hideRemoveButton !== true ? (
                <a
                  href="#remove-license"
                  className="link link_dashed"
                  onClick={this.onRemoveLicense}
                >
                  {i18next.t('delete')}
                </a>
              ) : null
            }
          </div>
        </div>

        {
          type === licenseCreateTypes.CHANGE ? (
            <div className="form__row form__row_2">
              <div className="form__field">
                <label htmlFor={`license-form-${id}-original-license`} className="form__label">
                  {i18next.t('original_license')}
                </label>
                <Input
                  type="text"
                  id={`license-form-${id}-original-license`}
                  className="form__field-text form__field-text_padding"
                  placeholder=""
                  defaultValue={originalLicense}
                  disabled
                />
              </div>
            </div>
          ) : null
        }

        {supportPeriodField}
        {validityInDaysField}

        {
          (
            licenseSubtype.isPremiumSupport() &&
            (orderType === orderTypes.COMMERCIAL || orderType === orderTypes.HWKEY_CHANGE)
          ) ? (
            <div className="form__row">
              <div className="form__field form__field-checkbox">
                <Checkbox
                  id={`license-form-${id}-premium-support`}
                  ref={(ref) => {
                    this.premiumSupportRef = ref;
                  }}
                  name="premiumSupport"
                  value={PREMIUM_SUPPORT}
                  label={i18next.t('premium_support')}
                  disabled={disabled}
                  defaultChecked={premiumSupport}
                />
              </div>
            </div>
          ) : null
        }
        <div className="form__row form__row_2">
          <div className="form__field">
            <label htmlFor={`license-form-${id}-customer`} className="form__label">
              {i18next.t('customer')}
            </label>
            <Input
              type="text"
              id={`license-form-${id}-customer`}
              ref={(ref) => { this.customerRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="IBM"
              defaultValue={customer}
              disabled={disabled}
            />
          </div>
          {
            licenseSubtype.isEditableDrives() ? (
              <div className="form__field">
                <label
                  htmlFor={`license-form-${id}-drives`}
                  className="form__label form__label_required"
                >
                  {i18next.t('drives')}
                </label>
                {
                  licenseSubtype.isListDrives() ? (
                    <Select
                      id={`license-form-${id}-drives`}
                      defaultValue={drivesValue}
                      className="select_form"
                      options={drivesList}
                      ref={(ref) => { this.drivesRef = ref; }}
                      clearable={false}
                      emptyValue={0}
                      disabled={this.state.disabledDrives}
                      onChange={this.onChangeDrives}
                      isSearchable={false}
                    />
                  ) : (
                    <div className="drives">
                      <Input
                        id={`license-form-${id}-drives`}
                        value={drivesValue}
                        type={this.state.disabledDrives ? 'text' : 'number'}
                        ref={(ref) => { this.drivesRef = ref; }}
                        onChange={this.onChangeDrives}
                        className="form__field-text form__field-text_padding drives__input"
                        placeholder="Type here"
                        disabled={this.state.disabledDrives}
                        maxLength="9"
                      />
                      <div className="drives__checkbox">
                        <Checkbox
                          onChange={this.onChangeDrivesToUnlimited}
                          id={`license-form-${id}-unlimited-drives`}
                          name="unlimitedDrives"
                          value={this.state.disabledDrives}
                          label={i18next.t('unlimited')}
                          disabled={this.state.disableUnlimitedDrives}
                          checked={unlimitedDrives}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            ) : null
          }
        </div>
        <div>
          <EraFunctionality
            id={id}
            orderType={orderType}
            disabled={disabled}
            oldKernelVersion={oldKernelVersion}
            driveType={driveTypeValue}
            extendedRaid={extendedRaidValue}
            linuxDistribution={linuxDistribution}
            linuxKernel={linuxKernel}
            productVersion={productVersion}
            currentProductVersion={currentProductVersion}
            productVersionsDataIm={productVersionsDataIm}
            ref={(ref) => { this.eraFunctionalityRef = ref; }}
          />

          <div className="form__row form__row_2">
            <div className="form__field form__field_wide">
              <label htmlFor={`license-form-${id}-notes`} className="form__label">
                {i18next.t('notes')}
                {
                  currentUserRole !== roles.PARTNER ? (
                    <span className="form__label-description">
                      {` (${i18next.t('visible_for_partners')})`}
                    </span>
                  ) : null
                }
              </label>
              <textarea
                className="form__field-text form__field-text_padding"
                id={`license-form-${id}-notes`}
                defaultValue={description}
                placeholder="Description"
                ref={(ref) => { this.descriptionRef = ref; }}
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        {
          (isEdit && orderCurrentStatus === orderStatus.GENERATION_READY) ||
          orderType === orderTypes.HWKEY_CHANGE ? (
            <div className="form__row form__row_hardware-key">
              <div className="form__field form__field_wide">
                <label htmlFor={`license-form-${id}-hardware-key-1`} className="form__label">
                  {i18next.t('hardware_key')}
                </label>
                <Input
                  type="text"
                  id={`license-form-${id}-hardware-key-1`}
                  ref={(ref) => { this.hardwareKey1Ref = ref; }}
                  className="form__field-text form__field-text_padding"
                  placeholder=""
                  defaultValue={hardwareKey1}
                  disabled={isDisabledHardwareKey}
                />
              </div>

              {
                generatedBy ? (
                  <div className="generated-info">
                    <div>
                      <div>
                        <span>{i18next.t('generated_by')}:</span>
                        <span className="generated-info__value">{generatedByUserName}</span>
                      </div>
                      <div>
                        <span>{i18next.t('generated_date')}:</span>
                        <span className="generated-info__value">{generatedDateFormat}</span>
                      </div>
                    </div>
                    <div className="controls-group controls-group_form controls-group_column">
                      <div className="controls-group__control">
                        <IconButton
                          type="button"
                          icon={DownloadIcon}
                          onClick={this.onDownloadLicense}
                          text={i18next.t('download_license_file')}
                        />
                      </div>

                      <div className="controls-group__control">
                        <input
                          type="button"
                          className="button"
                          value={i18next.t('show_license_file_contents')}
                          onClick={this.onShowLicenseText}
                        />
                      </div>
                    </div>
                  </div>
                ) : generateLicenseButton
              }
            </div>
          ) : null
        }
      </div>
    );
  }
}
