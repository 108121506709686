import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { Table } from 'containers';
import {
  roles,
  orderStatus,
  orderTypes,
  CSV_FILE_NAME_DATE_FORMAT,
} from 'constants';
import { orderTypeTranslations, orderStatusTranslations } from 'shared/constants';
import { List, Map } from 'immutable';
import { OrderToolbar } from 'components/toolbars';
import {
  OrderStatusCell,
  DateCell,
  PriceCell,
  LinkCell,
} from 'components/table/cell';

export default class OrdersPage extends PureComponent {
  constructor(props) {
    super(props);

    this.partners = List();
    this.managers = List();
    this.status = [];
    this.types = [];

    const users = props.usersDataIm.get('data');

    users.forEach((user) => {
      const userRole = user.get('role');

      if (userRole === roles.PARTNER) {
        this.partners = this.partners.push(user);
      }

      if (userRole === roles.MANAGER) {
        this.managers = this.managers.push(user);
      }
    });

    this.status = Object.entries(orderStatus).map(([statusName, statusValue]) => ({
      label: i18next.t(orderStatusTranslations[statusName]),
      value: statusValue,
    }));

    this.types = Object.entries(orderTypes).map(([typeName, typeValue]) => ({
      label: i18next.t(orderTypeTranslations[typeName]),
      value: typeValue,
    }));

    this.hardwareKeyFilterFunction = this.hardwareKeyFilterFunction.bind(this);
    this.onExportToFile = this.onExportToFile.bind(this);
  }

  onExportToFile() {
    const { productType, ordersCsvDataGetSignal } = this.props;

    this.ordersTableRef
      .getWrappedInstance()
      .exportToFile({
        productType,
        dataGetSignal: ordersCsvDataGetSignal,
        fileName: `${productType.toLowerCase()}_orders_${moment().format(CSV_FILE_NAME_DATE_FORMAT)}`,
      });
  }

  clearString(string) {
    return string.trim().toLowerCase();
  }

  hardwareKeyFilterFunction({ model, value }) {
    const orderId = model.get('id');
    const memoizeKey = value.toString();

    // Создаём массив с запоминаемыми значениями
    if (!Array.isArray(this.memoizeHardwareKeyFilter)) {
      this.memoizeHardwareKeyFilter = [];
    }

    // Если для искомого значения уже высчитаны данные, то производим поиск среди них
    if (this.memoizeHardwareKeyFilter[memoizeKey]) {
      return this.memoizeHardwareKeyFilter[memoizeKey].includes(orderId);
    }

    // Несколько значений в строке разделаются запятой
    const splitValue = value.indexOf(',') !== -1 && value.length > 1 ?
      value.split(',') :
      value;

    // Получаем все лицензии
    const licenses = this.props.licensesDataIm.get('data');
    // Id лицензий в которых были найдены совпадения
    const filteredOrders = [];

    licenses.forEach((license) => {
      const hardwareKey = license.get('hardwareKey');

      if (hardwareKey) {
        let isFiltered = true;

        if (Array.isArray(splitValue)) {
          isFiltered = splitValue.some(
            data => this.clearString(hardwareKey).indexOf(this.clearString(data)) !== -1
          );
        } else if (this.clearString(hardwareKey).indexOf(this.clearString(splitValue)) === -1) {
          isFiltered = false;
        }

        if (isFiltered) {
          filteredOrders.push(license.get('orderId'));
        }
      }
    });

    // Так как лицензии в таблице с заказами изменить нельзя,
    // то запоминаем результат только по искомой подстроке(value)
    this.memoizeHardwareKeyFilter[memoizeKey] = filteredOrders;

    return filteredOrders.includes(orderId);
  }

  render() {
    const {
      ordersDataIm,
      authDataIm,
      tableId,
      hideAddButton,
      productType,
    } = this.props;

    const cells = [
      {
        id: 'uid',
        // TODO: Передавать ссылку не в getValue
        getValue: model => ({
          text: model.get('uid'),
          link: `/orders/${model.get('id')}`,
        }),
        className: 'table__cell_2',
        name: i18next.t('id'),
        sort: {
          type: 'arithmetic',
          field: 'uid',
        },
        component: LinkCell,
      },
      {
        id: 'status',
        getValue: model => model.get('status'),
        className: 'table__cell_4',
        name: i18next.t('status'),
        sort: {
          type: 'alphabetic',
          field: 'status',
        },
        component: OrderStatusCell,
      },
      {
        id: 'status_date',
        getValue: model => model.get('statusDate'),
        className: 'table__cell_5',
        name: i18next.t('date_changed_status'),
        sort: {
          type: 'arithmetic',
          field: 'statusDate',
        },
        component: DateCell,
      },
      {
        id: 'partner',
        getValue: model => model.get('partnerName'),
        className: 'table__cell_3',
        name: i18next.t('partner'),
        sort: {
          type: 'alphabetic',
          field: 'partnerName',
        },
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
      },
      {
        id: 'price',
        getValue: model => ({
          price: model.get('price'),
          currency: model.get('currency'),
        }),
        className: 'table__cell_4',
        name: i18next.t('price'),
        component: PriceCell,
      },
      {
        id: 'invoice',
        getValue: model => model.get('invoice'),
        className: 'table__cell_6',
        name: i18next.t('invoice'),
        sort: {
          type: 'alphabetic',
          field: 'invoice',
        },
      },
    ];

    const filterFields = [
      {
        type: 'multiSelect',
        key: 'partnerId',
        name: i18next.t('partner'),
        getValue: item => item.get('id'),
        getText: item => item.get('name'),
        items: this.partners,
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
        sort: true,
      },
      {
        type: 'multiSelect',
        key: 'managerId',
        name: i18next.t('manager'),
        getValue: item => item.get('id'),
        getText: item => item.get('name'),
        items: this.managers,
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
        sort: true,
      },
      {
        type: 'orderStatus',
        key: 'status',
        name: i18next.t('status'),
        items: this.status,
      },
      {
        type: 'multiSelect',
        key: 'type',
        name: i18next.t('type'),
        items: this.types,
      },
      {
        type: 'dateInterval',
        key: 'creationDate',
        name: i18next.t('creation_date'),
      },
      {
        type: 'text',
        key: 'purchaseOrder',
        name: i18next.t('purchase_order'),
      },
      {
        type: 'text',
        key: 'invoice',
        name: i18next.t('invoice'),
      },
      {
        type: 'text',
        key: 'hardware_key',
        name: i18next.t('hardware_key'),
        filterFunction: this.hardwareKeyFilterFunction,
      },
    ];

    const quickFilterFields = [
      {
        key: 'pending',
        name: i18next.t('pending'),
        values: {
          status: orderStatus.PENDING,
          type: orderTypes.COMMERCIAL,
        },
        allowedToShowFor: [roles.ACCOUNTANT],
      },
      {
        key: 'documents_sent',
        name: i18next.t('documents_sent'),
        values: {
          status: orderStatus.DOCUMENTS_SENT,
          type: orderTypes.COMMERCIAL,
        },
        allowedToShowFor: [roles.ACCOUNTANT],
      },
      {
        key: 'documents_sent',
        name: i18next.t('documents_sent'),
        values: {
          status: orderStatus.DOCUMENTS_SENT,
          type: orderTypes.HWKEY_CHANGE,
        },
        allowedToShowFor: [roles.ENGINEER],
      },
      {
        key: 'cancelled',
        name: i18next.t('not_canceled'),
        values: {
          status: [
            orderStatus.PENDING,
            orderStatus.DOCUMENTS_SENT,
            orderStatus.GENERATION_READY,
          ],
        },
      },
    ];

    const defaultSort = Map({
      field: 'uid',
      type: 'arithmetic',
      order: 'desc',
    });

    return (
      <Fragment>
        <OrderToolbar
          productType={productType}
          tableId={tableId}
          filterFields={filterFields}
          quickFilterFields={quickFilterFields}
          authDataIm={authDataIm}
          hideAddButton={hideAddButton}
          onExportToFile={this.onExportToFile}
        />
        <div className="content__body">
          <Table
            id={tableId}
            items={ordersDataIm}
            defaultSort={defaultSort}
            cells={cells}
            filterFields={filterFields}
            ref={(ref) => { this.ordersTableRef = ref; }}
          />
        </div>
      </Fragment>
    );
  }
}
