import React, { Component } from 'react';
import i18next from 'i18next';
import browserHistory from 'browser-history';

export default class BackLink extends Component {
  constructor(props) {
    super(props);

    this.onBackClick = this.onBackClick.bind(this);
  }

  onBackClick() {
    if (this.props.to) {
      browserHistory.replace(this.props.to);
    } else {
      browserHistory.goBack();
    }
  }

  render() {
    const text = typeof this.props.text === 'string' ? this.props.text : i18next.t('back');

    return (
      <button
        className="content__back-link back-link gray-text"
        onClick={this.onBackClick}
      >
        <span className="back-link__text">{text}</span>
      </button>
    );
  }
}
