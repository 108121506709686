import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import classnames from 'classnames';
import { ITEMS_PER_PAGE_OPTIONS } from 'constants';
import _isFunction from 'lodash/isFunction';
import { List } from 'immutable';
import fileSaver from 'file-saver';
import { zipIntArray } from 'shared/functions';
import TableHeader from './header';
import TableRow from './row';
import Pagination from './pagination';

const EmptyTableContent = ({ isEmpty }) => (
  <div className="table__row table__row_empty">
    <div className="empty-table">
      <div className="empty-table__title">
        {isEmpty ? i18next.t('no_data_here') : i18next.t('filter_results_empty')}
      </div>
      {isEmpty ?
        null :
        <div className="empty-table__text">{i18next.t('change_filter_parameters')}</div>
      }
    </div>
  </div>
);

const TableContent = ({ items, cells, onClickRow, actionButtons }) => (
  <Fragment>
    {items.map(item => (
      <TableRow
        key={item.get('id')}
        item={item}
        cells={cells}
        onClickRow={onClickRow}
        actionButtons={actionButtons}
      />
    ))}
  </Fragment>
);

export default class Table extends PureComponent {
  constructor(props) {
    super(props);

    let filters = false;

    if (props.tableComponentIm && props.tableComponentIm.get('filters')) {
      filters = props.tableComponentIm.get('filters');
    }

    this.state = {
      filteredItems: this.filter(props.items, filters),
    };

    // Извлечем функции фильтрации из filterFields
    this.filterFunctions = {};
    this.quickFilterFunctions = {};

    if (Array.isArray(props.filterFields)) {
      props.filterFields.forEach((filterField) => {
        if (filterField.type === 'dateInterval') {
          this.filterFunctions[filterField.key] = this.dateRangeFilterFunction;
        }

        if (_isFunction(filterField.filterFunction)) {
          this.filterFunctions[filterField.key] = filterField.filterFunction;
        }
      });
    }

    if (Array.isArray(props.quickFilterFields)) {
      props.quickFilterFields.forEach((filterField) => {
        if (filterField.fields) {
          filterField.fields.forEach((field) => {
            if (field.type === 'dateInterval') {
              this.quickFilterFunctions[field.key] = this.dateRangeFilterFunction;
            }

            if (_isFunction(field.filterFunction)) {
              this.quickFilterFunctions[field.key] = field.filterFunction;
            }
          });
        }

        if (filterField.type === 'dateInterval') {
          this.quickFilterFunctions[filterField.key] = this.dateRangeFilterFunction;
        }

        if (_isFunction(filterField.filterFunction)) {
          this.quickFilterFunctions[filterField.key] = filterField.filterFunction;
        }
      });
    }
    this.exportToFile = this.exportToFile.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let nextFilters = false;
    let currentFilters = false;

    if (nextProps.tableComponentIm.get('filters')) {
      nextFilters = nextProps.tableComponentIm.get('filters');
    }

    if (this.props.tableComponentIm.get('filters')) {
      currentFilters = this.props.tableComponentIm.get('filters');
    }

    if (nextProps.items !== this.props.items || nextFilters !== currentFilters) {
      this.setState({
        filteredItems: this.filter(
          nextProps.items,
          nextProps.tableComponentIm.get('filters'),
          nextProps.tableComponentIm.get('quickFilter'),
        ),
      });
    }
  }

  componentWillUnmount() {
    this.props.tableComponentResetDelta(this.props.id);
  }

  sort(items, sort) {
    const type = sort.get('type');
    const field = sort.get('field');
    const order = sort.get('order');

    switch (type) {
      case 'arithmetic': {
        return items.sort((a, b) => {
          const fieldA = a.get(field);
          const fieldB = b.get(field);

          // Перемещаем пустые поля под отсортированные данные
          if (fieldA === undefined && fieldB !== undefined) return 1;
          if (fieldB === undefined && fieldA !== undefined) return -1;

          if (order === 'asc') {
            return fieldA - fieldB;
          }

          return fieldB - fieldA;
        });
      }

      case 'alphabetic': {
        return items.sort((a, b) => {
          let fieldA = a.get(field);
          let fieldB = b.get(field);

          fieldA = fieldA === undefined ? '' : fieldA;
          fieldB = fieldB === undefined ? '' : fieldB;

          if (order === 'asc') {
            if (fieldA === '' && fieldB !== '') return 1;
            if (fieldB === '' && fieldA !== '') return -1;
            if (fieldA < fieldB) return -1;
            if (fieldA > fieldB) return 1;
          }

          if (fieldA === '' && fieldB !== '') return -1;
          if (fieldB === '' && fieldA !== '') return 1;
          if (fieldA > fieldB) return -1;
          if (fieldA < fieldB) return 1;

          return 0;
        });
      }

      default: {
        // unknown sort type
        return items;
      }
    }
  }

  dateRangeFilterFunction({ model, fieldName, value }) {
    const fieldValue = model.get(fieldName);
    const fromTimestamp = Number(value[0]);
    const toTimestamp = Number(value[1]);
    // Третий параметр массива используется если необходимо найти данные
    // за определенный период и включить в результат запись без заданных значений
    const isEmpty = value[2];
    const fieldValueTimestamp = Number(fieldValue);

    // Поиск элементов с незаполненными полями дат
    if ((fromTimestamp === -1 || toTimestamp === -1 || isEmpty === true) && !fieldValueTimestamp) {
      return true;
    }

    if (fromTimestamp && toTimestamp) {
      return (fromTimestamp <= fieldValueTimestamp) && (fieldValueTimestamp <= toTimestamp);
    } else if (fromTimestamp) {
      return fromTimestamp <= fieldValueTimestamp;
    } else if (toTimestamp) {
      return fieldValueTimestamp <= toTimestamp;
    }

    return true;
  }

  clearString(string) {
    return string.trim().toLowerCase();
  }

  filter(items, filters, quickFilter) {
    if (!filters || !filters.size) {
      return items;
    }

    const filterFunctions = quickFilter !== false
      ? this.quickFilterFunctions
      : this.filterFunctions;

    return items.filter((model) => {
      let isFiltered = true;

      /* eslint consistent-return: "off" */
      filters.forEach((value, fieldName) => {
        // if there is specific filter function - use it
        const specificFilterFunction = filterFunctions[fieldName];

        if (specificFilterFunction) {
          isFiltered = specificFilterFunction({ model, fieldName, value });

          // Если спец функция вернула false, то сразу выходим из цикла
          if (!isFiltered) {
            // break
            return false;
          }

          // continue
          return;
        }

        // else use default filters
        const fieldValue = model.get(fieldName);

        if (typeof fieldValue === 'string') {
          // Несколько значений в строке разделаются запятой
          const splitValue = value.indexOf(',') !== -1 && value.length > 1 ?
            value.split(',') :
            value;

          if (Array.isArray(splitValue)) {
            isFiltered = splitValue.some(
              data => this.clearString(fieldValue).indexOf(this.clearString(data)) !== -1
            );
          } else if (this.clearString(fieldValue).indexOf(this.clearString(splitValue)) === -1) {
            isFiltered = false;
          }
        } else if (Array.isArray(value)) {
          // Если искомое значение является списком, то ищем вхождение в каждый элемент списка
          if (List.isList(fieldValue) || Array.isArray(fieldValue)) {
            isFiltered = value.some(data => fieldValue.includes(data));
          } else {
            isFiltered = value.some(data => fieldValue == data); // eslint-disable-line eqeqeq
          }
        } else if (fieldValue != value) { // eslint-disable-line eqeqeq
          isFiltered = false;
        }

        if (!isFiltered) {
          return false;
        }
      });

      return isFiltered;
    });
  }

  async exportToFile({ dataGetSignal, productType, fileName = 'export' }) {
    const { items } = this.props;
    const { filteredItems } = this.state;
    let result;

    // Если нечего экспортировать
    if (filteredItems.size <= 0) {
      return;
    }

    if (items.size === filteredItems.size) {
      // Запрашиваем все записи
      result = await dataGetSignal({
        data: {
          all: true,
          productType,
        }
      });
    } else {
      let ids = this.state.filteredItems.map(item => item.get('id')).toArray();

      // Уменьшаем количество передаваемых идентификаторов
      ids = zipIntArray(ids);

      // Запрашиваем отфильтрованные данные
      result = await dataGetSignal({
        data: { ids, productType },
      });
    }

    if (result !== undefined && result.data.content) {
      // eslint-disable-next-line no-undef
      const licenseBlob = new Blob(
        // Add \ufeff so that excel opens csv in the correct encoding (UTF-8)
        ['\ufeff', result.data.content],
        { type: 'octet/stream' },
      );

      // Запускаем скачивание
      fileSaver.saveAs(licenseBlob, `${fileName}.csv`);
    }
  }

  render() {
    const {
      id,
      cells,
      items,
      tableComponentIm,
      authDataIm,
      tableComponentSortChangeDelta,
      tableComponentChangePageDelta,
      tableComponentChangeItemsPerPageDelta,
      defaultSort,
      className,
      onClickRow,
      actionButtons,
    } = this.props;

    let { filteredItems } = this.state;
    let sort;
    // текущая страница в таблице
    let page;
    // сколько row отображать на странице
    let itemsPerPage;

    const role = authDataIm.getIn(['data', 'role']);
    const shownCells = [];
    const minItemsPerPage = ITEMS_PER_PAGE_OPTIONS[0];

    if (tableComponentIm !== undefined) {
      sort = tableComponentIm.get('sort');
      page = tableComponentIm.get('page');
      itemsPerPage = tableComponentIm.get('itemsPerPage');
    }

    // Сортировка
    if (sort && !sort.isEmpty()) {
      filteredItems = this.sort(filteredItems, sort);
    } else if (defaultSort && !defaultSort.isEmpty()) {
      filteredItems = this.sort(filteredItems, defaultSort);
    }

    page = page || 1;
    itemsPerPage = itemsPerPage || minItemsPerPage;

    // pagination
    const paginationStart = (page - 1) * itemsPerPage;
    const paginationEnd = paginationStart + itemsPerPage;
    let slicedItems;

    if (filteredItems.size > itemsPerPage) {
      slicedItems = filteredItems.slice(paginationStart, paginationEnd);
    } else {
      slicedItems = filteredItems;
    }

    cells.forEach((cell) => {
      // Отфильтровываем колонки на которые не хватает прав
      if (cell.allowedToShowFor && cell.allowedToShowFor.indexOf(role) === -1) {
        return;
      }

      if (cell.isShow !== undefined && !cell.isShow) {
        return;
      }

      shownCells.push(cell);
    });

    return (
      <div className={classnames('table', className)}>
        <TableHeader
          tableId={id}
          cells={shownCells}
          sortChangeAction={tableComponentSortChangeDelta}
          isEmpty={filteredItems.size === 0}
          currentSort={sort}
          actionButtons={actionButtons}
        />

        {filteredItems.size > 0 ?
          <TableContent
            items={slicedItems}
            cells={shownCells}
            onClickRow={onClickRow}
            actionButtons={actionButtons}
          /> :
          <EmptyTableContent isEmpty={items.size === 0} />
        }

        {filteredItems.size > 0 ?
          <div className="table__footer">
            {filteredItems.size > minItemsPerPage ?
              <Pagination
                tableId={id}
                changePage={tableComponentChangePageDelta}
                currentPage={page}
                itemsCount={filteredItems.size}
                itemsPerPage={itemsPerPage}
                changeItemsPerPage={tableComponentChangeItemsPerPageDelta}
              /> :
              null
            }
          </div> :
          null
        }
      </div>
    );
  }
}
