import React, { Fragment, PureComponent } from 'react';
import i18next from 'i18next';
import { Form } from 'containers';
import OrderForm from 'components/form/order';
import BackLink from 'components/controls/back-link';

export default class AddOrder extends PureComponent {
  render() {
    const {
      usersDataIm,
      licenseSubtypesDataIm,
      licensesListComponentIm,
      functionalitiesDataIm,
      ordersDataAddSignal,
      licensesListComponentAddDelta,
      licensesListComponentResetDelta,
      licensesListComponentDeleteDelta,
      modalComponentShowDelta,
      licensesDataIm,
      eraLicensesDataIm,
      currentUserLicenseSubtypesDataIm,
      productVersionsDataIm,
      match,
    } = this.props;

    return (
      <Fragment>
        <div className="content__head">
          <BackLink
            text={i18next.t('back_to_list_of_orders')}
            to="/orders"
          />
          <h1 className="content__title">{i18next.t('creating_order')}</h1>
        </div>
        <div className="content__body">
          <Form id="add-order">
            <OrderForm
              usersDataIm={usersDataIm}
              licenseSubtypesDataIm={licenseSubtypesDataIm}
              licensesListComponentIm={licensesListComponentIm}
              licensesDataIm={licensesDataIm}
              eraLicensesDataIm={eraLicensesDataIm}
              functionalitiesDataIm={functionalitiesDataIm}
              submitSignal={ordersDataAddSignal}
              licensesListComponentAddDelta={licensesListComponentAddDelta}
              licensesListComponentResetDelta={licensesListComponentResetDelta}
              licensesListComponentDeleteDelta={licensesListComponentDeleteDelta}
              modalComponentShowDelta={modalComponentShowDelta}
              currentUserLicenseSubtypesDataIm={currentUserLicenseSubtypesDataIm}
              productVersionsDataIm={productVersionsDataIm}
              defaultProductType={match.params.productType}
            />
          </Form>
        </div>
      </Fragment>
    );
  }
}
