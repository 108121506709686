import TableCell from './cell';

export default TableCell;
export { default as DateCell } from './date';
export { default as SupportEndDateCell } from './support-end-date';
export { default as LicenseTypeCell } from './license-type';
export { default as LicenseDriveTypeCell } from './license-drive-type';
export { default as LicenseStatusCell } from './license-status';
export { default as FunctionalityCell } from './functionality';
export { default as OrderStatusCell } from './order-status';
export { default as PriceCell } from './price';
export { default as LinkCell } from './link';
export { default as ShowLockingCell } from './show-locking-cell';
export { default as BooleanCell } from './boolean';
