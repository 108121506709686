/* global document */

/*
* Данный компонент должен быть отрисован лишь один раз,
* где-нибудь в верхнем уровне DOM приложения
* */

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');

// Защита от анимации закрытия еще неоткрытого окна
let isModalBackgroundWasOpened = false;

export default class ModalBackground extends PureComponent {
  render() {
    const { modalComponentIm } = this.props;
    const queue = modalComponentIm.get('queue');
    // Отображается только при наличии активных модальных окон
    const hasModals = queue.size > 0;
    const className = classnames(
      'modal-background',
      { 'modal-background_visible': hasModals },
      { 'modal-background_hidden': !hasModals && isModalBackgroundWasOpened },
    );

    isModalBackgroundWasOpened = hasModals;

    // Убираем прокрутку у body на время открытия модального окна
    // Это можно сделать через класс (если у body будет таковой)
    if (hasModals) {
      document.body.style.overflowY = 'hidden';
    } else {
      setTimeout(() => {
        // Показываем прокрутку после того как исчезнет модальное окно,
        // чтобы одновременно не отображалось две прокрутки
        document.body.style.overflowY = '';
      }, 300);
    }

    return ReactDOM.createPortal(<div className={className} />, modalRoot);
  }
}
