/* global window */
import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import Input from 'components/controls/input';
import SupportPeriod from 'components/controls/support-period';
import i18next from 'i18next';
import moment from 'moment';
import _isNaN from 'lodash/isNaN';
import _uniq from 'lodash/uniq';
import _unionBy from 'lodash/unionBy';
import {
  supportPeriods,
  orderTypes,
  orderStatus,
  VALID_HARDWARE_KEY_REX,
  VALID_ERA_HARDWARE_KEY_REX,
  FEATURE_DC_CODE,
  DATE_FORMAT,
  PREMIUM_SUPPORT,
  licenseFileTypes,
  FEATURE_ERA_CODE,
  LICENSE_SUBTYPE_DEFAULT_CODE,
  productTypeTranslations,
  productTypes,
  kernelTypes,
  UNLIMITED_DRIVES,
  NOT_UNLIMITED_DRIVES,
} from 'shared/constants';
import { getLicenseSubtype, similarityRaidixHardwareKey } from 'shared/functions';
import {
  licenseCreateTypeTranslations,
  licenseCreateTypes,
  roles,
  DOWNLOAD_LICENSE_URL,
} from 'constants';
import Checkbox from 'components/controls/checkbox';
import Select from 'components/controls/select';
import DownloadIcon from 'img/download.svg';
import DropdownButton from 'components/controls/dropdown-button';
import Functionality from 'components/form/order/functionality';
import SupportInfo from 'components/form/order/support-info';
import { getRelatedFeatures } from 'components/form/order/helpers';

export default class License extends PureComponent {
  constructor(props) {
    super(props);

    const {
      licenseOrderIm,
      licenseSubtypesDataIm,
      originalLicenseData,
      isEdit,
      orderType,
      functionalitiesDataIm,
      type,
      disabled,
    } = props;
    let drives = 0;
    let unlimitedDrives = NOT_UNLIMITED_DRIVES;
    let features = [];
    let supportPeriod = '';
    let supportPeriodDays = '';
    let premiumSupport = 0;
    let licenseSubtypeCode = LICENSE_SUBTYPE_DEFAULT_CODE;
    // TODO: Необходимо переместить в БД, чтобы впоследствии админ сам мог изменять их
    const NON_DEFAULT_TRIAL_FEATURES = ['DC', 'RASP', 'VIRT_MANAGER', 'GRAID'];

    // The default value for trial license features.
    // All active functionality except DC, RASP and VIRT MANAGER
    if (orderType === orderTypes.TRIAL) {
      functionalitiesDataIm.get('data').forEach((feature) => {
        if (
          !NON_DEFAULT_TRIAL_FEATURES.includes(feature.get('code')) &&
          feature.get('active') === 1
        ) {
          features.push(feature.get('id'));
        }
      });
    }

    // Initialize the state with the data of the modified license
    if (originalLicenseData) {
      // When changing the hardware key, the number of disks
      // is inherited from the original license
      if (orderType === orderTypes.HWKEY_CHANGE) {
        drives = originalLicenseData.get('mergeDrives');
      }

      licenseSubtypeCode = originalLicenseData.get('subtypeCode');
      features = originalLicenseData.get('functionalities').toArray();
      premiumSupport = originalLicenseData.get('premiumSupport');
      // supportPeriod, supportPeriodDays - do not initialize
    }

    let licenseSubtype = getLicenseSubtype(licenseSubtypeCode);

    // Initializes the disk value to the minimum value
    if (type !== licenseCreateTypes.CHANGE) {
      drives = licenseSubtype.getMinDriveCount();
    }

    // Инициализируем стейт данными сохранённой лицензии
    if (isEdit && licenseOrderIm) {
      const subtypeId = licenseOrderIm.get('subtypeId');

      drives = licenseOrderIm.get('drives');
      unlimitedDrives = licenseOrderIm.get('unlimitedDrives');
      features = licenseOrderIm.get('functionalities').toArray();
      // In the database, the data is stored as days
      supportPeriod = supportPeriods.SPECIFIED_NUMBER_OF_DAYS;
      supportPeriodDays = licenseOrderIm.get('supportPeriodDays');
      premiumSupport = licenseOrderIm.get('premiumSupport');
      licenseSubtypeCode = licenseSubtypesDataIm.get('data')
        .find(item => item.get('id') === subtypeId)
        .get('code');
      licenseSubtype = getLicenseSubtype(licenseSubtypeCode);

      if (supportPeriodDays === 0) {
        supportPeriod = supportPeriods.WITHOUT_SUPPORT;
      }
    }

    let disabledDrives = !!unlimitedDrives;
    let disableUnlimitedDrives = false;

    if (disabled) {
      disabledDrives = true;
      disableUnlimitedDrives = true;
    }

    this.state = {
      features,
      drives,
      unlimitedDrives,
      supportPeriod,
      supportPeriodDays,
      premiumSupport,
      disabledDrives,
      disableUnlimitedDrives,
      licenseSubtypeCode,
      licenseSubtype,
    };

    this.onRemoveLicense = this.onRemoveLicense.bind(this);
    this.onChangeDrives = this.onChangeDrives.bind(this);
    this.onChangeDrivesToUnlimited = this.onChangeDrivesToUnlimited.bind(this);
    this.onChangeFeatures = this.onChangeFeatures.bind(this);
    this.onGenerateLicense = this.onGenerateLicense.bind(this);
    this.onDownloadRpkLicense = this.onDownloadRpkLicense.bind(this);
    this.onDownloadNewRpkLicense = this.onDownloadNewRpkLicense.bind(this);
    this.onDownloadLicLicense = this.onDownloadLicLicense.bind(this);
    this.onShowLicenseText = this.onShowLicenseText.bind(this);
    this.onChangeSupportPeriod = this.onChangeSupportPeriod.bind(this);
    this.onChangeDrivesToBasic = this.onChangeDrivesToBasic.bind(this);
    this.isChangeLicenseUnlimitedDrives = this.isChangeLicenseUnlimitedDrives.bind(this);
    this.onLicenseSubtypesChange = this.onLicenseSubtypesChange.bind(this);
  }

  componentWillMount() {
    // Так как значение состояние disabled хранится в state
    // то выставлять его начальное значение нужно в componentWillMount
    this.isChangeLicenseUnlimitedDrives();
    this.isTrialLicenseDisableDrives();
  }

  componentDidUpdate(prevProps, prevState) {
    // Сбрасываем ошибку, при смене статуса, так как для
    // пробной лицензии это поле заполняется автоматически
    if (prevProps.orderType !== orderTypes.TRIAL &&
      this.props.orderType === orderTypes.TRIAL
    ) {
      this.drivesRef.error = '';
    }

    if (prevState.unlimitedDrives !== this.state.unlimitedDrives) {
      // Forbid editing the number of discs if an unlimited number is selected
      const disabledDrives = this.state.unlimitedDrives || this.props.orderType === orderTypes.TRIAL;

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ disabledDrives });
    }
  }

  onRemoveLicense(e) {
    e.preventDefault();

    this.props.onRemoveLicense(this.props.id);
  }

  onGenerateLicense() {
    const { licenseOrderIm, onGenerateLicense, originalLicenseData, orderId } = this.props;

    let isValid = this.validate();
    let hardwareKey2;

    if (this.hardwareKey1Ref && this.hardwareKey1Ref.value.length < 1) {
      this.hardwareKey1Ref.error = i18next.t('v.required');
      isValid = false;
    }

    if (this.hardwareKey2Ref) {
      hardwareKey2 = this.hardwareKey2Ref.value;

      if (hardwareKey2.length < 1) {
        this.hardwareKey2Ref.error = i18next.t('v.required');
        isValid = false;
      }
    }

    if (originalLicenseData && this.isEraAddedForChanged()) {
      const hardwareKeyOrig = originalLicenseData.get('hardwareKey');
      const [hardwareKey1Orig, hardwareKey2Orig] = hardwareKeyOrig.split(' ');

      if (!similarityRaidixHardwareKey(hardwareKey1Orig, this.hardwareKey1Ref.value)) {
        this.hardwareKey1Ref.error = i18next.t('v.invalid_raidix_hw_format');
        isValid = false;
      }

      if (hardwareKey2Orig) {
        if (!similarityRaidixHardwareKey(hardwareKey2Orig, this.hardwareKey2Ref.value)) {
          this.hardwareKey2Ref.error = i18next.t('v.invalid_raidix_hw_format');
          isValid = false;
        }
      }
    }

    if (isValid) {
      onGenerateLicense(
        licenseOrderIm.get('id'),
        {
          productType: productTypes.RAIDIX,
          hardwareKey1: this.hardwareKey1Ref.value,
          hardwareKey2,
          orderId,
        },
      );
    }
  }

  onDownloadLicLicense() {
    this.downloadLicense(licenseFileTypes.LIC);
  }

  onDownloadRpkLicense() {
    this.downloadLicense(licenseFileTypes.RPK, kernelTypes.OLD);
  }

  onDownloadNewRpkLicense() {
    this.downloadLicense(licenseFileTypes.RPK, kernelTypes.NEW);
  }

  onShowLicenseText() {
    this.props.modalComponentShowDelta('licenseText', {
      licenses: [
        {
          tabName: productTypeTranslations.RAIDIX,
          content: this.props.licenseOrderIm.get('raidixLicenseText'),
        },
        {
          tabName: productTypeTranslations.ERA,
          content: this.props.licenseOrderIm.get('eraKernelOldLicenseText'),
        },
        {
          tabName: productTypeTranslations.ERA,
          content: this.props.licenseOrderIm.get('eraKernelNewLicenseText'),
        }
      ],
    });
  }

  onChangeDrives(event) {
    let drives = event.value !== undefined ? event.value : event.target.value;

    drives = Number(drives);

    if (!_isNaN(drives)) {
      this.setState({ drives });
    }
  }

  onChangeDrivesToUnlimited(event) {
    this.setState({
      unlimitedDrives: event.target.checked ? UNLIMITED_DRIVES : NOT_UNLIMITED_DRIVES,
    });
  }

  onChangeDrivesToBasic(drives) {
    this.setState({ drives });
  }

  onChangeFeatures(features) {
    this.setState({ features });
  }

  onChangeSupportPeriod({ period, days }) {
    this.setState({
      supportPeriod: period,
      supportPeriodDays: days,
    });
  }

  onLicenseSubtypesChange({ value }) {
    const licenseSubtype = getLicenseSubtype(value);
    const state = {
      licenseSubtype,
      licenseSubtypeCode: value,
    };

    if (this.props.type !== licenseCreateTypes.CHANGE) {
      state.drives = licenseSubtype.getMinDriveCount();
    }

    this.setState(state);
  }

  get value() {
    const {
      isEdit,
      licenseOrderIm,
      originalLicenseData,
      type,
      orderType,
    } = this.props;

    const { licenseSubtype, unlimitedDrives } = this.state;
    const features = licenseSubtype.isEditableFeatures()
      ? this.functionalityRef.value
      : this.getFeatureIdsForLicenseSubtype(licenseSubtype);

    let values = {
      customer: this.customerRef.value,
      drives: this.drivesRef.value,
      unlimitedDrives,
      functionality: features,
      description: this.descriptionRef.value,
      subtypeCode: licenseSubtype.getSubtypeCode(),
    };

    if (this.hardwareKey1Ref) {
      values.hardwareKey1 = this.hardwareKey1Ref.value;
    }

    if (this.hardwareKey2Ref) {
      values.hardwareKey2 = this.hardwareKey2Ref.value;
    }

    if (orderType === orderTypes.COMMERCIAL && licenseSubtype.isEditableSupportPeriod()) {
      const isPremiumSupport = licenseSubtype.isPremiumSupport() && this.premiumSupportRef.checked;

      values = {
        ...values,
        supportPeriod: this.supportPeriodRef.value,
        supportDays: Number(this.supportPeriodRef.days),
        premiumSupport: isPremiumSupport ? this.premiumSupportRef.props.value : 0,
      };
    }

    if (orderType === orderTypes.TRIAL) {
      values = {
        ...values,
        supportPeriod: supportPeriods.SPECIFIED_NUMBER_OF_DAYS,
        supportDays: this.trialSupportDaysRef.value,
      };
    }

    if (type === licenseCreateTypes.CHANGE && originalLicenseData) {
      values = {
        ...values,
        originalLicenseId: originalLicenseData.get('id'),
      };
    }

    if (isEdit && licenseOrderIm) {
      values = {
        ...values,
        id: licenseOrderIm.get('id'),
      };
    }

    return values;
  }

  getFeatureIdsForLicenseSubtype(licenseSubtype) {
    return this.props.functionalitiesDataIm.get('data')
      .filter(item => licenseSubtype.includesFeature(item.get('code')))
      .toArray()
      .map(item => item.get('id'));
  }

  getInitValue() {
    const { licenseOrderIm, originalLicenseData, isEdit, type } = this.props;
    const {
      drives,
      unlimitedDrives,
      features,
      supportPeriod,
      supportPeriodDays,
      premiumSupport,
      licenseSubtype,
      licenseSubtypeCode,
    } = this.state;
    const value = {
      drives,
      unlimitedDrives,
      features,
      originalLicense: '',
      supportPeriod,
      supportPeriodDays,
      premiumSupport,
      mergeDrives: 0,
      licenseSubtype,
      licenseSubtypeCode,
    };
    let hardwareKey;
    let isDcFeatureOriginalLicense = false;

    const dcFeature = this.props.functionalitiesDataIm.get('data').find(
      feature => feature.get('code') === FEATURE_DC_CODE,
    );

    if (originalLicenseData) {
      value.customer = originalLicenseData.get('customer');
      value.description = originalLicenseData.get('description');
      value.originalLicense = originalLicenseData.get('id');
      value.mergeDrives = originalLicenseData.get('mergeDrives');

      hardwareKey = originalLicenseData.get('hardwareKey');

      originalLicenseData.get('functionalities').forEach((feature) => {
        if (feature === dcFeature.get('id')) {
          isDcFeatureOriginalLicense = true;
        }
      });
    }

    if (isEdit && licenseOrderIm) {
      value.customer = licenseOrderIm.get('customer');
      value.description = licenseOrderIm.get('description');
      value.originalLicense = licenseOrderIm.get('originalLicenseId');
      value.generatedBy = licenseOrderIm.get('generatedBy');
      value.generatedByUserName = licenseOrderIm.get('generatedByUserName');
      value.generatedDate = licenseOrderIm.get('generatedDate');
      value.mergeDrives = licenseOrderIm.get('mergeDrives');

      const isDc = dcFeature && dcFeature.size > 0 ?
        features.includes(dcFeature.get('id')) :
        false;

      if (
        // Условие для добавления функции DC которой еще не было у лицензии
        // и форме, когда одно поле не активно, а второе активно
        // (нового ввода еще нет, но есть старый)
        (isDc && !isDcFeatureOriginalLicense && licenseOrderIm.get('hardwareKey')) ||
        // Условие для ситуаци, когда заказ на изменение и новые данные присутствуют
        (type === licenseCreateTypes.CHANGE && licenseOrderIm.get('hardwareKey')) ||
        // Условие для любых заказов на новые лицензии
        type === licenseCreateTypes.NEW) {
        hardwareKey = licenseOrderIm.get('hardwareKey');
      }
    }

    if (hardwareKey) {
      [value.hardwareKey1, value.hardwareKey2] = hardwareKey.split(' ');
    }

    return value;
  }

  getPartnerLicenseSubtypeOptions() {
    const {
      isEdit,
      licenseOrderIm,
      licenseSubtypesDataIm,
      currentUserLicenseSubtypesDataIm,
    } = this.props;
    const availableLicenseSubtypes = currentUserLicenseSubtypesDataIm.get('data')
      .filter(item => item.get('daily_limit') > 0)
      .map(item => ({
        value: item.get('code'),
        label: item.get('name'),
      })).toArray();

    if (isEdit && licenseOrderIm) {
      // User should see the current subtype of the license
      // even if he does not have the right to create it
      const subtypeCode = licenseOrderIm.get('subtypeCode');
      const subtypeOfEditableLicense = licenseSubtypesDataIm.get('data')
        .find(item => item.get('code') === subtypeCode);

      if (subtypeOfEditableLicense) {
        availableLicenseSubtypes.push({
          value: subtypeOfEditableLicense.get('code'),
          label: subtypeOfEditableLicense.get('name'),
        });
      }
    }

    return _unionBy(availableLicenseSubtypes, 'value');
  }

  getStaffLicenseSubtypeOptions() {
    return this.props.licenseSubtypesDataIm.get('data').map(item => ({
      value: item.get('code'),
      label: item.get('name'),
    })).toArray();
  }

  getLicenseSubtypeOptions() {
    return this.props.currentUserRole === roles.PARTNER
      ? this.getPartnerLicenseSubtypeOptions()
      : this.getStaffLicenseSubtypeOptions();
  }

  /**
   * Download license file
   * @param {licenseFileTypes} type file format
   * @param {kernelTypes} [kernelType]
   */
  downloadLicense(type, kernelType) {
    const { licenseOrderIm } = this.props;
    const path = `${DOWNLOAD_LICENSE_URL}/${licenseOrderIm.get('id')}`;
    const query = [
      `type=${type}`,
      `productType=${productTypes.RAIDIX}`,
    ];

    if (kernelType) {
      query.push(`kernelType=${kernelType}`);
    }

    window.location.href = `${path}?${query.join('&')}`;
  }

  validate() {
    const { orderType } = this.props;
    let isValid = true;

    if (orderType === orderTypes.COMMERCIAL) {
      const { licenseSubtype } = this.state;
      let supportPeriod;
      let supportDays;

      if (licenseSubtype.isEditableSupportPeriod()) {
        supportPeriod = this.supportPeriodRef.value;
        supportDays = Number(this.supportPeriodRef.days);
      } else {
        const { value, days } = licenseSubtype.getDefaultSupportPeriod();

        supportPeriod = value;
        supportDays = days;
      }

      // support period
      if (supportPeriod <= 0) {
        this.supportPeriodRef.error = i18next.t('v.required');
        isValid = false;
      } else if (supportPeriod === supportPeriods.SPECIFIED_NUMBER_OF_DAYS &&
        (isNaN(supportDays) || supportDays <= 0)
      ) {
        if (supportDays <= 0) {
          this.supportPeriodRef.error = i18next.t('v.required');
          isValid = false;
        } else if (isNaN(supportDays)) {
          this.supportPeriodRef.error = i18next.t('v.invalid_format');
          isValid = false;
        }
      }
    }

    if (orderType === orderTypes.COMMERCIAL || orderType === orderTypes.TRIAL) {
      // drives
      if (this.drivesRef.value < 0) {
        this.drivesRef.error = i18next.t('v.required');
        isValid = false;
      }
    }

    // hardware key
    if (orderType === orderTypes.HWKEY_CHANGE) {
      if (this.hardwareKey1Ref && this.hardwareKey1Ref.value.length < 1) {
        this.hardwareKey1Ref.error = i18next.t('v.required');
        isValid = false;
      }

      if (this.hardwareKey2Ref && this.hardwareKey2Ref.value.length < 1) {
        this.hardwareKey2Ref.error = i18next.t('v.required');
        isValid = false;
      }
    }

    const isEraSelected = this.isEraSelected();

    if (
      this.hardwareKey1Ref &&
      this.isHardwareKeyValidation(this.hardwareKey1Ref.value, isEraSelected)
    ) {
      this.hardwareKey1Ref.error = isEraSelected ?
        i18next.t('v.invalid_era_hw_format') :
        i18next.t('v.invalid_hw_format');

      isValid = false;
    }

    if (
      this.hardwareKey2Ref &&
      this.isHardwareKeyValidation(this.hardwareKey2Ref.value, isEraSelected)
    ) {
      this.hardwareKey2Ref.error = isEraSelected ?
        i18next.t('v.invalid_era_hw_format') :
        i18next.t('v.invalid_hw_format');

      isValid = false;
    }

    return isValid;
  }

  isEraSelected() {
    const eraFeature = this.props.functionalitiesDataIm.get('data').find(
      feature => feature.get('code') === FEATURE_ERA_CODE,
    );
    const eraFeatureId = eraFeature.get('id');

    return (
      this.functionalityRef !== undefined &&
      this.functionalityRef !== null &&
      this.functionalityRef.value !== undefined &&
      this.functionalityRef.value.includes(eraFeatureId)
    );
  }

  isHardwareKeyValidation(hardwareKey, isEraSelected) {
    return hardwareKey && hardwareKey.length > 0 && (
      (!isEraSelected && !VALID_HARDWARE_KEY_REX.test(hardwareKey)) ||
      (isEraSelected && !VALID_ERA_HARDWARE_KEY_REX.test(hardwareKey))
    );
  }

  isChangeLicenseUnlimitedDrives() {
    // if the original license has unlimited disks
    if (this.props.type === licenseCreateTypes.CHANGE && this.state.unlimitedDrives) {
      this.setState({ disableUnlimitedDrives: true });
    }
  }

  isTrialLicenseDisableDrives() {
    if (this.props.orderType === orderTypes.TRIAL) {
      this.setState({
        disabledDrives: true,
        disableUnlimitedDrives: true,
      });
    }
  }

  isEraFeatureOriginalLicense() {
    const { originalLicenseData } = this.props;

    if (originalLicenseData) {
      const eraFeature = this.props.functionalitiesDataIm.get('data').find(
        feature => feature.get('code') === FEATURE_ERA_CODE,
      );
      const eraFeatureId = eraFeature.get('id');

      return originalLicenseData.get('functionalities').includes(eraFeatureId);
    }

    return false;
  }

  isEraAddedForChanged() {
    const { type, originalLicenseData } = this.props;

    if (type === licenseCreateTypes.CHANGE && originalLicenseData) {
      return !this.isEraFeatureOriginalLicense() && this.isEraSelected();
    }

    return false;
  }

  render() {
    const {
      id,
      isEdit,
      type,
      orderType,
      orderCurrentStatus,
      disabled = false,
      hideRemoveButton = false,
      functionalitiesDataIm,
      formComponentIm,
      originalLicenseData,
      licenseOrderIm,
      currentUserRole,
    } = this.props;

    const {
      supportPeriod,
      supportPeriodDays,
      customer,
      drives,
      unlimitedDrives,
      mergeDrives,
      features,
      description,
      originalLicense,
      hardwareKey1,
      hardwareKey2,
      generatedBy,
      generatedDate,
      generatedByUserName,
      premiumSupport,
      licenseSubtype,
      licenseSubtypeCode,
    } = this.getInitValue();

    let drivesValue = drives;
    let currentDrivesValue = 0;
    let featureValues = features;
    let disabledFeatures = [];
    let supportExpirationDate;
    let currentFeatures = [];
    // LIC-234 - добавить версию архива вне зависимости от наличия опции ERA
    // let eraKernelNewLicenseText = '';
    // Did DC have a modified license
    let isDcFeatureOriginalLicense = false;
    const featureIds = [];

    if (isEdit && licenseOrderIm) {
      currentFeatures = licenseOrderIm.get('functionalities').toArray();
      currentDrivesValue = licenseOrderIm.get('drives');
      // eraKernelNewLicenseText = licenseOrderIm.get('eraKernelNewLicenseText');
    }

    // Get the functionality responsible for DC
    const dcFeature = functionalitiesDataIm.get('data').find(
      feature => feature.get('code') === FEATURE_DC_CODE,
    );

    if (originalLicenseData) {
      originalLicenseData.get('functionalities').forEach((feature) => {
        if (feature === dcFeature.get('id')) {
          isDcFeatureOriginalLicense = true;
        }
      });

      supportExpirationDate = originalLicenseData.get('supportExpirationDate');
    }

    functionalitiesDataIm.get('data').forEach((item) => {
      const itemId = item.get('id');

      // Collect id all functionality
      featureIds.push(itemId);

      // If the functionality is not available for order
      if (item.get('active') !== 1) {
        disabledFeatures.push(itemId);

        if (currentFeatures.includes(itemId) && !featureValues.includes(itemId)) {
          featureValues.push(itemId);
        }
      }
    });

    const relatedFeatures = getRelatedFeatures(functionalitiesDataIm, featureValues);
    featureValues = _uniq(featureValues.concat(relatedFeatures));

    const isDc = dcFeature && dcFeature.size > 0 ?
      featureValues.includes(dcFeature.get('id')) :
      false;

    const drivesList = [];
    const maxDriveCount = licenseSubtype.getMaxDriveCount();

    // The administrator can create a license with 0 disks.
    // Other users can specify 0 only when changing an existing license
    if (
      currentUserRole === roles.ADMIN ||
      type === licenseCreateTypes.CHANGE ||
      drivesValue === 0
    ) {
      drivesList.push({ label: 0, value: 0 });
    }

    if (licenseSubtype.isListDrives()) {
      licenseSubtype.getListDrives().forEach((value) => {
        if (
          // Check available disk count
          value > ((maxDriveCount - mergeDrives) + currentDrivesValue) &&
          currentUserRole !== roles.ADMIN &&
          orderType !== orderTypes.HWKEY_CHANGE
        ) {
          return;
        }

        drivesList.push({ label: value, value });
      });
    }

    if (orderType === orderTypes.TRIAL) {
      drivesValue = licenseSubtype.isListDrives() ? maxDriveCount : 'UNLIMITED';
    }

    if (unlimitedDrives) {
      drivesValue = 'UNLIMITED';
    }

    if (disabled) {
      disabledFeatures = featureIds;
    }

    const generatedDateFormat = moment(generatedDate, 'X').format(DATE_FORMAT);

    let isDisabledGenerateButton = false;
    let isDisabledHardwareKey = true;
    let isDisabledHardwareKey2 = true;
    let isDisabledTrialSupportDays = false;
    // TODO: move 30 to constants
    let trialSupportDays = 30;

    if (currentUserRole === roles.PARTNER || disabled) {
      isDisabledTrialSupportDays = true;
    }

    if (isEdit) {
      trialSupportDays = supportPeriodDays;
    }

    // If the license is not yet generated
    if (generatedBy === undefined) {
      if (orderType === orderTypes.HWKEY_CHANGE) {
        isDisabledHardwareKey = false;
        isDisabledHardwareKey2 = false;
      }

      if (type !== licenseCreateTypes.CHANGE) {
        isDisabledHardwareKey = false;
        isDisabledHardwareKey2 = false;
      }

      // Если у изменяемой лицензии изначально не было DC, но оно было
      // добавлена при изменении, то разрешаем ввести ключ второй ноды
      if (type === licenseCreateTypes.CHANGE && isDc && !isDcFeatureOriginalLicense) {
        isDisabledHardwareKey2 = false;
      }

      // Unblock hardware key(s) field(s) if ERA specified
      if (type === licenseCreateTypes.CHANGE && !this.isEraFeatureOriginalLicense()) {
        const eraFeature = this.props.functionalitiesDataIm.get('data').find(
          feature => feature.get('code') === FEATURE_ERA_CODE,
        );
        const isEraAdded = featureValues.includes(eraFeature.get('id'));

        if (isEraAdded) {
          isDisabledHardwareKey = false;

          if (isDc) {
            isDisabledHardwareKey2 = false;
          }
        }
      }
    }

    if (formComponentIm && formComponentIm.get('isDisabled') === true) {
      isDisabledHardwareKey = true;
      isDisabledHardwareKey2 = true;
      isDisabledGenerateButton = true;
    }

    const generateLicenseButton = isEdit && orderCurrentStatus === orderStatus.GENERATION_READY ? (
      <div className="controls-group controls-group_form">
        <div className="controls-group__control">
          <input
            type="button"
            className="button button_submit"
            value={i18next.t('generate_license_file')}
            onClick={this.onGenerateLicense}
            disabled={isDisabledGenerateButton}
          />
        </div>
      </div>
    ) : null;

    const supportPeriodField = (licenseSubtype.isEditableSupportPeriod() &&
      (orderType === orderTypes.COMMERCIAL || orderType === orderTypes.HWKEY_CHANGE)) ? (
        <div className="form__row form__row_2">
          <div className="form__field">
            <label
              htmlFor={`license-form-${id}-support-period`}
              className="form__label form__label_required"
            >
              {i18next.t('support_period')}
            </label>
            <SupportPeriod
              id={`license-form-${id}-support-period`}
              ref={(ref) => { this.supportPeriodRef = ref; }}
              defaultValue={supportPeriod}
              defaultDays={supportPeriodDays}
              disabled={disabled}
              onChange={this.onChangeSupportPeriod}
            />
          </div>
          <div className="form__field">
            <SupportInfo
              orderType={orderType}
              supportPeriod={supportPeriod}
              supportPeriodDays={supportPeriodDays}
              supportExpirationDateUnix={supportExpirationDate}
              generatedDateUnix={generatedDate}
              showOnlyNewSupport={generatedBy || type !== licenseCreateTypes.CHANGE}
            />
          </div>
        </div>
      ) : null;

    const validityInDaysField = (orderType !== orderTypes.COMMERCIAL &&
      orderType !== orderTypes.HWKEY_CHANGE) ? (
        <div className="form__row form__row_2">
          <div className="form__field">
            <label
              htmlFor={`${id}-days`}
              className={classnames('form__label', {
                form__label_required: !isDisabledTrialSupportDays,
              })}
            >
              {i18next.t('validity_in_days')}
            </label>
            <Input
              type="number"
              id={`${id}-days`}
              defaultValue={trialSupportDays}
              ref={(ref) => { this.trialSupportDaysRef = ref; }}
              onChange={this.onChangeDays}
              className="form__field-text form__field-text_padding"
              placeholder="30"
              disabled={isDisabledTrialSupportDays}
              maxLength="3"
            />
          </div>
        </div>
      ) : null;

    const popUpDownloadMenu = (
      <div className="popup-menu">
        <a
          href="#download-license"
          className="popup-menu__item"
          onClick={this.onDownloadNewRpkLicense}
        >
          <span className="popup-menu__item-text">
            {i18next.t('download_rpk_new_license_file')}
          </span>
          <span className="popup-menu__item-text-info">
            {i18next.t('download_rpk_new_license_file_note')}
          </span>
        </a>
        <a
          href="#download-license"
          className="popup-menu__item"
          onClick={this.onDownloadLicLicense}
        >
          <span className="popup-menu__item-text">
            {i18next.t('download_old_license_file')}
          </span>
          <span className="popup-menu__item-text-info">
            {i18next.t('download_old_license_file_note')}
          </span>
        </a>
      </div>
    );

    return (
      <div className="license-form__license">
        <div className="form__row form__row_2">
          <div className="license-form__header">
            <div className="license-form__title">
              {i18next.t(licenseCreateTypeTranslations[type])}
              {
                isEdit && licenseOrderIm ? (
                  <span className="license-form__subtitle"> #{licenseOrderIm.get('id')}</span>
                ) : null
              }
            </div>
            {
              hideRemoveButton !== true ? (
                <a
                  href="#remove-license"
                  className="link link_dashed"
                  onClick={this.onRemoveLicense}
                >
                  {i18next.t('delete')}
                </a>
              ) : null
            }
          </div>
        </div>

        {
          type === licenseCreateTypes.CHANGE ? (
            <div className="form__row form__row_2">
              <div className="form__field">
                <label htmlFor={`license-form-${id}-original-license`} className="form__label">
                  {i18next.t('original_license')}
                </label>
                <Input
                  type="text"
                  id={`license-form-${id}-original-license`}
                  className="form__field-text form__field-text_padding"
                  placeholder=""
                  defaultValue={originalLicense}
                  disabled
                />
              </div>
            </div>
          ) : (
            <div className="form__row form__row_2">
              <div className="form__field">
                <label htmlFor={`license-form-${id}-license-subtypes`} className="form__label">
                  {i18next.t('subtype_of_license')}
                </label>
                <Select
                  onChange={this.onLicenseSubtypesChange}
                  id={`license-form-${id}-license-subtypes`}
                  className="select_form"
                  clearable={false}
                  options={this.getLicenseSubtypeOptions()}
                  defaultValue={licenseSubtypeCode}
                  emptyValue={0}
                  disabled={disabled}
                />
              </div>
            </div>
          )
        }

        {supportPeriodField}
        {validityInDaysField}

        {
          (
            licenseSubtype.isPremiumSupport() &&
            (orderType === orderTypes.COMMERCIAL || orderType === orderTypes.HWKEY_CHANGE)
          ) ? (
            <div className="form__row">
              <div className="form__field form__field-checkbox">
                <Checkbox
                  id={`license-form-${id}-premium-support`}
                  ref={(ref) => {
                    this.premiumSupportRef = ref;
                  }}
                  name="premiumSupport"
                  value={PREMIUM_SUPPORT}
                  label={i18next.t('premium_support')}
                  disabled={disabled}
                  defaultChecked={premiumSupport}
                />
              </div>
            </div>
          ) : null
        }
        <div className="form__row form__row_2">
          <div className="form__field">
            <label htmlFor={`license-form-${id}-customer`} className="form__label">
              {i18next.t('customer')}
            </label>
            <Input
              type="text"
              id={`license-form-${id}-customer`}
              ref={(ref) => { this.customerRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="IBM"
              defaultValue={customer}
              disabled={disabled}
            />
          </div>
          {
            licenseSubtype.isEditableDrives() ? (
              <div className="form__field">
                <label
                  htmlFor={`license-form-${id}-drives`}
                  className="form__label form__label_required"
                >
                  {i18next.t('drives')}
                </label>
                {
                  licenseSubtype.isListDrives() ? (
                    <Select
                      id={`license-form-${id}-drives`}
                      defaultValue={drivesValue}
                      className="select_form"
                      options={drivesList}
                      ref={(ref) => { this.drivesRef = ref; }}
                      clearable={false}
                      emptyValue={0}
                      disabled={this.state.disabledDrives}
                      onChange={this.onChangeDrives}
                      isSearchable={false}
                    />
                  ) : (
                    <div className="drives">
                      <Input
                        id={`license-form-${id}-drives`}
                        value={drivesValue}
                        type={this.state.disabledDrives ? 'text' : 'number'}
                        ref={(ref) => { this.drivesRef = ref; }}
                        onChange={this.onChangeDrives}
                        className="form__field-text form__field-text_padding drives__input"
                        placeholder="Type here"
                        disabled={this.state.disabledDrives}
                        maxLength="9"
                      />
                      <div className="drives__checkbox">
                        <Checkbox
                          onChange={this.onChangeDrivesToUnlimited}
                          id={`license-form-${id}-unlimited-drives`}
                          name="unlimitedDrives"
                          value={this.state.disabledDrives}
                          label={i18next.t('unlimited')}
                          disabled={this.state.disableUnlimitedDrives}
                          checked={unlimitedDrives}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            ) : null
          }
        </div>
        <div>
          {
            licenseSubtype.isEditableFeatures() ? (
              <Fragment>
                <div className="form__row form__row_2">
                  <div className="form-line" />
                </div>

                <div className="form__row form__row_2">
                  <Functionality
                    licenseKey={id}
                    value={featureValues}
                    defaultValue={features}
                    disabled={disabledFeatures}
                    relatedFeatures={relatedFeatures}
                    onChange={this.onChangeFeatures}
                    functionalitiesDataIm={functionalitiesDataIm}
                    orderType={orderType}
                    drives={drivesValue}
                    setDrives={this.onChangeDrivesToBasic}
                    ref={(ref) => { this.functionalityRef = ref; }}
                  />
                </div>
              </Fragment>
            ) : null
          }

          <div className="form__row form__row_2">
            <div className="form__field form__field_wide">
              <label htmlFor={`license-form-${id}-notes`} className="form__label">
                {i18next.t('notes')}
                {
                  currentUserRole !== roles.PARTNER ? (
                    <span className="form__label-description">
                      {` (${i18next.t('visible_for_partners')})`}
                    </span>
                  ) : null
                }
              </label>
              <textarea
                className="form__field-text form__field-text_padding"
                id={`license-form-${id}-notes`}
                defaultValue={description}
                placeholder="Description"
                ref={(ref) => { this.descriptionRef = ref; }}
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        {
          (isEdit && orderCurrentStatus === orderStatus.GENERATION_READY) ||
          orderType === orderTypes.HWKEY_CHANGE ? (
            <div className="form__row form__row_hardware-key">
              <div className="form__field form__field_wide">
                <label htmlFor={`license-form-${id}-hardware-key-1`} className="form__label">
                  {i18next.t('hardware_key')}
                </label>
                <Input
                  type="text"
                  id={`license-form-${id}-hardware-key-1`}
                  ref={(ref) => { this.hardwareKey1Ref = ref; }}
                  className="form__field-text form__field-text_padding"
                  placeholder=""
                  defaultValue={hardwareKey1}
                  disabled={isDisabledHardwareKey}
                />
              </div>
              {
                isDc ? (
                  <div className="form__field form__field_wide">
                    <label htmlFor={`license-form-${id}-hardware-key-2`} className="form__label">
                      {i18next.t('hardware_key')}
                    </label>
                    <Input
                      type="text"
                      id={`license-form-${id}-hardware-key-2`}
                      ref={(ref) => { this.hardwareKey2Ref = ref; }}
                      className="form__field-text form__field-text_padding"
                      placeholder=""
                      defaultValue={hardwareKey2}
                      disabled={isDisabledHardwareKey2}
                    />
                  </div>
                ) : null
              }

              {
                generatedBy ? (
                  <div className="generated-info">
                    <div>
                      <div>
                        <span>{i18next.t('generated_by')}:</span>
                        <span className="generated-info__value">{generatedByUserName}</span>
                      </div>
                      <div>
                        <span>{i18next.t('generated_date')}:</span>
                        <span className="generated-info__value">{generatedDateFormat}</span>
                      </div>
                    </div>
                    <div className="controls-group controls-group_form controls-group_column">
                      <div className="controls-group__control">
                        <DropdownButton
                          onClick={this.onDownloadRpkLicense}
                          popUpMenu={popUpDownloadMenu}
                          icon={DownloadIcon}
                        >
                          {i18next.t('download_rpk_old_license_file')}
                        </DropdownButton>
                      </div>
                      <div className="controls-group__control">
                        <input
                          type="button"
                          className="button"
                          value={i18next.t('show_license_file_contents')}
                          onClick={this.onShowLicenseText}
                        />
                      </div>
                    </div>
                  </div>
                ) : generateLicenseButton
              }
            </div>
          ) : null
        }
      </div>
    );
  }
}
