import { connect } from 'react-redux';
import EditOrder from 'components/pages/edit-order';
import dataFetcherEnhance from 'components/data-fetcher-enhance';
import { actions as ordersDataActions } from 'ducks/data/orders';
import { actions as licensesDataActions } from 'ducks/data/licenses';
import { actions as eraLicensesDataActions } from 'ducks/data/era-licenses';
import { actions as draidLicensesDataActions } from 'ducks/data/draid-licenses';
import { actions as usersDataActions } from 'ducks/data/users';
import { actions as functionalitiesDataActions } from 'ducks/data/functionalities';
import { actions as licensesListDataActions } from 'ducks/components/licenses-list';
import { actions as licenseSubtypesDataActions } from 'ducks/data/license-subtype';
import { actions as modalDataActions } from 'ducks/components/modal';
import { actions as generatorDataActions } from 'ducks/data/generator';
import {
  actions as currentUserLicenseSubtypeDataActions,
} from 'ducks/data/current-user-license-subtype';
import { actions as productVersionsDataActions } from 'ducks/data/product-versions';

const mapDispatchToProps = Object.assign(
  {},
  ordersDataActions,
  licensesDataActions,
  eraLicensesDataActions,
  draidLicensesDataActions,
  usersDataActions,
  functionalitiesDataActions,
  licensesListDataActions,
  licenseSubtypesDataActions,
  generatorDataActions,
  modalDataActions,
  currentUserLicenseSubtypeDataActions,
  productVersionsDataActions,
);

function mapStateToProps(state) {
  return {
    authDataIm: state.data.authDataIm,
    usersDataIm: state.data.usersDataIm,
    ordersDataIm: state.data.ordersDataIm,
    licensesDataIm: state.data.licensesDataIm,
    eraLicensesDataIm: state.data.eraLicensesDataIm,
    draidLicensesDataIm: state.data.draidLicensesDataIm,
    licenseSubtypesDataIm: state.data.licenseSubtypesDataIm,
    functionalitiesDataIm: state.data.functionalitiesDataIm,
    productVersionsDataIm: state.data.productVersionsDataIm,
    currentUserLicenseSubtypesDataIm: state.data.currentUserLicenseSubtypesDataIm,
    licensesListComponentIm: state.components.licensesListComponentIm,
    fetchActionNames: [
      'ordersDataGetSignal',
      'licensesDataGetSignal',
      'eraLicensesDataGetSignal',
      'draidLicensesDataGetSignal',
      'usersDataGetSignal',
      'functionalitiesDataGetSignal',
      'licenseSubtypesDataGetSignal',
      'currentUserLicenseSubtypesDataGetSignal',
      'productVersionsDataGetSignal',
    ],
  };
}

const EditOrderWithFetch = dataFetcherEnhance(EditOrder);

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderWithFetch);
