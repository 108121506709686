import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import { List, Map } from 'immutable';
import { Table } from 'containers';
import { licenseTypeTranslations } from 'constants';
import {
  LicenseTypeCell,
  LicenseStatusCell,
  FunctionalityCell,
  LicenseDriveTypeCell,
  DateCell,
  SupportEndDateCell,
  LinkCell,
  BooleanCell,
} from 'components/table/cell';
import { roles, licenseTypes, productTypes } from 'shared/constants';
import BackLink from 'components/controls/back-link';

export default class LicenseHistory extends PureComponent {
  constructor(props) {
    super(props);

    this.partners = List();
    this.managers = List();
    this.types = [];

    const users = props.usersDataIm.get('data');

    users.forEach((user) => {
      const userRole = user.get('role');

      if (userRole === roles.PARTNER) {
        this.partners = this.partners.push(user);
      }

      if (userRole === roles.MANAGER) {
        this.managers = this.managers.push(user);
      }
    });

    this.types = Object.entries(licenseTypes).map(([typeName, typeValue]) => ({
      label: i18next.t(licenseTypeTranslations[typeName]),
      value: typeValue,
    }));

    this.functionality = props.functionalitiesDataIm.get('data');
  }

  render() {
    const { currentLicensesDataIm: licensesDataIm, match, productType } = this.props;

    if (!match) {
      return null;
    }

    const licenseId = parseInt(match.params.licenseId, 10);
    let filterLicenseIm = List();

    // TODO: переписать
    /**
     *
     *   # id: 82, originalLicenseId: 57
     *   ^
     *   |_
     *     |
     *     |
     *    # id: 57, originalLicenseId: 17
     *              |_
     *               |
     *               > # id: 17, originalLicenseId: null
     */
    const licenseIm = licensesDataIm.get('data')
      .find(model => model.get('id') === licenseId);

    filterLicenseIm = filterLicenseIm.push(licenseIm);

    let newDownLicenseId = licenseIm.get('originalLicenseId');
    let newDownLicenseIm;

    const compareDownLicense = model => model.get('id') === newDownLicenseId;

    while (newDownLicenseId) {
      newDownLicenseIm = licensesDataIm.get('data').find(compareDownLicense);
      newDownLicenseId = false;

      if (newDownLicenseIm) {
        filterLicenseIm = filterLicenseIm.push(newDownLicenseIm);
        newDownLicenseId = newDownLicenseIm.getIn(['originalLicenseId']);
      }
    }

    let newUpLicenseId = licenseId;
    let newUpLicenseIm;

    const compareUpLicense = model => model.get('originalLicenseId') === newUpLicenseId;

    while (newUpLicenseId) {
      newUpLicenseIm = licensesDataIm.get('data').find(compareUpLicense);
      newUpLicenseId = false;

      if (newUpLicenseIm) {
        filterLicenseIm = filterLicenseIm.push(newUpLicenseIm);
        newUpLicenseId = newUpLicenseIm.getIn(['id']);
      }
    }
    // ///////////

    const cells = [
      {
        id: 'id',
        getValue: model => model.get('id'),
        className: 'table__cell_2',
        name: i18next.t('id'),
        sort: {
          type: 'arithmetic',
          field: 'id',
        },
      },
      {
        id: 'type',
        getValue: model => model.get('type'),
        className: 'table__cell_2',
        name: i18next.t('type'),
        sort: {
          type: 'alphabetic',
          field: 'type',
        },
        component: LicenseTypeCell,
      },
      {
        id: 'partner',
        getValue: model => model.get('partnerName'),
        className: 'table__cell_3',
        name: i18next.t('partner'),
        sort: {
          type: 'alphabetic',
          field: 'partnerName',
        },
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
      },
      {
        id: 'status',
        getValue: model => model.get('status'),
        className: 'table__cell_3',
        name: i18next.t('status'),
        sort: {
          type: 'alphabetic',
          field: 'status',
        },
        component: LicenseStatusCell,
      },
      {
        id: 'activation_date',
        getValue: model => model.get('generatedDate'),
        className: 'table__cell_3',
        name: i18next.t('activation_date'),
        sort: {
          type: 'arithmetic',
          field: 'generatedDate',
        },
        component: DateCell,
      },
      {
        id: 'support_end_date',
        getValue: model => model.get('supportExpirationDate'),
        className: 'table__cell_3',
        name: i18next.t('support_end_date'),
        sort: {
          type: 'arithmetic',
          field: 'supportExpirationDate',
        },
        component: SupportEndDateCell,
      },
      {
        id: 'order_id',
        getValue: model => ({
          text: model.get('orderUid'),
          link: `/orders/${model.get('orderId')}`,
        }),
        className: 'table__cell_2',
        name: i18next.t('order_id'),
        sort: {
          type: 'arithmetic',
          field: 'orderId',
        },
        component: LinkCell,
      },
      {
        id: 'functionality',
        getValue: model => model.get('functionalities'),
        className: 'table__cell_6',
        name: i18next.t('functionality'),
        component: FunctionalityCell,
        componentProps: {
          functionalities: this.functionality,
        },
        isShow: productType === productTypes.RAIDIX,
      },
      {
        id: 'drive_type',
        getValue: model => model.get('driveType'),
        className: 'table__cell_3',
        name: i18next.t('drive_type'),
        sort: {
          type: 'alphabetic',
          field: 'driveType',
        },
        component: LicenseDriveTypeCell,
        isShow: productType === productTypes.ERA,
      },
      {
        id: 'extended_raid',
        getValue: model => model.get('extendedRaid'),
        className: 'table__cell_3',
        name: i18next.t('additional_raid_short'),
        sort: {
          type: 'alphabetic',
          field: 'extendedRaid',
        },
        component: BooleanCell,
        isShow: productType === productTypes.ERA,
      },
    ];

    const defaultSort = Map({
      field: 'id',
      type: 'arithmetic',
      order: 'desc',
    });

    return (
      <Fragment>
        <div className="content__head">
          <BackLink
            text={i18next.t('back_to_list_of_licenses')}
            to="/licenses"
          />
          <h1 className="content__title">
            {i18next.t('license_history')}
            <span className="content__subtitle"> #{licenseId}</span>
          </h1>
        </div>
        <div className="content__body">
          <Table
            id="licenses"
            items={filterLicenseIm}
            defaultSort={defaultSort}
            cells={cells}
          />
        </div>
      </Fragment>
    );
  }
}
