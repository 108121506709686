/* eslint-disable new-cap */
const CryptoJS = require('crypto-js');

const getHashedPassword = (password) => {
  let newPassword = CryptoJS.enc.Hex.stringify(
    CryptoJS.HmacSHA512(password, 'X_Kz#U74@L!F#YfpGP_lPOh0U-jpH?N')
  );

  newPassword = CryptoJS.enc.Hex.stringify(
    CryptoJS.HmacSHA512(newPassword, 'bU!!L&*YGV2@#n6IJ/mlTH@9H+QW&!_')
  );

  return newPassword;
};

module.exports = getHashedPassword;
