import { Map } from 'immutable';
import { applicationName } from 'config';

/*
* Constants
* */
const MODULE_NAME = 'table-component';

// action types
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;
const SORT_CHANGE = `${applicationName}/${MODULE_NAME}/SORT_CHANGE`;
const CHANGE_PAGE = `${applicationName}/${MODULE_NAME}/CHANGE_PAGE`;
const CHANGE_ITEMS_PER_PAGE = `${applicationName}/${MODULE_NAME}/CHANGE_ITEMS_PER_PAGE`;
const CHANGE_FILTERS = `${applicationName}/${MODULE_NAME}/CHANGE_FILTERS`;
const CHANGE_QUICK_FILTERS = `${applicationName}/${MODULE_NAME}/CHANGE_QUICK_FILTERS`;
const RESET_FILTERS = `${applicationName}/${MODULE_NAME}/RESET_FILTERS`;

/*
* Reducer
* */

const initialState = Map();

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET: {
      return state.delete(action.payload.id);
    }

    case SORT_CHANGE: {
      return state.mergeDeep({
        [action.payload.id]: {
          sort: action.payload.sort,
        },
      });
    }

    case CHANGE_PAGE: {
      return state.mergeDeep({
        [action.payload.id]: {
          page: action.payload.page,
        },
      });
    }

    case CHANGE_ITEMS_PER_PAGE: {
      return state.mergeDeep({
        [action.payload.id]: {
          page: 1,
          itemsPerPage: action.payload.itemsPerPage,
        },
      });
    }

    case CHANGE_FILTERS:
    case CHANGE_QUICK_FILTERS: {
      const isQuickFilter = action.type === CHANGE_QUICK_FILTERS;

      // Не даём установить для быстрого фильтра пустого значения
      if (isQuickFilter && Object.keys(action.payload.filters).length === 0) {
        return state;
      }

      const tableFilter = Map(state.get(action.payload.id));
      const newTableFilter = tableFilter.withMutations((mutable) => {
        mutable
          .set('filters', Map(action.payload.filters))
          .set('quickFilter', isQuickFilter ? action.payload.name : false)
          .set('page', 1);
      });

      return state.set(action.payload.id, newTableFilter);
    }

    case RESET_FILTERS: {
      return state.mergeDeep({
        [action.payload.id]: {
          page: 1,
          quickFilter: false,
          filters: false,
        },
      });
    }

    default: {
      return state;
    }
  }
}

/*
* Actions
* */

const tableComponentResetDelta = id => ({ type: RESET, payload: { id } });
const tableComponentSortChangeDelta = (id, sort) => ({ type: SORT_CHANGE, payload: { id, sort } });
const tableComponentChangePageDelta = (id, page) => ({ type: CHANGE_PAGE, payload: { id, page } });
const tableComponentChangeItemsPerPageDelta = (id, itemsPerPage) => ({
  type: CHANGE_ITEMS_PER_PAGE,
  payload: { id, itemsPerPage },
});
const tableComponentChangeFiltersDelta = (id, filters) => ({
  type: CHANGE_FILTERS,
  payload: { id, filters },
});
const tableComponentChangeQuickFiltersDelta = (id, filters, name) => ({
  type: CHANGE_QUICK_FILTERS,
  payload: { id, filters, name },
});
const tableComponentResetFiltersDelta = id => ({ type: RESET_FILTERS, payload: { id } });

// all actions
export const actions = {
  tableComponentResetDelta,
  tableComponentSortChangeDelta,
  tableComponentChangePageDelta,
  tableComponentChangeItemsPerPageDelta,
  tableComponentChangeFiltersDelta,
  tableComponentChangeQuickFiltersDelta,
  tableComponentResetFiltersDelta,
};
