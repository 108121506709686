import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import { productTypes, productTypeTranslations } from 'shared/constants';
import { Tab, TabList, TabPanel } from 'react-tabs';
import OrdersPage from 'components/pages/orders/orders-page';
import FetchData from 'components/fetch-data';
import MemorizeTabs from 'components/controls/memorize-tabs';

export default class Orders extends PureComponent {
  constructor(props) {
    super(props);

    this.getRaidixOrders = this.getRaidixOrders.bind(this);
    this.getEraOrders = this.getEraOrders.bind(this);
    this.getDRaidOrders = this.getDRaidOrders.bind(this);
  }

  getRaidixOrders() {
    return this.getOrdersByProductType(productTypes.RAIDIX);
  }

  getEraOrders() {
    return this.getOrdersByProductType(productTypes.ERA);
  }

  getDRaidOrders() {
    return this.getOrdersByProductType(productTypes.DRAID);
  }

  getOrdersByProductType(productType) {
    const ordersDataIm = this.props.ordersDataIm.get('data')
      .filter(model => model.get('productType') === productType);

    return { ordersDataIm };
  }

  getTabName(productType) {
    return i18next.t(productTypeTranslations[productType]);
  }

  getTabs() {
    const {
      licensesDataGetSignal,
      eraLicensesDataGetSignal,
      eraLicensesDataIm,
      draidLicensesDataIm,
      draidLicensesDataGetSignal
    } = this.props;

    return [
      {
        productType: productTypes.RAIDIX,
        fetchProps: {
          updateProps: this.getRaidixOrders,
          licensesDataGetSignal,
          fetchActionNames: ['licensesDataGetSignal'],
        },
      },
      {
        productType: productTypes.ERA,
        fetchProps: {
          updateProps: this.getEraOrders,
          eraLicensesDataGetSignal,
          fetchActionNames: ['eraLicensesDataGetSignal'],
        },
        componentProps: {
          licensesDataIm: eraLicensesDataIm,
        }
      },
      {
        productType: productTypes.DRAID,
        fetchProps: {
          updateProps: this.getDRaidOrders,
          draidLicensesDataGetSignal,
          fetchActionNames: ['draidLicensesDataGetSignal'],
        },
        componentProps: {
          licensesDataIm: draidLicensesDataIm,
        }
      }
    ];
  }

  getAvailableTabs() {
    const productCount = this.props.ordersInfoDataIm.getIn(['data', 'productCount']);

    return this.getTabs().filter(({ productType }) => {
      const productInfo = productCount.find(
        product => product.get('productType') === productType
      );

      return productInfo !== undefined || this.hasCreatingProductType(productType);
    });
  }

  hasCreatingProductType(productType) {
    const { authDataIm, usersDataIm } = this.props;
    const currentUserId = authDataIm.getIn(['data', 'id']);

    return usersDataIm.get('data')
      .find(user => user.get('id') === currentUserId)
      .get('creatingProductTypes')
      .includes(productType);
  }

  render() {
    const availableTabs = this.getAvailableTabs();
    const hasTabs = availableTabs.length > 0;

    return (
      <Fragment>
        <div className="content__head">
          <h1 className="content__title">{i18next.t('orders')}</h1>
        </div>
        {
          hasTabs ? (
            <MemorizeTabs name="order-license-tabs">
              <TabList>
                {availableTabs.map(({ productType }) => (
                  <Tab key={productType}>{this.getTabName(productType)}</Tab>
                ))}
              </TabList>

              {availableTabs.map(({ productType, fetchProps, componentProps }) => (
                <TabPanel key={productType}>
                  <FetchData {...fetchProps}>
                    <OrdersPage
                      {...this.props}
                      productType={productType}
                      tableId={`${productType}-orders`}
                      hideAddButton={!this.hasCreatingProductType(productType)}
                      {...componentProps}
                    />
                  </FetchData>
                </TabPanel>
              ))}
            </MemorizeTabs>
          ) : (
            <div className="empty-table">
              <div className="empty-table__title">{i18next.t('no_data_here')}</div>
            </div>
          )
        }
      </Fragment>
    );
  }
}
