import { combineReducers } from 'redux';
import toastsComponentIm from './toasts';
import tableComponentIm from './table';
import formComponentIm from './form';
import modalComponentIm from './modal';
import licensesListComponentIm from './licenses-list';

const componentsReducer = combineReducers({
  toastsComponentIm,
  tableComponentIm,
  formComponentIm,
  modalComponentIm,
  licensesListComponentIm,
});

export default componentsReducer;
