import React, { PureComponent, Fragment } from 'react';
import { Modal } from 'containers';
import i18next from 'i18next';
import { licenseCreateTypes } from 'constants';
import { orderTypes, productTypes } from 'shared/constants';
import { LicenseTextModal, SelectLicenseModal, ConfirmModal } from 'components/modals';
import { RaidixLicense, EraLicense, DraidLicense } from 'components/form/order/license';

export default class LicenseForm extends PureComponent {
  constructor(props) {
    super(props);

    if (props.licensesOrderIm) {
      const hui = {
        [productTypes.RAIDIX]: props.licensesDataIm,
        [productTypes.ERA]: props.eraLicensesDataIm,
        [productTypes.DRAID]: props.draidLicensesDataIm,
      }

      const licenses = hui[props.productType].get('data');

      const licenseTypes = props.licensesOrderIm.map((licenseOrder) => {
        const originalLicenseId = licenseOrder.get('originalLicenseId');

        return {
          licenseId: licenseOrder.get('id'),
          type: originalLicenseId ?
            licenseCreateTypes.CHANGE :
            licenseCreateTypes.NEW,
          originalLicenseData: originalLicenseId ?
            licenses.find(license => license.get('id') === originalLicenseId) :
            null,
        };
      });

      this.props.licensesListComponentAddDelta(licenseTypes);
    }

    this.validate = this.validate.bind(this);
    this.onAddLicense = this.onAddLicense.bind(this);
    this.onChangeLicense = this.onChangeLicense.bind(this);
    this.onRemoveLicense = this.onRemoveLicense.bind(this);
    this.onGenerateLicense = this.onGenerateLicense.bind(this);
    this.onSelectLicense = this.onSelectLicense.bind(this);
  }

  componentWillUnmount() {
    this.props.licensesListComponentResetDelta();
  }

  onAddLicense() {
    this.props.licensesListComponentAddDelta([{ type: licenseCreateTypes.NEW }]);
  }

  onChangeLicense() {
    this.props.modalComponentShowDelta('selectLicense');
  }

  onSelectLicense({ item }) {
    this.props.licensesListComponentAddDelta([{
      type: licenseCreateTypes.CHANGE,
      originalLicenseData: item,
    }]);
  }

  onRemoveLicense(id) {
    this.props.licensesListComponentDeleteDelta(id);
  }

  onGenerateLicense(licenseId, data) {
    this.props.formComponentSubmitWrapperSignal({
      submitSignal: ({ force = false }) => this.props.generatorDataGenerateSignal({
        licenseId,
        data: {
          ...data,
          force,
        },
      }),
    });
  }

  get value() {
    // Запрашиваем данные у каждой лицензии
    return this.props.licensesListComponentIm.get('items').map(
      license => this.getLicense(license.get('id')).value,
    );
  }

  getLicense(license) {
    return this[`license_${license}_ref`];
  }

  getLicenseComponent(productType) {
    switch (productType) {
      case productTypes.RAIDIX:
        return RaidixLicense;
      case productTypes.ERA:
        return EraLicense;
      case productTypes.DRAID:
        return DraidLicense;
      default:
        return null;
    }
  }

  validate() {
    let validate = true;

    // Запускаем валидацию для каждой лицензии
    this.props.licensesListComponentIm.get('items').forEach((license) => {
      const validateLicense = this.getLicense(license.get('id')).validate();

      if (validateLicense === false) {
        validate = false;
      }
    });

    return validate;
  }

  render() {
    const {
      isEdit,
      disabled,
      orderType,
      orderId,
      orderStatus,
      orderCurrentStatus,
      productType,
      functionalitiesDataIm,
      licensesListComponentIm,
      licensesOrderIm,
      licenseSubtypesDataIm,
      formComponentIm,
      modifiableLicenses,
      modalComponentShowDelta,
      currentUserRole,
      currentUserLicenseSubtypesDataIm,
      productVersionsDataIm,
    } = this.props;
    const originalLicenseIds = [];

    const licenses = licensesListComponentIm.get('items').map((license) => {
      const id = license.get('id');
      const licenseId = license.get('licenseId');
      const originalLicenseData = license.get('originalLicenseData');
      const licenseOrderIm = licenseId ?
        licensesOrderIm.find(item => item.get('id') === licenseId) : false;
      let licenseDisabled = disabled;
      let hideRemoveButton = disabled;

      // Собираем id оригинальных лицензий, что бы в последствии
      // исключить их из списка выбираемых для изменения
      if (originalLicenseData && originalLicenseData.get('id')) {
        originalLicenseIds.push(originalLicenseData.get('id'));
      }

      // При изменении аппаратного ключа лицензии
      // для редактирования доступен только он
      if (orderType === orderTypes.HWKEY_CHANGE) {
        licenseDisabled = true;
        hideRemoveButton = false;
      }

      const LicenseComponent = this.getLicenseComponent(productType);

      return (
        <LicenseComponent
          id={id}
          key={id}
          isEdit={isEdit}
          currentUserRole={currentUserRole}
          disabled={licenseDisabled}
          hideRemoveButton={hideRemoveButton}
          type={license.get('type')}
          originalLicenseData={originalLicenseData}
          orderType={orderType}
          orderId={orderId}
          orderStatus={orderStatus}
          orderCurrentStatus={orderCurrentStatus}
          licenseOrderIm={licenseOrderIm}
          licenseSubtypesDataIm={licenseSubtypesDataIm}
          functionalitiesDataIm={functionalitiesDataIm}
          formComponentIm={formComponentIm}
          onRemoveLicense={this.onRemoveLicense}
          onGenerateLicense={this.onGenerateLicense}
          ref={(ref) => { this[`license_${id}_ref`] = ref; }}
          modalComponentShowDelta={modalComponentShowDelta}
          currentUserLicenseSubtypesDataIm={currentUserLicenseSubtypesDataIm}
          productVersionsDataIm={productVersionsDataIm}
        />
      );
    });

    const filterNoSelectLicenses = license => !originalLicenseIds.includes(license.get('id'));
    // Leave not selected licenses
    const noSelectLicenses = modifiableLicenses.filter(filterNoSelectLicenses);

    const changeLicenseButtonText = orderType === orderTypes.HWKEY_CHANGE ?
      i18next.t('select_license') :
      i18next.t('change_license');

    return (
      <Fragment>
        {licenses}

        {
          disabled !== true ? (
            <div className="form__row form__row_2">
              <div className="controls-group controls-group_form">
                {
                  (orderType === orderTypes.COMMERCIAL || orderType === orderTypes.TRIAL) ? (
                    <div className="controls-group__control">
                      <input
                        type="button"
                        className="button"
                        value={i18next.t('add_license')}
                        onClick={this.onAddLicense}
                      />
                    </div>
                  ) : null
                }

                {
                  (noSelectLicenses.size > 0 &&
                    (orderType === orderTypes.COMMERCIAL || orderType === orderTypes.HWKEY_CHANGE)
                  ) ? (
                    <div className="controls-group__control">
                      <input
                        type="button"
                        className="button"
                        value={changeLicenseButtonText}
                        onClick={this.onChangeLicense}
                      />
                    </div>
                  ) : null
                }
              </div>
            </div>
          ) : null
        }

        <div className="form__row form__row_2">
          <div className="form-line" />
        </div>
        <Modal modalId="selectLicense" wide>
          <SelectLicenseModal
            licenses={noSelectLicenses}
            productType={productType}
            functionalitiesDataIm={functionalitiesDataIm}
            onSelectLicense={this.onSelectLicense}
          />
        </Modal>
        <Modal modalId="licenseText">
          <LicenseTextModal />
        </Modal>
        <Modal modalId="confirm">
          <ConfirmModal />
        </Modal>
      </Fragment>
    );
  }
}
