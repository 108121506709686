import React from 'react';
import { Switch, Route } from 'react-router-dom';
import checkAuthEnhance from 'components/check-auth-enhance';
import SidebarMenu from 'components/app/sidebar-menu';
import UserMenu from 'components/app/user-menu';
import {
  Users,
  AddUser,
  EditUser,
  Licenses,
  Orders,
  AddOrder,
  EditOrder,
  LicenseHistory,
  Toasts,
  ModalBackground,
} from 'containers';
import i18next from 'i18next';
import { NoMatchError } from 'components/pages/error';

const App = ({ authDataIm, authDataSetLanguageDelta, authDataLogoutSignal }) => (
  <div className="app">
    <div className="sidebar">
      <SidebarMenu authDataIm={authDataIm} />
      <UserMenu
        authDataIm={authDataIm}
        authDataLogoutSignal={authDataLogoutSignal}
        authDataSetLanguageDelta={authDataSetLanguageDelta}
      />
    </div>
    <div className="content">
      <Switch>
        <Route path="/orders" component={Orders} exact />
        <Route path="/orders/add/:productType" component={AddOrder} />
        <Route path="/orders/add/" component={AddOrder} />
        <Route path="/orders/:orderId" component={EditOrder} />
        <Route path="/licenses" component={Licenses} exact />
        <Route path="/licenses/history/:productType/:licenseId" component={LicenseHistory} />
        <Route path="/users" component={Users} exact />
        <Route path="/users/add" component={AddUser} />
        <Route path="/users/:userId" component={EditUser} />
        <Route component={NoMatchError} />
      </Switch>
      <div className="content__footer">
        <div className="content__footer-text">
          {i18next.t('license_generator')} 2.0
        </div>
      </div>
    </div>

    <ModalBackground />

    <Toasts />
  </div>
);

export default checkAuthEnhance(App);
