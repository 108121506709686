import React from 'react';
import moment from 'moment';
import {
  orderTypes,
  supportPeriods,
  supportPeriodDates,
  DATE_FORMAT,
} from 'shared/constants';
import i18next from 'i18next';

export default function SupportInfo({
  orderType,
  supportPeriod,
  supportPeriodDays,
  // End time of support original license
  supportExpirationDateUnix,
  generatedDateUnix,
  showOnlyNewSupport = false,
}) {
  let supportExpirationDateFormat = '\u2014';
  let newSupportExpirationDateFormat = '\u2014';
  let supportExpirationDate = moment();
  // TODO: Take time data from server
  const currentTime = Date.now() / 1000;

  if (supportExpirationDateUnix > 0) {
    supportExpirationDate = moment(supportExpirationDateUnix, 'X');
    supportExpirationDateFormat = supportExpirationDate.format(DATE_FORMAT);
  }

  // We believe that the license will be generated in the near future
  let approximateGenerationDate = currentTime;

  // Specify the exact date of generation
  if (generatedDateUnix) {
    approximateGenerationDate = generatedDateUnix;
  }

  let startPoint = supportExpirationDate;

  if (
    (approximateGenerationDate > supportExpirationDateUnix) ||
    (generatedDateUnix && !supportExpirationDateUnix)
  ) {
    startPoint = moment(approximateGenerationDate, 'X');
  }

  // When changing the hardware key, the end date of support is always inherited
  if (orderType === orderTypes.HWKEY_CHANGE) {
    startPoint = supportExpirationDate;
  }

  let newSupportExpirationDate = startPoint;

  if (supportPeriod) {
    if (supportPeriod === supportPeriods.SPECIFIED_NUMBER_OF_DAYS) {
      newSupportExpirationDate = startPoint.add({ day: supportPeriodDays });
    }

    if (supportPeriodDates[supportPeriod]) {
      newSupportExpirationDate = startPoint.add(supportPeriodDates[supportPeriod]);
    }
  }

  if (supportExpirationDateUnix > 0 ||
    (supportPeriod && supportPeriod !== supportPeriods.WITHOUT_SUPPORT)
  ) {
    newSupportExpirationDateFormat = newSupportExpirationDate.format(DATE_FORMAT);
  }

  return showOnlyNewSupport ? (
    <div className="support-info">
      <div className="support-info__item">
        <span>{i18next.t('support_end_date')}: </span>
        <span className="gray-text support-info__value">{newSupportExpirationDateFormat}</span>
      </div>
    </div>
  ) : (
    <div className="support-info">
      <div className="support-info__item">
        <span >{i18next.t('support_end_date')}: </span>
        <span className="gray-text support-info__value">{supportExpirationDateFormat}</span>
      </div>
      <div className="support-info__item">
        <span>{i18next.t('new_support_end_date')}: </span>
        <span className="gray-text support-info__value">{newSupportExpirationDateFormat}</span>
      </div>
    </div>
  );
}
