import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { Map } from 'immutable';
import { Table } from 'containers';
import browserHistory from 'browser-history';
import { CSV_FILE_NAME_DATE_FORMAT } from 'constants';
import { LicenseToolbar } from 'components/toolbars';
import { roles, orderStatus } from 'shared/constants';

export default class LicensesPage extends PureComponent {
  constructor(props) {
    super(props);

    this.onExportToFile = this.onExportToFile.bind(this);
  }

  onExportToFile() {
    const { productType, licensesCsvDataGetSignal } = this.props;

    this.licensesTableRef
      .getWrappedInstance()
      .exportToFile({
        productType,
        dataGetSignal: licensesCsvDataGetSignal,
        fileName: `${productType.toLowerCase()}_licenses_${moment().format(CSV_FILE_NAME_DATE_FORMAT)}`,
      });
  }

  render() {
    const {
      licensesDataIm,
      authDataIm,
      tableId,
      filterFields,
      cells,
      productType,
    } = this.props;

    const now = new Date();
    // Первая секунда текущего дня
    const currentDay = moment(
      `${now.getDate()}.${now.getMonth() + 1}.${now.getFullYear()}`,
      'DD.MM.YYYY',
    );
    const formatCurrentDay = currentDay.format('X');

    const quickFilterFields = [
      {
        key: 'no_support',
        name: i18next.t('no_support'),
        title: i18next.t('no_support_title'),
        values: {
          supportExpirationDate: [
            null,
            formatCurrentDay,
            true, // Лицензии с незаданным периодом поддержки
          ],
        },
        allowedToShowFor: [roles.MANAGER],
      },
      {
        key: 'expiring_support',
        name: i18next.t('expiring_support'),
        fields: [
          {
            type: 'dateInterval',
            key: 'supportExpirationDate',
            name: i18next.t('support_end_date'),
          },
          {
            type: 'checkbox',
            key: 'managerId',
            name: i18next.t('only_my_licenses'),
            filterFunction: ({ model, fieldName }) =>
              model.get(fieldName) === authDataIm.getIn(['data', 'id']),
          },
        ],
        values: {
          supportExpirationDate: [
            formatCurrentDay,
            currentDay
              // Добавляем 30 дней к текущему дню
              .add({ days: 30, hours: 23, minutes: 59, seconds: 59 })
              .format('X'),
          ],
          managerId: true,
        },
        allowedToShowFor: [roles.MANAGER],
      },
      {
        key: 'issue_date',
        name: i18next.t('issue_date'),
        fields: [
          {
            type: 'dateInterval',
            key: 'generatedDate',
            name: i18next.t('issue_date'),
          },
        ],
        values: {
          generatedDate: [
            // Начало текущего квартала
            moment().startOf('quarter').format('X'),
            undefined,
          ]
        },
        allowedToShowFor: [roles.ACCOUNTANT],
      },
      {
        key: 'orderStatus',
        name: i18next.t('not_canceled'),
        values: {
          orderStatus: [
            orderStatus.PENDING,
            orderStatus.DOCUMENTS_SENT,
            orderStatus.GENERATION_READY,
          ],
        },
      },
    ];

    const defaultSort = Map({
      field: 'id',
      type: 'arithmetic',
      order: 'desc',
    });

    const actionButtons = [{
      label: i18next.t('history'),
      onClick: license => browserHistory.push(`/licenses/history/${productType.toLowerCase()}/${license.get('id')}`),
    }];

    return (
      <Fragment>
        <LicenseToolbar
          tableId={tableId}
          filterFields={filterFields}
          quickFilterFields={quickFilterFields}
          authDataIm={authDataIm}
          onExportToFile={this.onExportToFile}
        />
        <div className="content__body">
          <Table
            id={tableId}
            items={licensesDataIm.get('data')}
            defaultSort={defaultSort}
            cells={cells}
            filterFields={filterFields}
            quickFilterFields={quickFilterFields}
            actionButtons={actionButtons}
            ref={(ref) => { this.licensesTableRef = ref; }}
          />
        </div>
      </Fragment>
    );
  }
}
