export const applicationName = 'license-generator';

export const rights = {
  admin: [
    '/orders',
    '/licenses',
    '/users',
  ],

  manager: [
    '/orders',
    '/licenses',
    '/users',
  ],

  partner: [
    '/orders',
    '/licenses',
  ],

  engineer: [
    '/orders',
    '/licenses',
  ],

  accountant: [
    '/orders',
    '/licenses',
  ],
};

export const menu = {
  '/orders': {
    text: 'orders',
    icon: 'orders',
  },

  '/licenses': {
    text: 'licenses',
    icon: 'licenses',
  },

  '/users': {
    text: 'users',
    icon: 'users',
  },
};
