import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Auth, App } from 'containers';

export default (
  <Switch>
    {/* redirect */}
    <Redirect exact from="/" to="/licenses" />

    {/* auth pages */}
    <Route path="/auth" component={Auth} />

    {/* app pages */}
    <Route component={App} />
  </Switch>
);
