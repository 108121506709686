import { connect } from 'react-redux';
import { actions as tableComponentActions } from 'ducks/components/table';
import { Table } from 'components/table';
import { List } from 'immutable';

const mapDispatchToProps = tableComponentActions;

function mapStateToProps(state, ownProps) {
  const tableComponentImForId = state.components.tableComponentIm.get(ownProps.id);

  return {
    authDataIm: state.data.authDataIm,
    tableComponentIm: tableComponentImForId !== undefined ? tableComponentImForId : List(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Table);
