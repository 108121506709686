import React, { PureComponent } from 'react';
import i18next from 'i18next';
import browserHistory from 'browser-history';

export default class ErrorPage extends PureComponent {
  onReturnToHomepage() {
    browserHistory.push('/licenses/');
  }

  render() {
    const {
      title = i18next.t('error_page.error_title'),
      textNormal,
      textSmall,
      icon: Icon,
    } = this.props;

    return (
      <div className="error-page">
        <div className="error-page__container">
          <div className="error-page__icon">
            <Icon
              width="160"
              height="160"
            />
          </div>
          <div className="error-page__message error-page__message_wide">
            <div className="error-page__text_big">
              {title}
            </div>

            {
              textNormal ? (
                <div className="error-page__text_normal">
                  {textNormal}
                </div>
              ) : null
            }

            {
              textSmall ? (
                <div className="error-page__text_small">
                  {textSmall}
                </div>
              ) : null
            }

            <div className="error-page__footer">
              <button
                className="button"
                autoFocus
                onClick={this.onReturnToHomepage}
              >
                {i18next.t('error_page.return_to_the_homepage')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
