import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { rights, menu } from 'config';
import i18next from 'i18next';
import LicencesIcon from 'img/licenses.svg';
import OrdersIcon from 'img/orders.svg';
import UsersIcon from 'img/users.svg';

const icons = {
  licenses: LicencesIcon,
  orders: OrdersIcon,
  users: UsersIcon,
};

const SidebarMenuLink = ({ link }) => {
  const Icon = icons[menu[link].icon];
  const text = i18next.t(menu[link].text);

  return (
    <NavLink
      className="sidebar__menu-item"
      activeClassName="sidebar__menu-item_active"
      title={text}
      to={link}
    >
      <span className="sidebar__menu-icon">
        <Icon width="28" height="28" />
      </span>
      <span className="sidebar__menu-text">{text}</span>
    </NavLink>
  );
};

// Здесь нужен Component, а не PureComponent для работы activeClassName в SidebarMenuLink
export default class SidebarMenu extends Component {
  // return only accessed links for current user role
  getLinks() {
    const role = this.props.authDataIm.getIn(['data', 'role']);
    const accessedPagesUrl = rights[role];

    return accessedPagesUrl.map(link => <SidebarMenuLink key={link} link={link} />);
  }

  render() {
    return (
      <div className="sidebar__menu">
        {this.getLinks()}
      </div>
    );
  }
}
