import React, { PureComponent, Fragment } from 'react';
import Input from 'components/controls/input';
import Select from 'components/controls/select';
import OrderStatus from 'components/controls/order-status';
import Checkbox from 'components/controls/checkbox';
import i18next from 'i18next';
import _isFunction from 'lodash/isFunction';
import _isNumber from 'lodash/isNumber';
import DateInterval from './date-interval';

const FilterButtons = ({ onResetFilter, onChangeFilter }) => (
  <Fragment>
    <div className="form__row form__row_1">
      <div className="form-line form-line_filter" />
    </div>

    <div className="form__row form__row_1 form__row_footer">
      <div className="controls-group controls-group_form">
        <div className="controls-group__control">
          <button
            type="reset"
            className="button button_form"
            onClick={onResetFilter}
          >
            {i18next.t('reset')}
          </button>
        </div>
        <div className="controls-group__control">
          <button
            type="submit"
            className="button button_form button_submit"
            onClick={onChangeFilter}
          >
            {i18next.t('filter')}
          </button>
        </div>
      </div>
    </div>
  </Fragment>
);

export default class TableFilter extends PureComponent {
  constructor(props) {
    super(props);

    const { authDataIm, filterFields } = props;
    const role = authDataIm.getIn(['data', 'role']);

    this.permittedFilterFields = filterFields.filter(
      filterField => filterField.allowedToShowFor === undefined ||
        (filterField.allowedToShowFor && filterField.allowedToShowFor.indexOf(role) !== -1)
    );

    this.onResetFilter = this.onResetFilter.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
  }

  onChangeFilter(event) {
    event.preventDefault();

    const {
      id,
      quickFilterName,
      isQuickFilter = false,
      tableComponentChangeFiltersDelta,
      tableComponentChangeQuickFiltersDelta,
    } = this.props;
    const filter = {};

    this.permittedFilterFields.forEach((field) => {
      const fieldKey = field.key;
      const refName = this.getRefName(field);
      const fieldRef = this[refName];
      let fieldValue;

      if (field.type === 'checkbox') {
        fieldValue = fieldRef.checked;
      } else {
        fieldValue = fieldRef.value;
      }

      if (Array.isArray(fieldValue) && fieldValue.length === 0) {
        fieldValue = false;
      }

      if (fieldValue || (_isNumber(fieldValue) && fieldValue === 0)) {
        filter[fieldKey] = fieldValue;
      }
    });

    // Если фильтр используется для изменения быстрого фильтра
    if (isQuickFilter && quickFilterName) {
      tableComponentChangeQuickFiltersDelta(id, filter, quickFilterName);
    } else {
      tableComponentChangeFiltersDelta(id, filter);
    }
  }

  onResetFilter(event) {
    event.preventDefault();

    const { tableComponentResetFiltersDelta, id, defaultValue = {} } = this.props;

    this.permittedFilterFields.forEach((field) => {
      const refName = this.getRefName(field);
      const fieldRef = this[refName];
      const fieldDefaultValue = defaultValue[field.key];

      switch (field.type) {
        case 'text': {
          fieldRef.value = fieldDefaultValue !== undefined ? fieldDefaultValue : '';
          break;
        }

        case 'select':
        case 'multiSelect':
        case 'orderStatus': {
          fieldRef.value = fieldDefaultValue !== undefined ? fieldDefaultValue : '';
          break;
        }
        case 'checkbox': {
          fieldRef.checked = fieldDefaultValue !== undefined ? fieldDefaultValue : false;
          break;
        }

        case 'dateInterval': {
          fieldRef.value = fieldDefaultValue !== undefined ?
            fieldDefaultValue :
            [undefined, undefined];
          break;
        }

        default: {
          break;
        }
      }
    });

    tableComponentResetFiltersDelta(id);
  }

  getField(filterField, filterKey) {
    const { tableComponentIm, isQuickFilter = false, quickFilterName, defaultValue } = this.props;
    const refName = this.getRefName(filterField);
    let currentValue = defaultValue && defaultValue[filterField.key] ?
      defaultValue[filterField.key] :
      false;
    let inputBlock;

    if (tableComponentIm) {
      const filters = tableComponentIm.getIn(['filters', filterField.key]);
      const quickFilter = tableComponentIm.get('quickFilter');

      // Выставляем значение по умолчанию если оно существует
      if (filters === undefined && defaultValue && defaultValue[filterField.key]) {
        currentValue = defaultValue[filterField.key];
      } else {
        // Если незадан быстрый фильтр и в фильтре запрещено отображать значения быстрого фильтра
        currentValue = ((!quickFilter && !isQuickFilter) ||
          // Если разрешено отображать значения быстрого фильтра (используется когда
          // пользователю разрешено скорректировать изначальные данные быстрого фильтра)
          // и его названия равно выбранному фильтру
          (isQuickFilter && quickFilter === quickFilterName)
        ) ? filters : false;
      }
    }

    // Автофокус для первого контрола в фильтре
    const autoFocus = filterKey === 0;

    switch (filterField.type) {
      case 'text': {
        inputBlock = (
          <Input
            type="text"
            className="form__field-text form__field-text_filter-padding"
            ref={(ref) => { this[refName] = ref; }}
            defaultValue={currentValue || ''}
            placeholder={i18next.t('placeholder.use_comma_to_separate_values')}
            autoFocus={autoFocus}
          />
        );
        break;
      }
      case 'checkbox': {
        inputBlock = (
          <Checkbox
            id="functionality__item-id"
            label={filterField.name}
            defaultChecked={currentValue}
            ref={(ref) => { this[refName] = ref; }}
          />
        );
        break;
      }

      case 'select':
      case 'multiSelect':
      case 'orderStatus': {
        const filterFieldItems = _isFunction(filterField.items) ?
          filterField.items() :
          filterField.items;

        const getText = _isFunction(filterField.getText) ?
          filterField.getText :
          item => item.label;

        const getValue = _isFunction(filterField.getValue) ?
          filterField.getValue :
          item => item.value;

        let options = filterFieldItems.map(item => ({
          label: getText(item),
          value: getValue(item),
        }));

        // Сортируем необходимые поля по алфавиту
        if (filterField.sort) {
          options = options.sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
            if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
            return 0;
          });
        }

        if (!Array.isArray(options)) {
          options = options.toArray();
        }

        const SelectComponent = filterField.type === 'orderStatus' ? OrderStatus : Select;
        const multi = filterField.type === 'multiSelect' || filterField.type === 'orderStatus';

        inputBlock = (
          <SelectComponent
            multi={multi}
            className="select_filter-padding"
            clearable={false}
            options={options}
            ref={(ref) => { this[refName] = ref; }}
            defaultValue={currentValue}
            placeholder={filterField.placeholder || i18next.t('all')}
            emptyValue={false}
            autoFocus={autoFocus}
          />
        );
        break;
      }

      case 'dateInterval': {
        inputBlock = (
          <DateInterval
            ref={(ref) => { this[refName] = ref; }}
            defaultValue={currentValue || undefined}
            autoFocus={autoFocus}
            emptyValue={defaultValue && defaultValue[filterField.key]}
          />
        );
        break;
      }

      default: {
        inputBlock = null;
      }
    }

    return (
      <div className="form__field" key={filterKey}>
        {
          /* Не вставлять label для checkbox. У Checkbox внутри собственный label. */
          filterField.type !== 'checkbox' ?
            (<label className="form__label">{filterField.name}</label>) :
            null
        }
        {inputBlock}
      </div>
    );
  }

  getRefName(filterField) {
    return `${filterField.key}Ref`;
  }

  render() {
    return (
      <form className="form form_filter">
        <div className="form__row form__row_1">
          {this.permittedFilterFields.map((field, key) => this.getField(field, key))}
        </div>
        <FilterButtons
          onResetFilter={this.onResetFilter}
          onChangeFilter={this.onChangeFilter}
        />
      </form>
    );
  }
}
