import React, { PureComponent } from 'react';
import i18next from 'i18next';
import { List } from 'immutable';
import { licenseTypeTranslations, booleanSelectList } from 'constants';
import { roles, licenseTypes, driveTypes, driveTypeTranslations } from 'shared/constants';
import {
  LicenseTypeCell,
  LicenseDriveTypeCell,
  LicenseStatusCell,
  DateCell,
  SupportEndDateCell,
  LinkCell,
  BooleanCell,
} from 'components/table/cell';
import LicensesPage from 'components/pages/licenses/licenses-page';

export default class RaidixLicensesPage extends PureComponent {
  constructor(props) {
    super(props);

    this.partners = List();
    this.managers = List();

    const users = props.usersDataIm.get('data');

    users.forEach((user) => {
      const userRole = user.get('role');

      if (userRole === roles.PARTNER) {
        this.partners = this.partners.push(user);
      }

      if (userRole === roles.MANAGER) {
        this.managers = this.managers.push(user);
      }
    });

    this.types = Object.entries(licenseTypes).map(([typeName, typeValue]) => ({
      label: i18next.t(licenseTypeTranslations[typeName]),
      value: typeValue,
    }));

    this.driveTypes = Object.entries(driveTypes).map(([typeName, typeValue]) => ({
      label: i18next.t(driveTypeTranslations[typeName]),
      value: typeValue,
    }));

    this.driveTypes.unshift({
      label: i18next.t('all'),
      value: '',
    });

    this.booleanSelectList = booleanSelectList.map(
      ({ label, value }) => ({ label: i18next.t(label), value })
    );

    this.productVersions = props.productVersionsDataIm.get('data')
      .map(productVersion => ({
        label: productVersion.get('name'),
        value: productVersion.get('id'),
      }))
      .toArray();
  }

  render() {
    const {
      usersDataIm,
    } = this.props;

    const cells = [
      {
        id: 'id',
        getValue: model => model.get('id'),
        className: 'table__cell_2',
        name: i18next.t('id'),
        sort: {
          type: 'arithmetic',
          field: 'id',
        },
      },
      {
        id: 'type',
        getValue: model => model.get('type'),
        className: 'table__cell_2',
        name: i18next.t('type'),
        sort: {
          type: 'alphabetic',
          field: 'type',
        },
        component: LicenseTypeCell,
      },
      {
        id: 'partner',
        getValue: model => model.get('partnerName'),
        className: 'table__cell_3',
        name: i18next.t('partner'),
        sort: {
          type: 'alphabetic',
          field: 'partnerName',
        },
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
      },
      {
        id: 'status',
        getValue: model => model.get('status'),
        className: 'table__cell_3',
        name: i18next.t('status'),
        sort: {
          type: 'alphabetic',
          field: 'status',
        },
        component: LicenseStatusCell,
      },
      {
        id: 'activation_date',
        getValue: model => model.get('generatedDate'),
        className: 'table__cell_3',
        name: i18next.t('activation_date'),
        sort: {
          type: 'arithmetic',
          field: 'generatedDate',
        },
        component: DateCell,
      },
      {
        id: 'support_end_date',
        getValue: model => model.get('supportExpirationDate'),
        className: 'table__cell_3',
        name: i18next.t('support_end_date'),
        sort: {
          type: 'arithmetic',
          field: 'supportExpirationDate',
        },
        component: SupportEndDateCell,
      },
      {
        id: 'order_id',
        getValue: model => ({
          text: model.get('orderUid'),
          link: `/orders/${model.get('orderId')}`,
        }),
        className: 'table__cell_2',
        name: i18next.t('order_id'),
        sort: {
          type: 'arithmetic',
          field: 'orderId',
        },
        component: LinkCell,
      },
      {
        id: 'drive_type',
        getValue: model => model.get('driveType'),
        className: 'table__cell_3',
        name: i18next.t('drive_type'),
        sort: {
          type: 'alphabetic',
          field: 'driveType',
        },
        component: LicenseDriveTypeCell,
      },
      {
        id: 'extended_raid',
        getValue: model => model.get('extendedRaid'),
        className: 'table__cell_3',
        name: i18next.t('additional_raid_short'),
        sort: {
          type: 'alphabetic',
          field: 'extendedRaid',
        },
        component: BooleanCell,
      },
    ];

    const filterFields = [
      {
        type: 'text',
        key: 'customer',
        name: i18next.t('customer'),
      },
      {
        type: 'multiSelect',
        key: 'partnerId',
        name: i18next.t('partner'),
        getValue: item => item.get('id'),
        getText: item => item.get('name'),
        items: this.partners,
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
        sort: true,
      },
      {
        type: 'multiSelect',
        key: 'managerId',
        name: i18next.t('manager'),
        getValue: item => item.get('id'),
        getText: item => item.get('name'),
        items: this.managers,
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
        sort: true,
      },
      {
        type: 'multiSelect',
        key: 'generatedBy',
        name: i18next.t('generated_by'),
        getValue: item => item.get('id'),
        getText: item => item.get('name'),
        items: usersDataIm.get('data'),
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
        sort: true,
      },
      {
        type: 'dateInterval',
        key: 'generatedDate',
        name: i18next.t('issue_date'),
      },
      {
        type: 'dateInterval',
        key: 'supportExpirationDate',
        name: i18next.t('support_end_date'),
      },
      {
        type: 'multiSelect',
        key: 'type',
        name: i18next.t('type'),
        items: this.types,
      },
      {
        type: 'text',
        key: 'hardwareKey',
        name: i18next.t('hardware_key'),
      },
      {
        type: 'text',
        key: 'purchaseOrder',
        name: i18next.t('purchase_order'),
      },
      {
        type: 'text',
        key: 'id',
        name: i18next.t('id'),
      },
      {
        type: 'select',
        key: 'driveType',
        name: i18next.t('drive_type'),
        items: this.driveTypes,
      },
      {
        type: 'select',
        key: 'extendedRaid',
        name: i18next.t('additional_raid'),
        items: this.booleanSelectList,
      },
      {
        type: 'select',
        key: 'oldKernelVersion',
        name: i18next.t('older_kernel_version'),
        items: this.booleanSelectList,
      },
      {
        type: 'text',
        key: 'linuxDistribution',
        name: i18next.t('linux_dist'),
      },
      {
        type: 'text',
        key: 'linuxKernel',
        name: i18next.t('kernel_version'),
      },
      {
        type: 'multiSelect',
        key: 'productVersion',
        name: i18next.t('era_version'),
        items: this.productVersions,
      },
    ];

    return (
      <LicensesPage
        {...this.props}
        cells={cells}
        filterFields={filterFields}
      />
    );
  }
}
