export const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50, 100, 200, 500];
export const BASIC_NUMBER_OF_DRIVES = 16;
// TODO: Необходимо переместить в БД, чтобы впоследствии админ сам мог изменять их
export const BASIC_FEATURES_CODES = ['NAS', 'ISCSI', 'ERA'];
export const CSV_FILE_NAME_DATE_FORMAT = 'DD-MM-YYYY-HH-mm-ss';
export const DOWNLOAD_LICENSE_URL = '/api/download/license';

export const roles = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  ENGINEER: 'engineer',
  ACCOUNTANT: 'accountant',
  PARTNER: 'partner',
};

export const licenseTypeTranslations = {
  COMMERCIAL: 'commercial',
  TRIAL: 'trial',
};

export const licenseCreateTypes = {
  NEW: 'NEW',
  CHANGE: 'CHANGE',
};

export const licenseCreateTypeTranslations = {
  NEW: 'new_license',
  CHANGE: 'changing_license',
};

export const orderStatus = {
  PENDING: 'PENDING',
  DOCUMENTS_SENT: 'DOCUMENTS_SENT',
  GENERATION_READY: 'GENERATION_READY',
  CANCELLED: 'CANCELLED',
};

export const orderStatusColors = {
  PENDING: 'yellow',
  DOCUMENTS_SENT: 'purple',
  GENERATION_READY: 'green',
  CANCELLED: 'gray',
};

export const orderTypes = {
  COMMERCIAL: 'COMMERCIAL',
  HWKEY_CHANGE: 'HWKEY_CHANGE',
  TRIAL: 'TRIAL',
};

export const currencyTypes = {
  RUB: 'RUB',
  USD: 'USD',
  EUR: 'EUR',
};

export const currencySymbols = {
  RUB: '\u20BD',
  USD: '\u0024',
  EUR: '\u20AC',
};

export const supportPeriodTranslations = {
  WITHOUT_SUPPORT: 'without_support',
  ONE_YEAR: 'one_year',
  TWO_YEARS: 'two_years',
  THREE_YEARS: 'three_years',
  FOUR_YEARS: 'four_years',
  FIVE_YEARS: 'five_years',
  SIX_YEARS: 'six_years',
  SPECIFIED_NUMBER_OF_DAYS: 'specified_number_of_days',
};

export const languages = {
  en: 'en',
  ru: 'ru',
};

export const languageTranslations = {
  en: 'english',
  ru: 'russian',
};

export const booleanSelectList = [
  {
    label: 'all',
    value: '',
  },
  {
    label: 'yes',
    value: 1,
  },
  {
    label: 'no',
    value: 0,
  },
];

// Да это должно быть в базе и мне просто лень переделывать все
export const draidLicensePresets = {
  hdd: {
    drives: 200,
    baseFeatureCode: 'DRAID',
    features: ['DC', 'DRAID', 'VIRT_MANAGER'],
  },
  nvme: {
    drives: 24,
    baseFeatureCode: 'DRAIDFLASH',
    features: ['DC', 'DRAIDFLASH', 'VIRT_MANAGER'],
  },
}