import React, { PureComponent } from 'react';
import i18next from 'i18next';

import { draidBuildingBlocks } from 'shared/constants';
import Select from 'components/controls/select';

export default class BuldingBlocksSelect extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selected: null
        }
    }

    getOptions = () => {
        return draidBuildingBlocks.map((block) => ({
            value: block,
            label: i18next.t(`building_block_types.${block}`),
        }));
    }

    onChange = (event) => {
        this.props.onChange(event.value);
    }

    render() {
        return (
            <div className="form__field">
                <label htmlFor={`license-form-${this.props.id}-building-blocks`} className="form__label">
                    {i18next.t('building_block')}
                </label>

                <Select
                    id={`license-form-${this.props.id}-building-blocks`}
                    value={this.props.value}
                    className="select_form"
                    clearable={false}
                    isSearchable={false}
                    options={this.getOptions()}
                    onChange={this.onChange}
                />
            </div>
        );
    }
}
