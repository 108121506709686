import { connect } from 'react-redux';
import LicenseHistory from 'components/pages/license-history';
import dataFetcherEnhance from 'components/data-fetcher-enhance';
import { actions as licensesDataActions } from 'ducks/data/licenses';
import { actions as eraLicensesDataActions } from 'ducks/data/era-licenses';
import { actions as functionalitiesDataActions } from 'ducks/data/functionalities';
import { actions as usersDataActions } from 'ducks/data/users';

const mapDispatchToProps = Object.assign(
  {},
  licensesDataActions,
  eraLicensesDataActions,
  functionalitiesDataActions,
  usersDataActions,
);

function mapStateToProps(state) {
  return {
    licensesDataIm: state.data.licensesDataIm,
    eraLicensesDataIm: state.data.eraLicensesDataIm,
    functionalitiesDataIm: state.data.functionalitiesDataIm,
    usersDataIm: state.data.usersDataIm,
    fetchActionNames: [
      'functionalitiesDataGetSignal',
      'usersDataGetSignal',
    ],
  };
}

const LicenseHistoryWithFetch = dataFetcherEnhance(LicenseHistory);

export default connect(mapStateToProps, mapDispatchToProps)(LicenseHistoryWithFetch);
