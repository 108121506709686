import React, { PureComponent, Fragment } from 'react';
import { Form } from 'containers';
import UserForm from 'components/form/user';
import i18next from 'i18next';
import BackLink from 'components/controls/back-link';

export default class AddUser extends PureComponent {
  render() {
    const {
      usersDataIm,
      usersDataAddSignal,
    } = this.props;

    return (
      <Fragment>
        <div className="content__head">
          <BackLink
            text={i18next.t('back_to_list_of_users')}
            to="/users"
          />
          <h1 className="content__title">{i18next.t('creating_user')}</h1>
        </div>
        <div className="content__body">
          <Form id="add-user">
            <UserForm
              usersDataIm={usersDataIm}
              submitSignal={usersDataAddSignal}
            />
          </Form>
        </div>
      </Fragment>
    );
  }
}
