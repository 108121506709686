import { connect } from 'react-redux';
import Orders from 'components/pages/orders';
import dataFetcherEnhance from 'components/data-fetcher-enhance';
import { actions as ordersDataActions } from 'ducks/data/orders';
import { actions as ordersInfoDataActions } from 'ducks/data/orders-info';
import { actions as licensesDataActions } from 'ducks/data/licenses';
import { actions as eraLicensesDataActions } from 'ducks/data/era-licenses';
import { actions as draidLicensesDataActions } from 'ducks/data/draid-licenses';
import { actions as usersDataActions } from 'ducks/data/users';

const mapDispatchToProps = Object.assign(
  {},
  ordersDataActions,
  licensesDataActions,
  eraLicensesDataActions,
  usersDataActions,
  ordersInfoDataActions,
  draidLicensesDataActions
);

function mapStateToProps(state) {
  return {
    authDataIm: state.data.authDataIm,
    ordersDataIm: state.data.ordersDataIm,
    ordersInfoDataIm: state.data.ordersInfoDataIm,
    licensesDataIm: state.data.licensesDataIm,
    eraLicensesDataIm: state.data.eraLicensesDataIm,
    draidLicensesDataIm: state.data.draidLicensesDataIm,
    usersDataIm: state.data.usersDataIm,
    fetchActionNames: [
      'ordersDataGetSignal',
      'usersDataGetSignal',
      'ordersInfoDataGetSignal'
    ],
  };
}

const OrdersWithFetch = dataFetcherEnhance(Orders);

export default connect(mapStateToProps, mapDispatchToProps)(OrdersWithFetch);
