/* global window, document */
import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import configureStore from 'store';
import routes from 'routes';
import i18next from 'i18next';
import moment from 'moment';
/* eslint-disable no-unused-vars */
import momentEn from 'moment/locale/en-gb';
import momentRu from 'moment/locale/ru';
/* eslint-enable no-unused-vars */
import Promise from 'bluebird';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import browserHistory from 'browser-history';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-select/dist/react-select.css';
import 'img/favicon.ico';
import '../static/scss/app.scss';
import * as languages from '../static/translations';

// Подмена Promise из babel на bluebird
Promise.config({ cancellation: true });
global.Promise = Promise;

const { en, ru } = languages;

const currentLanguage = window.localStorage.getItem('language') || 'en';

moment.locale(currentLanguage);

const rootElement = document.getElementById('root');
const store = configureStore();
/**
 * Converts the list [{ href: '#', name: 'name'}, ...] into links.
 * Used as `{{- links, links}}`
 * @param value
 * @returns {string}
 */
const i18nextLinks = (value) => {
  if (!value) {
    return '';
  }

  const links = Array.isArray(value) ? value : [value];

  return links.map(link => `<a href="${link.href}" target="_blank">${link.name}</a>`).join(', ');
};

i18next.init({
  lng: currentLanguage,
  compatibilityJSON: 'v2',
  resources: {
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
  },
  interpolation: {
    format: (value, format) => {
      if (format === 'links') {
        return i18nextLinks(value);
      }

      return value;
    },
  },
}, () => {
  render(
    <Provider store={store}>
      <Router history={browserHistory} >{routes}</Router>
    </Provider>,
    rootElement
  );
});
