import React from 'react';
import i18next from 'i18next';
import HurtIcon from 'img/hurt.svg';
import ErrorPage from './error-page';

const ServerError = () => (
  <ErrorPage
    icon={HurtIcon}
    textNormal={i18next.t('error_page.server_temporary_error_text')}
    textSmall={i18next.t('error_page.please_try_again_in_30_seconds')}
  />
);

export default ServerError;
