import React, { Component } from 'react';
import classnames from 'classnames';
import { orderStatusColors } from 'constants';

export default class StatusOption extends Component {
  constructor(props) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  }

  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }

  render() {
    const { option, className, children } = this.props;
    const statusOptionClassName = classnames(
      'order-status__value-icon',
      `order-status__value-icon_${orderStatusColors[option.value]}`
    );

    return (
      <div
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
      >
        <span className={statusOptionClassName} />
        <span className="order-status__value">{ children }</span>
      </div>
    );
  }
}
