import React, { PureComponent } from 'react';

export default class Form extends PureComponent {
  constructor(props) {
    super(props);

    this.submitWrapperSignal = this.submitWrapperSignal.bind(this);
  }

  submitWrapperSignal(options) {
    // Добавляем id здесь, это бы не делать это в каждой форме
    return this.props.formComponentSubmitWrapperSignal({ ...options, id: this.props.id });
  }

  render() {
    return React.cloneElement(
      this.props.children,
      {
        authDataIm: this.props.authDataIm,
        formComponentIm: this.props.formComponentIm,
        formComponentSubmitWrapperSignal: this.submitWrapperSignal,
        formComponentAddSuccessSignal: this.props.formComponentAddSuccessSignal,
      }
    );
  }
}
