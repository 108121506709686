import React, { Fragment, PureComponent } from 'react';
import i18next from 'i18next';
import { Form } from 'containers';
import OrderForm from 'components/form/order';
import BackLink from 'components/controls/back-link';
import { productTypes } from 'shared/constants';

export default class EditOrder extends PureComponent {
  render() {
    const {
      usersDataIm,
      licenseSubtypesDataIm,
      ordersDataIm,
      licensesDataIm,
      eraLicensesDataIm,
      draidLicensesDataIm,
      licensesListComponentIm,
      functionalitiesDataIm,
      ordersDataUpdateSignal,
      licensesListComponentAddDelta,
      licensesListComponentResetDelta,
      licensesListComponentDeleteDelta,
      generatorDataGenerateSignal,
      modalComponentShowDelta,
      currentUserLicenseSubtypesDataIm,
      productVersionsDataIm,
      match,
    } = this.props;

    if (!match) {
      return null;
    }

    const orderId = parseInt(match.params.orderId, 10);
    const orderIm = ordersDataIm.get('data')
      .find(model => model.get('id') === orderId);
    const uId = orderIm.get('uid');
    const productType = orderIm.get('productType');
    const map = {
      [productTypes.RAIDIX]: licensesDataIm,
      [productTypes.ERA]: eraLicensesDataIm,
      [productTypes.DRAID]: draidLicensesDataIm,
    }
    const licensesData = map[productType];

    const licensesOrderIm = licensesData.get('data')
      .filter(model => model.get('orderId') === orderId);

    return (
      <Fragment>
        <div className="content__head">
          <BackLink
            text={i18next.t('back_to_list_of_orders')}
            to="/orders"
          />
          <h1 className="content__title">
            {i18next.t('editing_order')}
            <span className="content__subtitle"> #{uId}</span>
          </h1>
        </div>
        <div className="content__body">
          <Form id="add-order">
            <OrderForm
              orderIm={orderIm}
              licensesOrderIm={licensesOrderIm}
              licensesDataIm={licensesDataIm}
              eraLicensesDataIm={eraLicensesDataIm}
              usersDataIm={usersDataIm}
              licensesListComponentIm={licensesListComponentIm}
              functionalitiesDataIm={functionalitiesDataIm}
              submitSignal={ordersDataUpdateSignal}
              licensesListComponentAddDelta={licensesListComponentAddDelta}
              licensesListComponentResetDelta={licensesListComponentResetDelta}
              licensesListComponentDeleteDelta={licensesListComponentDeleteDelta}
              generatorDataGenerateSignal={generatorDataGenerateSignal}
              modalComponentShowDelta={modalComponentShowDelta}
              licenseSubtypesDataIm={licenseSubtypesDataIm}
              currentUserLicenseSubtypesDataIm={currentUserLicenseSubtypesDataIm}
              productVersionsDataIm={productVersionsDataIm}
              isEdit
            />
          </Form>
        </div>
      </Fragment>
    );
  }
}
