import { combineReducers } from 'redux';
import authDataIm from './auth';
import usersDataIm from './users';
import licensesDataIm from './licenses';
import draidLicensesDataIm from './draid-licenses';
import licensesInfoDataIm from './licenses-info';
import eraLicensesDataIm from './era-licenses';
import functionalitiesDataIm from './functionalities';
import ordersDataIm from './orders';
import ordersInfoDataIm from './orders-info';
import licenseSubtypesDataIm from './license-subtype';
import userLicenseSubtypesDataIm from './user-license-subtype';
import currentUserLicenseSubtypesDataIm from './current-user-license-subtype';
import productVersionsDataIm from './product-versions';

const pagesReducers = combineReducers({
  authDataIm,
  usersDataIm,
  licensesDataIm,
  licensesInfoDataIm,
  eraLicensesDataIm,
  draidLicensesDataIm,
  functionalitiesDataIm,
  ordersDataIm,
  ordersInfoDataIm,
  licenseSubtypesDataIm,
  userLicenseSubtypesDataIm,
  currentUserLicenseSubtypesDataIm,
  productVersionsDataIm,
});

export default pagesReducers;
