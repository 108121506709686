import React, { Fragment } from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import Tooltip from 'components/tooltip';
import { FilterButton, QuickFilter, TableFilter } from 'containers';
import ToolBar from './toolbar';

export default function UserToolbar({
  filterFields,
  quickFilterFields,
  quickFilterLabel,
  tableId,
}) {
  let filterContent;

  if (filterFields) {
    filterContent = (
      <TableFilter
        id={tableId}
        filterFields={filterFields}
      />
    );
  }

  return (
    <ToolBar>
      <div className="toolbar__item">
        <Link to="/users/add" className="button button_submit">
          {i18next.t('add')}
        </Link>
        {
          quickFilterFields ? (
            <Fragment>
              <div className="toolbar__space" />

              <QuickFilter
                id={tableId}
                quickFilterFields={quickFilterFields}
                quickFilterLabel={quickFilterLabel}
              />
            </Fragment>
          ) : null
        }
      </div>

      {
        filterFields ? (
          <div className="toolbar__item">
            <div className="controls-group">

              <div className="controls-group__control">
                <Tooltip
                  content={filterContent}
                >
                  <FilterButton
                    id={tableId}
                  />
                </Tooltip>
              </div>

            </div>
          </div>
        ) : null
      }
    </ToolBar>
  );
}
