import { connect } from 'react-redux';
import { actions as tableComponentActions } from 'ducks/components/table';
import QuickFilter from 'components/table-filter/quick-filter';

const mapDispatchToProps = tableComponentActions;

function mapStateToProps(state, ownProps) {
  return {
    authDataIm: state.data.authDataIm,
    tableComponentIm: state.components.tableComponentIm.get(ownProps.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickFilter);
