import React, { PureComponent } from 'react';
import classnames from 'classnames';

export default class Input extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { error: false };

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  onChange(event) {
    const { onChange, type } = this.props;

    // Разрешаем вводить только числа если поле имеет соответствующий тип
    if (type === 'number') {
      this.inputRef.value = event.target.value.replace(/\D+/g, '');
    }

    this.clearError();

    if (typeof onChange === 'function') {
      onChange(event);
    }
  }

  onFocus(event) {
    const { onFocus, readOnly, autoComplete } = this.props;

    // Если режим только для чтения не был включён принудительно и
    // было выключено автозаполнение (в связи с этим был добавлен атрибут readonly)
    if (!readOnly && autoComplete === 'off') {
      event.target.removeAttribute('readonly');
    }

    if (onFocus) {
      onFocus(event);
    }
  }

  get error() {
    return this.state.error;
  }

  get value() {
    return this.inputRef.value;
  }

  set error(newValue) {
    this.setState({
      error: newValue,
    });
  }

  set value(newValue) {
    this.inputRef.value = newValue;
  }

  clearError() {
    this.setState({
      error: false,
    });
  }

  focus() {
    this.inputRef.focus();
  }

  render() {
    const {
      value,
      defaultValue,
      className,
      id,
      name,
      placeholder,
      onChange,
      onBlur,
      type = 'text',
      autoComplete = undefined,
      disabled = false,
      autoFocus = false,
      errorClassName,
      maxLength,
      tabIndex,
    } = this.props;

    let { readOnly = false } = this.props;
    const { error } = this.state;

    // Если автозаполнение отключено, то делаем поля доступным
    // только для чтения, а при фокусе разрешаем редактирования.
    // Только так кроссбраузерно можно отменить автозаполнение
    if (autoComplete === 'off') {
      readOnly = true;
    }

    const inputType = type === 'number' ? 'text' : type;

    const childProps = {
      id,
      name,
      value,
      defaultValue,
      type: inputType,
      placeholder: disabled ? '' : placeholder,
      onChange,
      onBlur,
      disabled,
      autoComplete,
      autoFocus,
      readOnly,
      maxLength,
      tabIndex,
      className: classnames(
        'input-component__field',
        { 'input-component__field_error': error },
        className
      ),
    };

    const errorClassNames = classnames('input-component__error', errorClassName);

    return (
      <div className="input-component">
        <input
          {...childProps}
          onChange={this.onChange}
          onFocus={this.onFocus}
          ref={(ref) => { this.inputRef = ref; }}
        />
        {
          error && error !== true ? (
            <div
              className={errorClassNames}
              title={error}
            >
              {error}
            </div>
          ) : null
        }
      </div>
    );
  }
}
