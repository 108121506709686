import React, { PureComponent, Fragment } from 'react';
import { Form } from 'containers';
import UserForm from 'components/form/user';
import i18next from 'i18next';
import BackLink from 'components/controls/back-link';

export default class EditUser extends PureComponent {
  render() {
    const {
      usersDataIm,
      usersDataUpdateSignal,
      match,
      licenseSubtypesDataIm,
      userLicenseSubtypesDataIm,
    } = this.props;

    if (!match) {
      return null;
    }

    const userId = parseInt(match.params.userId, 10);
    const userIm = usersDataIm.get('data')
      .find(model => model.get('id') === userId);

    return (
      <Fragment>
        <div className="content__head">
          <BackLink
            text={i18next.t('back_to_list_of_users')}
            to="/users"
          />
          <h1 className="content__title">
            {i18next.t('editing_user')}
            <span className="content__subtitle"> #{userId}</span>
          </h1>
        </div>
        <div className="content__body">
          <Form id="add-user">
            <UserForm
              userIm={userIm}
              usersDataIm={usersDataIm}
              submitSignal={usersDataUpdateSignal}
              licenseSubtypesDataIm={licenseSubtypesDataIm}
              userLicenseSubtypesDataIm={userLicenseSubtypesDataIm}
              isEdit
            />
          </Form>
        </div>
      </Fragment>
    );
  }
}
