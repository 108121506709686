import React from 'react';
import Tooltip from 'components/tooltip';
import { TableFilter, QuickFilter, FilterButton } from 'containers';
import { roles } from 'shared/constants';
import IconButton from 'components/controls/icon-button';
import i18next from 'i18next';
import DownloadIcon from 'img/download.svg';
import ToolBar from './toolbar';

export default function LicenseToolbar({
  filterFields,
  quickFilterFields,
  tableId = 'licenses',
  onExportToFile,
  authDataIm,
}) {
  let filterContent;

  if (filterFields) {
    filterContent = (
      <TableFilter
        id={tableId}
        filterFields={filterFields}
      />
    );
  }

  const currentUserRole = authDataIm !== undefined ? authDataIm.getIn(['data', 'role']) : false;

  return (
    <ToolBar className="toolbar_page-tabs">
      <div className="toolbar__item">
        {
          quickFilterFields ? (
            <QuickFilter
              id={tableId}
              quickFilterFields={quickFilterFields}
            />
          ) : null
        }
      </div>

      {
        filterFields ? (
          <div className="toolbar__item">
            <div className="controls-group">
              {
                currentUserRole !== roles.PARTNER && onExportToFile ? (
                  <div className="controls-group__control">
                    <IconButton
                      icon={DownloadIcon}
                      onClick={onExportToFile}
                      text={i18next.t('export_to_csv')}
                    />
                  </div>
                ) : null
              }
              <div className="controls-group__control">
                <Tooltip
                  content={filterContent}
                >
                  <FilterButton
                    id={tableId}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        ) : null
      }
    </ToolBar>
  );
}
