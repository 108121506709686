import React, { PureComponent } from 'react';
import i18next from 'i18next';
import Input from 'components/controls/input';

export default class AuthForm extends PureComponent {
  get login() {
    return this.loginRef;
  }

  get password() {
    return this.passwordRef;
  }

  render() {
    return (
      <div className="auth">
        <form className="form form_auth">
          <h1 className="auth__title">
            {i18next.t('license_service')}
          </h1>
          <div className="form__row">
            <div className="form__field">
              <label htmlFor="auth__block_login">
                {i18next.t('login')}
              </label>
              <Input
                id="auth__block_login"
                className="form__field-text form__field-text_padding"
                ref={(ref) => { this.loginRef = ref; }}
                type="text"
                name="login"
              />
            </div>
            <div className="form__field">
              <label htmlFor="auth__block_password">
                {i18next.t('password')}
              </label>
              <Input
                id="auth__block_password"
                className="form__field-text form__field-text_padding"
                ref={(ref) => { this.passwordRef = ref; }}
                type="password"
                name="password"
              />
            </div>
          </div>
          <div className="form__footer">
            <button className="button button_form button_submit" onClick={this.props.onSubmit}>
              {i18next.t('log_in')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
