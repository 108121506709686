import React from 'react';
import i18next from 'i18next';
import { licenseStatusTranslations } from 'shared/constants';
import TableCell from './cell';

export default function LicenseStatusCell({ value, className }) {
  const translation = i18next.t(licenseStatusTranslations[value], { context: 'female' });

  return (
    <TableCell
      className={className}
      value={translation}
    />
  );
}
