import React, { PureComponent } from 'react';
import _map from 'lodash/map';
import numeral from 'numeral';
import { currencyTypes, currencySymbols } from 'constants';
import Input from 'components/controls/input';
import { PRICE_FORMAT_SHORT } from 'shared/constants';
import ReactTooltip from 'react-tooltip';
import CurrencyButton from './currency-button';

export default class InputPrice extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currency: props.defaultCurrency,
    };

    this.onChangeCurrency = this.onChangeCurrency.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeCurrency({ currency }) {
    if (this.props.disabled !== true) {
      this.setState({ currency });
    }
  }

  onChangeValue(event) {
    const { value } = event.target;
    const formatValue = this.formatValue(value);

    this.inputRef.value = value === '' ||
      (formatValue === '0' && value !== '0') ||
      formatValue === 'NaN' ?
      '' : formatValue;
  }

  get value() {
    // Возвращаем только числа
    return this.inputRef.value.replace(/\D+/g, '');
  }

  get currency() {
    return this.state.currency;
  }

  set value(newValue) {
    // Применяем новое значения с учётом форматирования
    this.inputRef.value = this.formatValue(newValue);
  }

  set error(newValue) {
    this.inputRef.error = newValue;
  }

  getCurrencyTypes() {
    const { currency } = this.state;

    return _map(currencyTypes, (currencyName, currencyCode) => {
      const currencySymbol = currencySymbols[currencyCode];
      const active = currencyName === currency;

      return (
        <CurrencyButton
          key={currencyName}
          id={currencyName}
          active={active}
          disabled={this.props.disabled}
          onClick={this.onChangeCurrency}
        >
          {currencySymbol}
        </CurrencyButton>
      );
    });
  }

  formatValue(value) {
    if (value === undefined || value === null) {
      return value;
    }

    return numeral(value).format(PRICE_FORMAT_SHORT);
  }

  render() {
    const {
      defaultValue,
      placeholder = '18,240,629',
    } = this.props;

    const props = {
      ...this.props,
      className: 'form__field-text form__field-text_currency',
      errorClassName: 'input-component__error_price',
      defaultValue: this.formatValue(defaultValue),
      ref: (ref) => { this.inputRef = ref; },
      onChange: this.onChangeValue,
      placeholder,
      maxLength: 15,
    };

    return (
      <div className="input-price-component form__field-text_padding">
        <Input {...props} />
        <div className="input-price-component__currencies">
          {this.getCurrencyTypes()}
        </div>
        <ReactTooltip
          id="currency"
          effect="solid"
          delayHide={200}
        />
      </div>
    );
  }
}
