import React from 'react';
import i18next from 'i18next';
import { licenseTypeTranslations } from 'constants';
import TableCell from './cell';

export default function LicenseTypeCell({ value, className }) {
  const translation = i18next.t(licenseTypeTranslations[value]);

  return (
    <TableCell
      className={className}
      value={translation}
    />
  );
}
