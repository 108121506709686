import { Map, fromJS } from 'immutable';
import { applicationName } from 'config';
import { fetchSignal } from 'ducks/fetch';

/*
* Constants
* */

export const MODULE_NAME = 'licenses-data';
export const URL = '/api/licenses';
export const URL_CVS = `${URL}/csv`;

// Action names
const FETCH_DONE = `${applicationName}/${MODULE_NAME}/FETCH_DONE`;
const UPDATE_DONE = `${applicationName}/${MODULE_NAME}/UPDATE_DONE`;
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;

/*
* Reducer
* */

const initialState = Map({ data: false });

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DONE:
      return state.set('data', fromJS(action.payload));

    case UPDATE_DONE: {
      return state.update('data', data => data.map((dataItem) => {
        if (dataItem.get('id') === action.payload.id) {
          return dataItem.merge(action.payload);
        }

        return dataItem;
      }));
    }

    case RESET:
      return initialState;

    default:
      return state;
  }
}

/*
* Actions
* */

const licensesDataResetDelta = () => ({ type: RESET });

const licensesDataGetSignal = () => dispatch => Promise.coroutine(function* getLicense() {
  const answer = yield dispatch(fetchSignal(URL));

  if (answer.isSuccess) {
    dispatch({ type: FETCH_DONE, payload: answer.data });
  }

  return answer;
})();

const licensesCsvDataGetSignal = ({ data }) => dispatch => Promise.coroutine(function* getOrder() {
  return yield dispatch(fetchSignal(URL_CVS, { method: 'POST', body: data }));
})();

// Используется после генерации лицензии
const licensesDataNoFetchUpdateSignal = data => ({ type: UPDATE_DONE, payload: data });

export const actions = {
  licensesDataResetDelta,
  licensesDataGetSignal,
  licensesCsvDataGetSignal,
  licensesDataNoFetchUpdateSignal,
};
