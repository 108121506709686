import React, { Component } from 'react';
import classnames from 'classnames';

export default class RadioButtonList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: props.defaultValue,
      error: false
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { onChange } = this.props;

    this.setState({
      error: false,
      selectedItem: event.target.value,
    });

    if (typeof onChange === 'function') {
      onChange(event);
    }
  }

  get value() {
    const { emptyValue = '' } = this.props;
    const { selectedItem } = this.state;

    if (selectedItem === undefined) {
      return emptyValue;
    }

    return selectedItem;
  }

  get error() {
    return this.state.error;
  }

  set error(newValue) {
    this.setState({
      error: newValue,
    });
  }

  set value(value) {
    this.setState({
      selectedItem: value,
      error: false,
    });
  }

  render() {
    const { options, name, disabled, value: currentValue, errorClassName, className } = this.props;
    const { error, selectedItem } = this.state;
    const selectedValue = currentValue !== undefined ? currentValue : selectedItem;

    return (
      <div className="radio-button-list radio-button-list__direction_column">
        {options.map(({ value, label }) => (
          <label className="radio-button" key={value}>
            <input
              className={classnames(
                'radio-button_input',
                { 'radio-button_input__error': error },
                className
              )}
              type="radio"
              name={name}
              value={value}
              disabled={disabled}
              checked={selectedValue === value}
              onChange={this.onChange}
            />
            <span className="radio-button_label">{label}</span>
          </label>
        ))}
        {
          this.state.error ? (
            <div
              className={classnames('radio-button-list__error', errorClassName)}
              title={this.state.error}
            >
              {this.state.error}
            </div>
          ) : null
        }
      </div>
    );
  }
}
